<template>
  <div class="navigatorContainer">
    <div
      v-if="isProjection"
      class="page"
      style="display: flex; align-items: center; justify-content: center"
    >
      <div
        v-if="isProjection && isExitPrevious"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
        "
      >
        <!-- <el-button size="small" class="projection" @click="$_previous_projection"
                  :title="languageData.previousPage">{{ languageData.previousPage }}</el-button> -->
        <img
          src="../../assets/上一页.png"
          :title="languageData.previousPage"
          style="cursor: pointer; width: 16px; height: 16px"
          @click="$_previous_projection"
        />
      </div>
      <div
        v-if="isProjection && isExitNext"
        style="display: flex; align-items: center; justify-content: center"
      >
        <!-- <el-button size="small" class="projection" @click="$_next_projection"
                  :title="languageData.nextPage">{{ languageData.nextPage }}</el-button> -->
        <img
          src="../../assets/下一页.png"
          :title="languageData.nextPage"
          style="cursor: pointer; width: 16px; height: 16px"
          @click="$_next_projection"
        />
      </div>
    </div>
    <div
      v-if="!isProjection && !isShare"
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;
      "
    >
      <el-switch
        v-model="value"
        :active-text="languageData.readOnlyMode"
        :inactive-text="languageData.editMode"
        @change="changeMode"
      >
      </el-switch>
    </div>
    <div
      v-if="!isProjection"
      style="display: flex; flex-direction: row; justify-content: center"
    >
      <div class="scaleContainer">
        <div
          class="el-tooltip btn item"
          aria-describedby="el-tooltip-5941"
          tabindex="0"
          @click="reduce"
        >
          <el-icon size="16"><Minus /></el-icon>
        </div>
        <div class="scaleInfo">{{ transform }}%</div>
        <div
          class="el-tooltip btn item"
          aria-describedby="el-tooltip-1380"
          tabindex="0"
          @click="enlarge"
        >
          <el-icon size="16"><Plus /></el-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import LogicFlow from "@logicflow/core";
export default {
  props: {
    lf: LogicFlow,
    transform: { type: Number, default: undefined },
    isProjection: Boolean,
    isExitPrevious: Boolean,
    isExitNext: Boolean,
    isChangeRate: Boolean,
    isShare: Boolean,
  },
  emits: ["setIsSilentMode", "getTransformSize", "changeRate"],
  data() {
    return {
      value: false,
      languageData: {},
    };
  },
  watch: {
    isChangeRate(newVal) {
      if (newVal) {
        this.$emit("getTransformSize", 100);
        this.$emit("changeRate", false);
      }
    },
  },
  mounted() {
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
  },
  methods: {
    changeMode(e) {
      if (e) {
        this.$props.lf.updateEditConfig({
          isSilentMode: e,
        });
        this.$emit("setIsSilentMode", e);
      } else {
        this.$props.lf.updateEditConfig({
          isSilentMode: e,
        });
        this.$emit("setIsSilentMode", e);
      }
    },
    reduce() {
      var _this = this;
      _this.$nextTick(() => {
        var size = parseInt($(".scaleInfo").text().split("%")[0]);
        if (size - 4 >= 24) {
          // $(".scaleInfo").text((size - 10).toString() + "%");
          _this.$props.lf.zoom((size - 4) / 100);
          _this.$emit("getTransformSize", size - 4);
        }
      });
    },
    enlarge() {
      var _this = this;
      _this.$nextTick(() => {
        var size = parseInt($(".scaleInfo").text().split("%")[0]);
        if (size + 4 <= 400) {
          // $(".scaleInfo").text((size + 10).toString() + "%");
          _this.$props.lf.zoom((size + 4) / 100);
          _this.$emit("getTransformSize", size + 4);
        }
      });
    },
    $_previous_projection() {
      this.$parent.$_previous_projection();
    },
    $_next_projection() {
      this.$parent.$_next_projection();
    },
  },
};
</script>

<style>
.navigatorContainer {
  background: #ffffff;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
}

.item {
  margin-right: 10px;
  margin-left: 10px;
}

.scaleContainer {
  display: flex;
  align-items: center;
}

.scaleContainer .btn {
  cursor: pointer;
}

.scaleContainer .scaleInfo {
  width: 40px;
  text-align: center;
  margin: 0 20px;
}
</style>

<style scoped>
.projection {
  /* position: absolute;
  top: 66px;
  left: 58%; */
  z-index: 10;
  background: #ff9800;
  margin-left: 20px;
  box-shadow: 0px 2px 15px 1px rgba(0, 123, 255, 0.16);
  border-color: #ff9800;
  color: white;
}
</style>
