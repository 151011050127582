import { PIXI } from "./pixi.js";
import { find_min_x, find_max_x, find_min_y, find_max_y } from "./utils";
import $ from "jquery";
import jujinPng_1 from "../assets/口袋妖怪-蛇.png";
import jujinPng_2 from "../assets/口袋妖怪.png";
import people_1 from "../assets/人物-1.png";
import people_2 from "../assets/人物-2.png";
import people_3 from "../assets/人物-3.png";
import people_4 from "../assets/人物-4.png";
import { enLanguage } from "../lang/en.js";
import { zhHansLanguage } from "../lang/zh-Hans.js";
import { zhHantLanguage } from "../lang/zh-Hant.js";

let loader = null;
let app = null;
let container = null;
// 移动的范围
let gameContainer = null;
// let currentNode = {};
// let ishit = false;
let player = null;
let index_new = null;
let nodes = [];
let tipContainer = null;
let userTipContainer = null;
let isTip = false;
// let overlay_new = null
let width = null;
let height = null;
let languageData = {};

// 创建一个亮度滤镜
let brightnessFilter = new PIXI.filters.ColorMatrixFilter();
brightnessFilter.brightness(1.5); // 设置亮度，1 是默认值，大于 1 会增加亮度

if (window.location.href.indexOf("cocorobo.cn") != -1) {
  languageData = zhHansLanguage;
} else if (window.location.href.indexOf("cocorobo.hk") != -1) {
  languageData = zhHantLanguage;
} else if (window.location.href.indexOf("cocorobo.com") != -1) {
  languageData = enLanguage;
} else {
  languageData = zhHansLanguage;
}

class SpriteUtilities {
  constructor(PIXI, pixiRenderer) {
    if (PIXI === undefined)
      throw new Error(
        "Please supply a reference to PIXI in the SpriteUtilities constructor before using spriteUtilities.js"
      );

    //Find out which rendering engine is being used (the default is Pixi)
    this.renderer = "";

    //If the `PIXI` is Pixi, set up Pixi object aliases
    this.renderer = "pixi";
    this.Container = PIXI.Container;
    this.ParticleContainer = PIXI.ParticleContainer;
    this.TextureCache = PIXI.utils.TextureCache;
    this.Texture = PIXI.Texture;
    this.Rectangle = PIXI.Rectangle;
    this.AnimatedSprite = PIXI.AnimatedSprite;
    this.BitmapText = PIXI.BitmapText;
    this.Sprite = PIXI.Sprite;
    this.TilingSprite = PIXI.TilingSprite;
    this.Graphics = PIXI.Graphics;
    this.Text = PIXI.Text;
    this.pixiRenderer = pixiRenderer;

    //An array to store all the shaking sprites
    this.shakingSprites = [];
  }

  update() {
    if (this.shakingSprites.length > 0) {
      for (let i = this.shakingSprites.length - 1; i >= 0; i--) {
        let shakingSprite = this.shakingSprites[i];
        if (shakingSprite.updateShake) shakingSprite.updateShake();
      }
    }
  }

  sprite(source, x = 0, y = 0, tiling = false, width, height) {
    let o, texture;

    //Create a sprite if the `source` is a string
    if (typeof source === "string") {
      //Access the texture in the cache if it's there
      if (this.TextureCache[source]) {
        texture = this.TextureCache[source];
      }

      //If it's not is the cache, load it from the source file
      else {
        texture = this.Texture.from(source);
      }

      //If the texture was created, make the o
      if (texture) {
        //If `tiling` is `false`, make a regular `Sprite`
        if (!tiling) {
          o = new this.Sprite(texture);
        }

        //If `tiling` is `true` make a `TilingSprite`
        else {
          o = new this.TilingSprite(texture, width, height);
        }
      }
      //But if the source still can't be found, alert the user
      else {
        throw new Error(`${source} cannot be found`);
      }
    }

    //Create a o if the `source` is a texture
    else if (source instanceof this.Texture) {
      if (!tiling) {
        o = new this.Sprite(source);
      } else {
        o = new this.TilingSprite(source, width, height);
      }
    }

    //Create a `AnimatedSprite` o if the `source` is an array
    else if (source instanceof Array) {
      //Is it an array of frame ids or textures?
      if (typeof source[0] === "string") {
        //They're strings, but are they pre-existing texture or
        //paths to image files?
        //Check to see if the first element matches a texture in the
        //cache
        if (this.TextureCache[source[0]]) {
          //It does, so it's an array of frame ids
          o = this.AnimatedSprite.fromFrames(source);
        } else {
          //It's not already in the cache, so let's load it
          o = this.AnimatedSprite.fromImages(source);
        }
      }

      //If the `source` isn't an array of strings, check whether
      //it's an array of textures
      else if (source[0] instanceof this.Texture) {
        //Yes, it's an array of textures.
        //Use them to make a AnimatedSprite o
        o = new this.AnimatedSprite(source);
      }
    }

    //If the sprite was successfully created, intialize it
    if (o) {
      //Position the sprite
      o.x = x;
      o.y = y;

      //Set optional width and height
      if (width) o.width = width;
      if (height) o.height = height;

      //If the sprite is a AnimatedSprite, add a state player so that
      //it's easier to control
      if (o instanceof this.AnimatedSprite) this.addStatePlayer(o);

      //Assign the sprite
      return o;
    }
  }

  addStatePlayer(sprite) {
    let frameCounter = 0,
      numberOfFrames = 0,
      startFrame = 0,
      endFrame = 0,
      timerInterval = undefined;

    //The `show` function (to display static states)
    function show(frameNumber) {
      //Reset any possible previous animations
      reset();

      //Find the new state on the sprite
      sprite.gotoAndStop(frameNumber);
    }

    //The `stop` function stops the animation at the current frame
    function stopAnimation() {
      reset();
      sprite.gotoAndStop(sprite.currentFrame);
    }

    //The `playSequence` function, to play a sequence of frames
    function playAnimation(sequenceArray) {
      //Reset any possible previous animations
      reset();

      //Figure out how many frames there are in the range
      if (!sequenceArray) {
        startFrame = 0;
        endFrame = sprite.totalFrames - 1;
      } else {
        startFrame = sequenceArray[0];
        endFrame = sequenceArray[1];
      }

      //Calculate the number of frames
      numberOfFrames = endFrame - startFrame;

      //Compensate for two edge cases:
      //1. If the `startFrame` happens to be `0`
      /*
            if (startFrame === 0) {
              numberOfFrames += 1;
              frameCounter += 1;
            }
            */

      //2. If only a two-frame sequence was provided
      /*
            if(numberOfFrames === 1) {
              numberOfFrames = 2;
              frameCounter += 1;
            }  
            */

      //Calculate the frame rate. Set the default fps to 12
      if (!sprite.fps) sprite.fps = 12;
      let frameRate = 1000 / sprite.fps;

      //Set the sprite to the starting frame
      sprite.gotoAndStop(startFrame);

      //Set the `frameCounter` to the first frame
      frameCounter = 1;

      //If the state isn't already `playing`, start it
      if (!sprite.animating) {
        timerInterval = setInterval(advanceFrame.bind(this), frameRate);
        sprite.animating = true;
      }
    }

    //`advanceFrame` is called by `setInterval` to display the next frame
    //in the sequence based on the `frameRate`. When the frame sequence
    //reaches the end, it will either stop or loop
    function advanceFrame() {
      //Advance the frame if `frameCounter` is less than
      //the state's total frames
      if (frameCounter < numberOfFrames + 1) {
        //Advance the frame
        sprite.gotoAndStop(sprite.currentFrame + 1);

        //Update the frame counter
        frameCounter += 1;

        //If we've reached the last frame and `loop`
        //is `true`, then start from the first frame again
      } else {
        if (sprite.loop) {
          sprite.gotoAndStop(startFrame);
          frameCounter = 1;
        }
      }
    }

    function reset() {
      //Reset `sprite.playing` to `false`, set the `frameCounter` to 0, //and clear the `timerInterval`
      if (timerInterval !== undefined && sprite.animating === true) {
        sprite.animating = false;
        frameCounter = 0;
        startFrame = 0;
        endFrame = 0;
        numberOfFrames = 0;
        clearInterval(timerInterval);
      }
    }

    //Add the `show`, `play`, `stop`, and `playSequence` methods to the sprite
    sprite.show = show;
    sprite.stopAnimation = stopAnimation;
    sprite.playAnimation = playAnimation;
  }

  //`tilingSpirte` lets you quickly create Pixi tiling sprites
  tilingSprite(source, width, height, x, y) {
    if (width === undefined) {
      throw new Error(
        "Please define a width as your second argument for the tiling sprite"
      );
    }
    if (height === undefined) {
      throw new Error(
        "Please define a height as your third argument for the tiling sprite"
      );
    }
    let o = this.sprite(source, x, y, true, width, height);

    //Add `tileX`, `tileY`, `tileScaleX` and `tileScaleY` properties
    Object.defineProperties(o, {
      tileX: {
        get() {
          return o.tilePosition.x;
        },
        set(value) {
          o.tilePosition.x = value;
        },
        enumerable: true,
        configurable: true,
      },
      tileY: {
        get() {
          return o.tilePosition.y;
        },
        set(value) {
          o.tilePosition.y = value;
        },
        enumerable: true,
        configurable: true,
      },
      tileScaleX: {
        get() {
          return o.tileScale.x;
        },
        set(value) {
          o.tileScale.x = value;
        },
        enumerable: true,
        configurable: true,
      },
      tileScaleY: {
        get() {
          return o.tileScale.y;
        },
        set(value) {
          o.tileScale.y = value;
        },
        enumerable: true,
        configurable: true,
      },
    });

    return o;
  }

  filmstrip(texture, frameWidth, frameHeight, spacing = 0) {
    //An array to store the x/y positions of the frames
    let positions = [];

    //Find the width and height of the texture
    let textureWidth = this.TextureCache[texture].width,
      textureHeight = this.TextureCache[texture].height;

    //Find out how many columns and rows there are
    let columns = textureWidth / frameWidth,
      rows = textureHeight / frameHeight;

    //Find the total number of frames
    let numberOfFrames = columns * rows;

    for (let i = 0; i < numberOfFrames; i++) {
      //Find the correct row and column for each frame
      //and figure out its x and y position
      let x = (i % columns) * frameWidth,
        y = Math.floor(i / columns) * frameHeight;

      //Compensate for any optional spacing (padding) around the tiles if
      //there is any. This bit of code accumlates the spacing offsets from the
      //left side of the tileset and adds them to the current tile's position
      if (spacing > 0) {
        x += spacing + ((spacing * i) % columns);
        y += spacing + spacing * Math.floor(i / columns);
      }

      //Add the x and y value of each frame to the `positions` array
      positions.push([x, y]);
    }

    //Return the frames
    return this.frames(texture, positions, frameWidth, frameHeight);
  }

  //Make a texture from a frame in another texture or image
  frame(source, x, y, width, height) {
    let texture, imageFrame;

    //If the source is a string, it's either a texture in the
    //cache or an image file
    if (typeof source === "string") {
      if (this.TextureCache[source]) {
        texture = new this.Texture(this.TextureCache[source]);
      }
    }

    //If the `source` is a texture,  use it
    else if (source instanceof this.Texture) {
      texture = new this.Texture(source);
    }
    if (!texture) {
      throw new Error(`Please load the ${source} texture into the cache.`);
    } else {
      //Make a rectangle the size of the sub-image
      imageFrame = new this.Rectangle(x, y, width, height);
      texture.frame = imageFrame;
      return texture;
    }
  }

  //Make an array of textures from a 2D array of frame x and y coordinates in
  //texture
  frames(source, coordinates, frameWidth, frameHeight) {
    let baseTexture,
      textures = [];

    //If the source is a string, it's either a texture in the
    //cache or an image file
    if (typeof source === "string") {
      if (this.TextureCache[source]) {
        baseTexture = new this.Texture(this.TextureCache[source]);
      }
    }
    //If the `source` is a texture,  use it
    else if (source instanceof this.Texture) {
      baseTexture = new this.Texture(source);
    }
    if (!baseTexture) {
      throw new Error(`Please load the ${source} texture into the cache.`);
    } else {
      textures = coordinates.map((position) => {
        let x = position[0],
          y = position[1];
        let imageFrame = new this.Rectangle(x, y, frameWidth, frameHeight);
        let frameTexture = new this.Texture(baseTexture);
        frameTexture.frame = imageFrame;
        return frameTexture;
      });
      return textures;
    }
  }

  frameSeries(startNumber = 0, endNumber = 1, baseName = "", extension = "") {
    //Create an array to store the frame names
    let frames = [];

    for (let i = startNumber; i < endNumber + 1; i++) {
      let frame = this.TextureCache[`${baseName + i + extension}`];
      frames.push(frame);
    }
    return frames;
  }

  /* Text creation */

  //The`text` method is a quick way to create a Pixi Text sprite
  text(
    content = "message",
    fontFamily = "Helvetica, Arial, sans-serif",
    fontSize = 16,
    fill = "red",
    x = 0,
    y = 0
  ) {
    //Create a Pixi Sprite object
    let message = new this.Text(content, { fontFamily, fontSize, fill });
    message.x = x;
    message.y = y;

    //Add a `_text` property with a getter/setter
    message._content = content;
    Object.defineProperty(message, "content", {
      get() {
        return this._content;
      },
      set(value) {
        this._content = value;
        this.text = value;
      },
      enumerable: true,
      configurable: true,
    });

    //Return the text object
    return message;
  }

  //The`bitmapText` method lets you create bitmap text
  bitmapText(content = "message", font, align, tint, x = 0, y = 0) {
    //Create a Pixi Sprite object
    let message = new this.BitmapText(content, {
      font: font,
      align: align,
      tint: tint,
    });
    message.x = x;
    message.y = y;

    //Add a `_text` property with a getter/setter
    message._content = content;
    Object.defineProperty(message, "content", {
      get() {
        return this._content;
      },
      set(value) {
        this._content = value;
        this.text = value;
      },
      enumerable: true,
      configurable: true,
    });

    //Return the text object
    return message;
  }

  /* Shapes and lines */

  //Rectangle
  rectangle(
    width = 32,
    height = 32,
    fillStyle = 0xff3300,
    strokeStyle = 0x0033cc,
    lineWidth = 0,
    radius = 0,
    x = 0,
    y = 0
  ) {
    let o = new this.Graphics();
    o._sprite = undefined;
    o._width = width;
    o._height = height;
    o._fillStyleColor = this.color(fillStyle);
    o._strokeStyleColor = this.color(strokeStyle);
    o._lineWidth = lineWidth;
    o._radius = radius;

    //Draw the rectangle
    let draw = (width, height, fillStyle, strokeStyle, lineWidth) => {
      o.clear();
      o.beginFill(fillStyle);
      if (lineWidth > 0) {
        o.lineStyle(lineWidth, strokeStyle, 1);
      }
      if (o._radius === 0) {
        o.drawRect(0, 0, width, height);
      } else {
        o.drawRoundedRect(0, 0, width, height, radius);
      }
      o.endFill();
    };

    //Draw the line and capture the sprite that the `draw` function
    //returns
    draw(
      o._width,
      o._height,
      o._fillStyleColor,
      o._strokeStyleColor,
      o._lineWidth
    );

    //Generate a texture from the rectangle
    //let texture = o.generateTexture();
    let texture = this.pixiRenderer.generateTexture(o);

    //Use the texture to create a sprite
    let sprite = new this.Sprite(texture);

    //Position the sprite
    sprite.x = x;
    sprite.y = y;

    //Add getters and setters to the sprite
    let self = this;
    Object.defineProperties(sprite, {
      fillStyle: {
        get() {
          return o._fillStyle;
        },
        set(value) {
          o._fillStyle = self.color(value);

          //Draw the new rectangle
          draw(
            o._width,
            o._height,
            o._fillStyle,
            o._strokeStyle,
            o._lineWidth,
            o._x,
            o._y
          );

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
      strokeStyle: {
        get() {
          return o._strokeStyle;
        },
        set(value) {
          o._strokeStyle = self.color(value);

          //Draw the new rectangle
          draw(
            o._width,
            o._height,
            o._fillStyle,
            o._strokeStyle,
            o._lineWidth,
            o._x,
            o._y
          );

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
      lineWidth: {
        get() {
          return o._lineWidth;
        },
        set(value) {
          o._lineWidth = value;

          //Draw the new rectangle
          draw(
            o._width,
            o._height,
            o._fillStyle,
            o._strokeStyle,
            o._lineWidth,
            o._x,
            o._y
          );

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
    });

    //Get a local reference to the sprite so that we can
    //change the rectangle properties later using the getters/setters
    o._sprite = sprite;

    //Return the sprite
    return sprite;
  }

  //Circle
  circle(
    diameter = 32,
    fillStyle = 0xff3300,
    strokeStyle = 0x0033cc,
    lineWidth = 0,
    x = 0,
    y = 0
  ) {
    let o = new this.Graphics();
    o._diameter = diameter;
    o._fillStyleColor = this.color(fillStyle);
    o._strokeStyleColor = this.color(strokeStyle);
    o._lineWidth = lineWidth;

    //Draw the circle
    let draw = (diameter, fillStyle, strokeStyle, lineWidth) => {
      o.clear();
      o.beginFill(fillStyle);
      if (lineWidth > 0) {
        o.lineStyle(lineWidth, strokeStyle, 1);
      }
      o.drawCircle(0, 0, diameter / 2);
      o.endFill();
    };

    //Draw the cirlce
    draw(o._diameter, o._fillStyleColor, o._strokeStyleColor, o._lineWidth);

    //Generate a texture from the rectangle
    let texture = this.pixiRenderer.generateTexture(o);

    //Use the texture to create a sprite
    let sprite = new this.Sprite(texture);

    //Position the sprite
    sprite.x = x;
    sprite.y = y;

    //Add getters and setters to the sprite
    let self = this;
    Object.defineProperties(sprite, {
      fillStyle: {
        get() {
          return o._fillStyleColor;
        },
        set(value) {
          o._fillStyleColor = self.color(value);

          //Draw the cirlce
          draw(
            o._diameter,
            o._fillStyleColor,
            o._strokeStyleColor,
            o._lineWidth
          );

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
      strokeStyle: {
        get() {
          return o._strokeStyleColor;
        },
        set(value) {
          o._strokeStyleColor = self.color(value);

          //Draw the cirlce
          draw(
            o._diameter,
            o._fillStyleColor,
            o._strokeStyleColor,
            o._lineWidth
          );

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
      diameter: {
        get() {
          return o._diameter;
        },
        set(value) {
          console.log(value);
          o._lineWidth = 10;

          //Draw the cirlce
          draw(
            o._diameter,
            o._fillStyleColor,
            o._strokeStyleColor,
            o._lineWidth
          );

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
      radius: {
        get() {
          return o._diameter / 2;
        },
        set(value) {
          //Draw the cirlce
          draw(value * 2, o._fillStyleColor, o._strokeStyleColor, o._lineWidth);

          //Generate a new texture and set it as the sprite's texture
          let texture = this.pixiRenderer.generateTexture(o);
          o._sprite.texture = texture;
        },
        enumerable: true,
        configurable: true,
      },
    });
    //Get a local reference to the sprite so that we can
    //change the circle properties later using the getters/setters
    o._sprite = sprite;

    //Return the sprite
    return sprite;
  }

  //Line
  line(
    strokeStyle = 0x000000,
    lineWidth = 1,
    ax = 0,
    ay = 0,
    bx = 32,
    by = 32
  ) {
    //Create the line object
    let o = new this.Graphics();

    //Private properties
    o._strokeStyleColor = this.color(strokeStyle);
    o._width = lineWidth;
    o._ax = ax;
    o._ay = ay;
    o._bx = bx;
    o._by = by;

    //A helper function that draws the line
    let draw = (strokeStyle, lineWidth, ax, ay, bx, by) => {
      o.clear();
      o.lineStyle(lineWidth, strokeStyle, 1);
      o.moveTo(ax, ay);
      o.lineTo(bx, by);
    };

    //Draw the line
    draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);

    //Define getters and setters that redefine the line's start and
    //end points and re-draws it if they change
    let self = this;
    Object.defineProperties(o, {
      ax: {
        get() {
          return o._ax;
        },
        set(value) {
          o._ax = value;
          draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);
        },
        enumerable: true,
        configurable: true,
      },
      ay: {
        get() {
          return o._ay;
        },
        set(value) {
          o._ay = value;
          draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);
        },
        enumerable: true,
        configurable: true,
      },
      bx: {
        get() {
          return o._bx;
        },
        set(value) {
          o._bx = value;
          draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);
        },
        enumerable: true,
        configurable: true,
      },
      by: {
        get() {
          return o._by;
        },
        set(value) {
          o._by = value;
          draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);
        },
        enumerable: true,
        configurable: true,
      },
      strokeStyle: {
        get() {
          return o._strokeStyleColor;
        },
        set(value) {
          o._strokeStyleColor = self.color(value);

          //Draw the line
          draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);
        },
        enumerable: true,
        configurable: true,
      },
      width: {
        get() {
          return o._width;
        },
        set(value) {
          o._width = value;

          //Draw the line
          draw(o._strokeStyleColor, o._width, o._ax, o._ay, o._bx, o._by);
        },
        enumerable: true,
        configurable: true,
      },
    });

    //Return the line
    return o;
  }

  /* Compound sprites */

  //Use `grid` to create a grid of sprites
  grid(
    columns = 0,
    rows = 0,
    cellWidth = 32,
    cellHeight = 32,
    centerCell = false,
    xOffset = 0,
    yOffset = 0,
    makeSprite = undefined,
    extra = undefined
  ) {
    //Create an empty group called `container`. This `container`
    //group is what the function returns back to the main program.
    //All the sprites in the grid cells will be added
    //as children to this container
    let container = new this.Container();

    //The `create` method plots the grid

    let createGrid = () => {
      //Figure out the number of cells in the grid
      let length = columns * rows;

      //Create a sprite for each cell
      for (let i = 0; i < length; i++) {
        //Figure out the sprite's x/y placement in the grid
        let x = (i % columns) * cellWidth,
          y = Math.floor(i / columns) * cellHeight;

        //Use the `makeSprite` function supplied in the constructor
        //to make a sprite for the grid cell
        let sprite = makeSprite();

        //Add the sprite to the `container`
        container.addChild(sprite);

        //Should the sprite be centered in the cell?

        //No, it shouldn't be centered
        if (!centerCell) {
          sprite.x = x + xOffset;
          sprite.y = y + yOffset;
        }

        //Yes, it should be centered
        else {
          sprite.x = x + cellWidth / 2 - sprite.width / 2 + xOffset;
          sprite.y = y + cellHeight / 2 - sprite.width / 2 + yOffset;
        }

        //Run any optional extra code. This calls the
        //`extra` function supplied by the constructor
        if (extra) extra(sprite);
      }
    };

    //Run the `createGrid` method
    createGrid();

    //Return the `container` group back to the main program
    return container;
  }

  //Use `shoot` to create bullet sprites
  shoot(
    shooter,
    angle,
    x,
    y,
    container,
    bulletSpeed,
    bulletArray,
    bulletSprite
  ) {
    //Make a new sprite using the user-supplied `bulletSprite` function
    let bullet = bulletSprite();

    //Set the bullet's anchor point to its center
    bullet.anchor.set(0.5, 0.5);

    //Temporarily add the bullet to the shooter
    //so that we can position it relative to the
    //shooter's position
    shooter.addChild(bullet);
    bullet.x = x;
    bullet.y = y;

    //Find the bullet's global coordinates so that we can use
    //them to position the bullet on the new parent container
    let tempGx = bullet.getGlobalPosition().x,
      tempGy = bullet.getGlobalPosition().y;

    //Add the bullet to the new parent container using
    //the new global coordinates
    container.addChild(bullet);
    bullet.x = tempGx;
    bullet.y = tempGy;

    //Set the bullet's velocity
    bullet.vx = Math.cos(angle) * bulletSpeed;
    bullet.vy = Math.sin(angle) * bulletSpeed;

    //Push the bullet into the `bulletArray`
    bulletArray.push(bullet);
  }

  /*
    grid
    ----
  
    Helps you to automatically create a grid of sprites. `grid` returns a
    `group` sprite object that contains a sprite for every cell in the
    grid. You can define the rows and columns in the grid, whether or
    not the sprites should be centered inside each cell, or what their offset from the
    top left corner of each cell should be. Supply a function that
    returns the sprite that you want to make for each cell. You can
    supply an optional final function that runs any extra code after
    each sprite has been created. Here's the format for creating a grid:
  
        gridGroup = grid(
  
          //Set the grid's properties
          columns, rows, cellWidth, cellHeight,
          areSpirtesCentered?, xOffset, yOffset,
  
          //A function that returns a sprite
          () => g.circle(16, "blue"),
  
          //An optional final function that runs some extra code
          () => console.log("extra!")
        );
    */

  // grid(
  //     columns = 0,
  //     rows = 0,
  //     cellWidth = 32,
  //     cellHeight = 32,
  //     centerCell = false,
  //     xOffset = 0,
  //     yOffset = 0,
  //     makeSprite = undefined,
  //     extra = undefined
  // ) {
  //     //Create an empty group called `container`. This `container`
  //     //group is what the function returns back to the main program.
  //     //All the sprites in the grid cells will be added
  //     //as children to this container
  //     let container = this.group();

  //     //The `create` method plots the grid
  //     let createGrid = () => {
  //         //Figure out the number of cells in the grid
  //         let length = columns * rows;

  //         //Create a sprite for each cell
  //         for (let i = 0; i < length; i++) {
  //             //Figure out the sprite's x/y placement in the grid
  //             let x = (i % columns) * cellWidth,
  //                 y = Math.floor(i / columns) * cellHeight;

  //             //Use the `makeSprite` function supplied in the constructor
  //             //to make a sprite for the grid cell
  //             let sprite = makeSprite();

  //             //Add the sprite to the `container`
  //             container.addChild(sprite);

  //             //Should the sprite be centered in the cell?

  //             //No, it shouldn't be centered
  //             if (!centerCell) {
  //                 sprite.x = x + xOffset;
  //                 sprite.y = y + yOffset;
  //             }

  //             //Yes, it should be centered
  //             else {
  //                 sprite.x = x + cellWidth / 2 - sprite.halfWidth + xOffset;
  //                 sprite.y = y + cellHeight / 2 - sprite.halfHeight + yOffset;
  //             }

  //             //Run any optional extra code. This calls the
  //             //`extra` function supplied by the constructor
  //             if (extra) extra(sprite);
  //         }
  //     };

  //     //Run the `createGrid` method
  //     createGrid();

  //     //Return the `container` group back to the main program
  //     return container;
  // }

  /*
    shake
    -----
  
    Used to create a shaking effect, like a screen shake
    */

  shake(sprite, magnitude = 16, angular = false) {
    //Get a reference to this current object so that
    //it's easy to maintain scope in the nested sub-functions
    let self = this;

    //A counter to count the number of shakes
    let counter = 1;

    //The total number of shakes (there will be 1 shake per frame)
    let numberOfShakes = 10;

    //Capture the sprite's position and angle so you can
    //restore them after the shaking has finished
    let startX = sprite.x,
      startY = sprite.y,
      startAngle = sprite.rotation;

    //Divide the magnitude into 10 units so that you can
    //reduce the amount of shake by 10 percent each frame
    let magnitudeUnit = magnitude / numberOfShakes;

    //The `randomInt` helper function
    let randomInt = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    //Add the sprite to the `shakingSprites` array if it
    //isn't already there
    if (self.shakingSprites.indexOf(sprite) === -1) {
      self.shakingSprites.push(sprite);

      //Add an `updateShake` method to the sprite.
      //The `updateShake` method will be called each frame
      //in the game loop. The shake effect type can be either
      //up and down (x/y shaking) or angular (rotational shaking).
      sprite.updateShake = () => {
        if (angular) {
          angularShake();
        } else {
          upAndDownShake();
        }
      };
    }

    //The `upAndDownShake` function
    function upAndDownShake() {
      //Shake the sprite while the `counter` is less than
      //the `numberOfShakes`
      if (counter < numberOfShakes) {
        //Reset the sprite's position at the start of each shake
        sprite.x = startX;
        sprite.y = startY;

        //Reduce the magnitude
        magnitude -= magnitudeUnit;

        //Randomly change the sprite's position
        sprite.x += randomInt(-magnitude, magnitude);
        sprite.y += randomInt(-magnitude, magnitude);

        //Add 1 to the counter
        counter += 1;
      }

      //When the shaking is finished, restore the sprite to its original
      //position and remove it from the `shakingSprites` array
      if (counter >= numberOfShakes) {
        sprite.x = startX;
        sprite.y = startY;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }

    //The `angularShake` function
    //First set the initial tilt angle to the right (+1)
    let tiltAngle = 1;

    function angularShake() {
      if (counter < numberOfShakes) {
        //Reset the sprite's rotation
        sprite.rotation = startAngle;

        //Reduce the magnitude
        magnitude -= magnitudeUnit;

        //Rotate the sprite left or right, depending on the direction,
        //by an amount in radians that matches the magnitude
        sprite.rotation = magnitude * tiltAngle;
        counter += 1;

        //Reverse the tilt angle so that the sprite is tilted
        //in the opposite direction for the next shake
        tiltAngle *= -1;
      }

      //When the shaking is finished, reset the sprite's angle and
      //remove it from the `shakingSprites` array
      if (counter >= numberOfShakes) {
        sprite.rotation = startAngle;
        self.shakingSprites.splice(self.shakingSprites.indexOf(sprite), 1);
      }
    }
  }

  /*
    _getCenter
    ----------
  
    A utility that finds the center point of the sprite. If it's anchor point is the
    sprite's top left corner, then the center is calculated from that point.
    If the anchor point has been shifted, then the anchor x/y point is used as the sprite's center
    */

  _getCenter(o, dimension, axis) {
    if (o.anchor !== undefined) {
      if (o.anchor[axis] !== 0) {
        return 0;
      } else {
        return dimension / 2;
      }
    } else {
      return dimension;
    }
  }

  /* Groups */

  //Group sprites into a container
  group(...sprites) {
    let container = new this.Container();
    sprites.forEach((sprite) => {
      container.addChild(sprite);
    });
    return container;
  }

  //Use the `batch` method to create a ParticleContainer
  batch(
    size = 15000,
    options = { rotation: true, alpha: true, scale: true, uvs: true }
  ) {
    let o = new this.ParticleContainer(size, options);
    return o;
  }

  //`remove` is a global convenience method that will
  //remove any sprite, or an argument list of sprites, from its parent.
  remove(...sprites) {
    //Remove sprites that's aren't in an array
    if (!(sprites[0] instanceof Array)) {
      if (sprites.length > 1) {
        sprites.forEach((sprite) => {
          sprite.parent.removeChild(sprite);
        });
      } else {
        sprites[0].parent.removeChild(sprites[0]);
      }
    }

    //Remove sprites in an array of sprites
    else {
      let spritesArray = sprites[0];
      if (spritesArray.length > 0) {
        for (let i = spritesArray.length - 1; i >= 0; i--) {
          let sprite = spritesArray[i];
          sprite.parent.removeChild(sprite);
          spritesArray.splice(spritesArray.indexOf(sprite), 1);
        }
      }
    }
  }

  /* Color conversion */
  //From: http://stackoverflow.com/questions/1573053/javascript-function-to-convert-color-names-to-hex-codes
  //Utilities to convert HTML color string names to hexadecimal codes

  colorToRGBA(color) {
    // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
    // color must be a valid canvas fillStyle. This will cover most anything
    // you'd want to use.
    // Examples:
    // colorToRGBA('red')  # [255, 0, 0, 255]
    // colorToRGBA('#f00') # [255, 0, 0, 255]
    var cvs, ctx;
    cvs = document.createElement("canvas");
    cvs.height = 1;
    cvs.width = 1;
    ctx = cvs.getContext("2d");
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    let data = ctx.getImageData(0, 0, 1, 1).data;
    return data;
  }

  byteToHex(num) {
    // Turns a number (0-255) into a 2-character hex number (00-ff)
    return ("0" + num.toString(16)).slice(-2);
  }

  colorToHex(color) {
    // Convert any CSS color to a hex representation
    // Examples:
    // colorToHex('red')            # '#ff0000'
    // colorToHex('rgb(255, 0, 0)') # '#ff0000'
    var rgba, hex;
    rgba = this.colorToRGBA(color);
    hex = [0, 1, 2].map((idx) => this.byteToHex(rgba[idx])).join("");
    return "0x" + hex;
  }

  //A function to find out if the user entered a number (a hex color
  //code) or a string (an HTML color string)
  color(value) {
    //Check if it's a number
    if (!isNaN(value)) {
      //Yes, it is a number, so just return it
      return value;
    }

    //No it's not a number, so it must be a string
    else {
      return parseInt(this.colorToHex(value));
      /*
      
            //Find out what kind of color string it is.
            //Let's first grab the first character of the string
            let firstCharacter = value.charAt(0);
      
            //If the first character is a "#" or a number, then
            //we know it must be a RGBA color
            if (firstCharacter === "#") {
              console.log("first character: " + value.charAt(0))
            }
            */
    }

    /*
        //Find out if the first character in the string is a number
        if (!isNaN(parseInt(string.charAt(0)))) {
          
          //It's not, so convert it to a hex code
          return colorToHex(string);
          
        //The use input a number, so it must be a hex code. Just return it
        } else {
        
          return string;
        }
        
      */
  }
}

// 绘制用户发送的信息
export function showUserMessage(message) {
  // 创建图形对象
  const bubble = new PIXI.Graphics();

  // 设置线条样式（边框宽度，边框颜色）
  bubble.lineStyle(1, 0x7de3ae, 1);

  // 设置气泡的样式
  const bubbleColor = "#000000"; // 气泡颜色
  const bubbleAlpha = 0.75; // 气泡透明度
  const borderRadius = 10; // 气泡边角的圆角半径
  const padding = 10; // 文本与气泡边缘的间距

  // 创建文本对象
  const style = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 16,
    fill: "#FFFFFF",
    breakWords: true,
    wordWrap: true,
    wordWrapWidth: 516,
    lineHeight: 16,
    fontWeight: "400",
  });

  const text = new PIXI.Text(message, style);

  // 计算气泡的尺寸
  const bubbleWidth = text.width + 2 * padding;
  const bubbleHeight = text.height + 2 * padding;

  // 绘制气泡形状
  bubble.beginFill(bubbleColor, bubbleAlpha);
  bubble.drawRoundedRect(0, 0, bubbleWidth, bubbleHeight, borderRadius);

  // 绘制一个小三角形作为气泡的尾巴
  bubble.moveTo(30, bubbleHeight); // 尾巴的起点
  bubble.lineTo(40, bubbleHeight + 20); // 尾巴的中点
  bubble.lineTo(50, bubbleHeight); // 尾巴的终点
  // bubble.lineTo(bubbleWidth - 70, bubbleHeight); // 封闭三角形

  bubble.endFill();

  // 设置文本位置
  text.x = padding;
  text.y = padding;

  // 创建一个容器来组合气泡和文本
  let container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(text);
  container.visible = true;
  // 将容器添加到舞台上
  app.stage.addChild(container);

  // 可以根据需要调整气泡的位置
  container.x = player.x + player.width / 2 - 40;
  container.y = player.y - bubbleHeight - 30;

  let newContainer = drawUserTag(
    player.x + player.width / 2 - 40,
    player.y - bubbleHeight - 30,
    languageData.tip117
  );

  setTimeout(function () {
    container.visible = false;
    newContainer.visible = false;
  }, 3000);
}

// 绘制用户标签
function drawUserTag(x, y, message) {
  // 创建图形对象
  const bubble = new PIXI.Graphics();

  // 设置线条样式（边框宽度，边框颜色）
  bubble.lineStyle(1, 0x7de3ae, 1);

  // 设置气泡的样式
  const bubbleColor = 0x17c469; // 气泡颜色
  const bubbleAlpha = 0.75; // 气泡透明度
  const borderRadius = 3; // 气泡边角的圆角半径
  const padding = 5; // 文本与气泡边缘的间距

  // 创建文本对象
  const style = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 12,
    fill: "#FFFFFF",
    breakWords: true,
    wordWrap: true,
    wordWrapWidth: 516,
    lineHeight: 12,
    fontWeight: "400",
  });

  const text = new PIXI.Text(message, style);

  // 计算气泡的尺寸
  const bubbleWidth = text.width + 2 * padding;
  const bubbleHeight = text.height + 2 * padding;

  // 绘制气泡形状
  bubble.beginFill(bubbleColor, bubbleAlpha);
  bubble.drawRoundedRect(0, 0, bubbleWidth, bubbleHeight, borderRadius);

  bubble.endFill();

  // 设置文本位置
  text.x = padding;
  text.y = padding;

  // 创建一个容器来组合气泡和文本
  let container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(text);
  container.visible = true;
  // 将容器添加到舞台上
  app.stage.addChild(container);

  // 可以根据需要调整气泡的位置
  container.x = x;
  container.y = y - bubbleHeight + 3;

  return container;
}

// 绘制请求正在返回提示
export function showTipMessage(node, message) {
  // 创建图形对象
  const bubble = new PIXI.Graphics();

  // 设置线条样式（边框宽度，边框颜色）
  bubble.lineStyle(1, 0x3681fc, 1);

  // 设置气泡的样式
  const bubbleColor = "#000000"; // 气泡颜色
  const bubbleAlpha = 0.75; // 气泡透明度
  const borderRadius = 30; // 气泡边角的圆角半径
  const padding = 10; // 文本与气泡边缘的间距

  // 创建文本对象
  const style = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 16,
    fill: "#FFFFFF",
    breakWords: true,
    wordWrap: true,
    wordWrapWidth: 516,
    lineHeight: 16,
    fontWeight: "400",
  });

  const text = new PIXI.Text(message, style);

  // 计算气泡的尺寸
  const bubbleWidth = text.width + 2 * padding;
  const bubbleHeight = text.height + 2 * padding;

  // 绘制气泡形状
  bubble.beginFill(bubbleColor, bubbleAlpha);
  bubble.drawRoundedRect(0, 0, bubbleWidth, bubbleHeight, borderRadius);
  bubble.endFill();

  // 设置文本位置
  text.x = padding;
  text.y = padding;

  // 创建一个容器来组合气泡和文本
  const container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(text);
  container.visible = true;
  // 将容器添加到舞台上
  app.stage.addChild(container);

  // 可以根据需要调整气泡的位置
  if (
    node.properties.nodeSize &&
    node.properties.nodeSize.width &&
    node.properties.nodeSize.height
  ) {
    container.x = node.x - bubbleWidth / 2;
    container.y = node.y + node.properties.nodeSize.height / 2 + 10;
  } else {
    container.x = node.x - bubbleWidth / 2;
    container.y = node.y + 50 + 10;
  }

  tipContainer = container;
}

// 绘制输入提示
export function showUserTipMessage(message) {
  // 创建图形对象
  const bubble = new PIXI.Graphics();

  // 设置线条样式（边框宽度，边框颜色）
  bubble.lineStyle(1, 0x3681fc, 1);

  // 设置气泡的样式
  const bubbleColor = "#000000"; // 气泡颜色
  const bubbleAlpha = 0.75; // 气泡透明度
  const borderRadius = 30; // 气泡边角的圆角半径
  const padding = 10; // 文本与气泡边缘的间距

  // 创建文本对象
  const style = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 16,
    fill: "#FFFFFF",
    breakWords: true,
    wordWrap: true,
    wordWrapWidth: 516,
    lineHeight: 16,
    fontWeight: "400",
  });

  const text = new PIXI.Text(message, style);

  // 计算气泡的尺寸
  const bubbleWidth = text.width + 2 * padding;
  const bubbleHeight = text.height + 2 * padding;

  // 绘制气泡形状
  bubble.beginFill(bubbleColor, bubbleAlpha);
  bubble.drawRoundedRect(0, 0, bubbleWidth, bubbleHeight, borderRadius);
  bubble.endFill();

  // 设置文本位置
  text.x = padding;
  text.y = padding;

  // 创建一个容器来组合气泡和文本
  const container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(text);
  container.visible = true;
  // 将容器添加到舞台上
  app.stage.addChild(container);

  // 可以根据需要调整气泡的位置
  container.x = player.x;
  container.y = player.y + player.height / 2 + bubbleHeight + 30;

  userTipContainer = container;
}

// 绘制AI NPC 返回的信息
export function showAIMessage(node, message) {
  if (tipContainer != null) {
    tipContainer.visible = false;
    tipContainer = null;
  }

  // 创建图形对象
  const bubble = new PIXI.Graphics();

  // 设置线条样式（边框宽度，边框颜色）
  bubble.lineStyle(1, 0xffc399, 1);

  // 设置气泡的样式
  const bubbleColor = "#000000"; // 气泡颜色
  const bubbleAlpha = 0.75; // 气泡透明度
  const borderRadius = 10; // 气泡边角的圆角半径
  const padding = 10; // 文本与气泡边缘的间距

  // 创建文本对象
  const style = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 16,
    fill: "#FFFFFF",
    breakWords: true,
    wordWrap: true,
    wordWrapWidth: 516,
    lineHeight: 16,
    fontWeight: "400",
  });

  const text = new PIXI.Text(message, style);

  // 计算气泡的尺寸
  const bubbleWidth = text.width + 2 * padding;
  const bubbleHeight = text.height + 2 * padding;

  // 绘制气泡形状
  bubble.beginFill(bubbleColor, bubbleAlpha);
  bubble.drawRoundedRect(0, 0, bubbleWidth, bubbleHeight, borderRadius);

  // 绘制一个小三角形作为气泡的尾巴
  bubble.moveTo(bubbleWidth - 90, bubbleHeight); // 尾巴的起点
  bubble.lineTo(bubbleWidth - 80, bubbleHeight + 20); // 尾巴的中点
  bubble.lineTo(bubbleWidth - 70, bubbleHeight); // 尾巴的终点
  // bubble.lineTo(bubbleWidth - 80, bubbleHeight); // 封闭三角形

  bubble.endFill();

  // 设置文本位置
  text.x = padding;
  text.y = padding;

  // 创建一个容器来组合气泡和文本
  const container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(text);
  container.visible = true;
  // 将容器添加到舞台上
  app.stage.addChild(container);

  let newContainer = null;

  // 可以根据需要调整气泡的位置
  if (
    node.properties.nodeSize &&
    node.properties.nodeSize.width &&
    node.properties.nodeSize.height
  ) {
    container.x = node.x - bubbleWidth + 80;
    container.y =
      node.y - node.properties.nodeSize.height / 2 - bubbleHeight - 30;
    newContainer = drawAITag(
      node.x + 80,
      node.y - node.properties.nodeSize.height / 2 - bubbleHeight - 30,
      node.properties.name
    );
  } else {
    container.x = node.x - bubbleWidth + 80;
    container.y = node.y - 50 - bubbleHeight - 30;
    newContainer = drawAITag(
      node.x + 80,
      node.y - 50 - bubbleHeight - 30,
      node.properties.name
    );
  }

  setTimeout(function () {
    container.visible = false;
    newContainer.visible = false;
  }, 3000);
}

// 绘制AI NPC名称标签
function drawAITag(x, y, message) {
  // 创建图形对象
  const bubble = new PIXI.Graphics();

  // 设置线条样式（边框宽度，边框颜色）
  bubble.lineStyle(1, 0xffc399, 1);

  // 设置气泡的样式
  const bubbleColor = 0xc96720; // 气泡颜色
  const bubbleAlpha = 0.75; // 气泡透明度
  const borderRadius = 3; // 气泡边角的圆角半径
  const padding = 5; // 文本与气泡边缘的间距

  // 创建文本对象
  const style = new PIXI.TextStyle({
    fontFamily: "Arial",
    fontSize: 12,
    fill: "#FFFFFF",
    breakWords: true,
    wordWrap: true,
    wordWrapWidth: 516,
    lineHeight: 12,
    fontWeight: "400",
  });

  const text = new PIXI.Text(message, style);

  // 计算气泡的尺寸
  const bubbleWidth = text.width + 2 * padding;
  const bubbleHeight = text.height + 2 * padding;

  // 绘制气泡形状
  bubble.beginFill(bubbleColor, bubbleAlpha);
  bubble.drawRoundedRect(0, 0, bubbleWidth, bubbleHeight, borderRadius);

  bubble.endFill();

  // 设置文本位置
  text.x = padding;
  text.y = padding;

  // 创建一个容器来组合气泡和文本
  let container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(text);
  container.visible = true;
  // 将容器添加到舞台上
  app.stage.addChild(container);

  // 可以根据需要调整气泡的位置
  container.x = x - container.width;
  container.y = y - bubbleHeight + 3;

  return container;
}

// 销毁输入提示
export function destoryUserTipMessage() {
  if (userTipContainer != null) {
    userTipContainer.visible = false;
    userTipContainer = null;
  }
}

// 销毁请求正在返回提示
export function destoryTipMessage() {
  if (tipContainer != null) {
    tipContainer.visible = false;
    tipContainer = null;
  }
}

// 取消输入状态
export function setIsTip() {
  isTip = false;
  // container.removeChild(overlay_new);
}

// 键盘函数
function keyboard(value) {
  let key = {};
  key.value = value;
  key.isDown = false;
  key.isUp = true;
  key.press = undefined;
  key.release = undefined;
  //The `downHandler`
  key.downHandler = (event) => {
    if (event.key === key.value) {
      if (key.isUp && key.press) key.press();
      key.isDown = true;
      key.isUp = false;
      event.preventDefault();
    }
  };

  //The `upHandler`
  key.upHandler = (event) => {
    if (event.key === key.value) {
      if (key.isDown && key.release) key.release();
      key.isDown = false;
      key.isUp = true;
      event.preventDefault();
    }
  };

  //Attach event listeners
  const downListener = key.downHandler.bind(key);
  const upListener = key.upHandler.bind(key);

  window.addEventListener("keydown", downListener, false);
  window.addEventListener("keyup", upListener, false);

  // Detach event listeners
  key.unsubscribe = () => {
    window.removeEventListener("keydown", downListener);
    window.removeEventListener("keyup", upListener);
  };

  return key;
}

// 键盘控制移动
function keyMove(person, key) {
  let left = keyboard(key.left),
    up = keyboard(key.up),
    right = keyboard(key.right),
    down = keyboard(key.down);
  left.press = () => {
    // person.show(person.states.left);
    window.moveAndZoom();
    if (!isTip) {
      person.playAnimation(person.states.walkLeft);
      person.vx = -parseInt(key.distance_x);
      person.vy = 0;
    } else {
      $("#input textarea").val($("#input textarea").val() + key.left);
    }
  };
  left.release = () => {
    if (!isTip) {
      person.stopAnimation();
      person.show(person.states.left);
      person.vx = 0;
      person.vy = 0;
    }
  };
  right.press = () => {
    window.moveAndZoom();
    if (!isTip) {
      person.playAnimation(person.states.walkRight);
      person.vx = parseInt(key.distance_x);
      person.vy = 0;
    } else {
      $("#input textarea").val($("#input textarea").val() + key.right);
    }
  };
  right.release = () => {
    if (!isTip) {
      person.stopAnimation();
      person.show(person.states.right);
      person.vx = 0;
      person.vy = 0;
    }
  };
  up.press = () => {
    window.moveAndZoom();
    if (!isTip) {
      person.playAnimation(person.states.walkUp);
      // person.show(person.states.up);
      person.vx = 0;
      person.vy = -parseInt(key.distance_y);
    } else {
      $("#input textarea").val($("#input textarea").val() + key.up);
    }
  };
  up.release = () => {
    if (!isTip) {
      person.stopAnimation();
      person.show(person.states.up);
      person.vx = 0;
      person.vy = 0;
    }
  };
  down.press = () => {
    window.moveAndZoom();
    if (!isTip) {
      person.playAnimation(person.states.walkDown);
      // person.show(person.states.down);
      person.vx = 0;
      person.vy = parseInt(key.distance_y);
    } else {
      $("#input textarea").val($("#input textarea").val() + key.down);
    }
  };
  down.release = () => {
    if (!isTip) {
      person.stopAnimation();
      person.show(person.states.down);
      person.vx = 0;
      person.vy = 0;
    }
  };
}

// 创建遮盖层
// function createOverlay() {
//     const overlay = new PIXI.Graphics();
//     overlay.beginFill(0x000000, 0.5); // 半透明黑色
//     overlay.drawRect(0, 0, app.screen.width, app.screen.height);
//     overlay.endFill();
//     return overlay;
// }

// 移动精灵 Sprite
function gameLoop(person, sprites) {
  if (!isTip) {
    person.x += person.vx;
    person.y += person.vy;
    if (person.vx != 0 || person.vy != 0) {
      window.focusOnNode(person.x, person.y);
    }
  }
  // 对精灵进行边界检测
  const arriveBorder = _moveRangeCheck(person, gameContainer);
  // 精灵抵达边界后,进行反向移动
  if (arriveBorder === "left" || arriveBorder === "right") {
    person.vx *= 0;
  }
  if (arriveBorder === "top" || arriveBorder === "bottom") {
    person.vy *= 0;
  }
  index_new = null;
  player = person;
  player.filters = [];
  sprites.forEach((sprite, index) => {
    // 检测猎人是否碰到了怪物
    let isHit = _hitTestRectangle(person, sprite);
    sprite.filters = [];
    if (isHit) {
      // setTimeout(() => {
      //     window.showDialog(nodes[i])
      // }, 200)
      index_new = index;
      window.selectOnNode(nodes[index]);
      player.filters = [brightnessFilter];
      sprite.filters = [brightnessFilter];
      if (!isTip && nodes[index].type == "icon-ai-npc") {
        if (
          nodes[index].properties.nodeSize &&
          nodes[index].properties.nodeSize.height
        ) {
          if (
            nodes[index].y + nodes[index].properties.nodeSize.height / 2 >=
            player.y + player.height
          ) {
            destoryUserTipMessage();
            destoryTipMessage();
            showTipMessage(nodes[index], languageData.tip116);
          } else {
            destoryTipMessage();
            destoryUserTipMessage();
            showUserTipMessage(languageData.tip116);
          }
        } else {
          if (nodes[index].y + 50 >= player.y + player.height) {
            destoryUserTipMessage();
            destoryTipMessage();
            showTipMessage(nodes[index], languageData.tip116);
          } else {
            destoryTipMessage();
            destoryUserTipMessage();
            showUserTipMessage(languageData.tip116);
          }
        }
      }
      else {
        if (
          nodes[index].properties.nodeSize &&
          nodes[index].properties.nodeSize.height
        ) {
          if (
            nodes[index].y + nodes[index].properties.nodeSize.height / 2 >=
            player.y + player.height
          ) {
            destoryUserTipMessage();
            destoryTipMessage();
            showTipMessage(nodes[index], languageData.tip124);
          } else {
            destoryTipMessage();
            destoryUserTipMessage();
            showUserTipMessage(languageData.tip124);
          }
        } else {
          if (nodes[index].y + 50 >= player.y + player.height) {
            destoryUserTipMessage();
            destoryTipMessage();
            showTipMessage(nodes[index], languageData.tip124);
          } else {
            destoryTipMessage();
            destoryUserTipMessage();
            showUserTipMessage(languageData.tip124);
          }
        }
      }
    }
  });
  if (index_new == null && !isTip) {
    destoryTipMessage();
    destoryUserTipMessage();
    window.clearSelectElements();
  }
}

let Enter = keyboard("Enter");
Enter.press = () => {
  if (index_new != null) {
    isTip = true;
    // player.filters = [];
    // const overlay = createOverlay();
    // overlay_new = overlay;
    // container.addChild(overlay);
    let node = nodes[index_new];
    destoryUserTipMessage();
    destoryTipMessage();
    window.showDialog(node);
    // container.addChild(player)
  }
};

// function setTexture (texture) {
//     // console.log(texture)
//     let sprite = new pixi.AnimatedSprite(texture);
//     return sprite;
// }

// 边界检测
function _moveRangeCheck(sprite, container) {
  let boundary = ""; // 定义精灵抵达的边
  // 精灵抵达左边界
  if (sprite.x < container.x) {
    sprite.x = container.x;
    boundary = "left";
  }
  // 精灵抵达右边界
  if (sprite.x + sprite.width > container.width) {
    sprite.x = container.width - sprite.width;
    boundary = "right";
  }
  // 精灵抵达上边界
  if (sprite.y < container.y) {
    sprite.y = container.y;
    boundary = "top";
  }
  // 精灵抵达下编辑
  if (sprite.y + sprite.height > container.height) {
    sprite.y = container.height - sprite.height;
    boundary = "bottom";
  }
  return boundary;
}

// 碰撞检测
function _hitTestRectangle(moveSprite, otherSprite) {
  // 定义所需变量
  let hit = false; // 是否碰撞
  // 寻找每个精灵的中心点
  moveSprite.centerX = moveSprite.x + moveSprite.width / 2; // 移动精灵水平中心点
  moveSprite.centerY = moveSprite.y + moveSprite.height / 2; // 移动精灵垂直中心点
  // (32,232)
  otherSprite.centerX = otherSprite.x + otherSprite.width / 2; // 矩形水平中心点
  otherSprite.centerY = otherSprite.y + otherSprite.height / 2; // 矩形垂直中心点
  // console.log(`(${otherSprite.rectangle.x},${otherSprite.rectangle.y})`);

  // 找出每个精灵的半高和半宽
  moveSprite.halfWidth = moveSprite.width / 2; // 移动精灵半宽
  moveSprite.halfHeight = moveSprite.height / 2; // 移动精灵半高
  otherSprite.halfWidth = otherSprite.width / 2; // 矩形半宽
  otherSprite.halfHeight = otherSprite.height / 2; // 矩形半高

  // 移动精灵和矩形之间的距离
  const gapX = moveSprite.centerX - otherSprite.centerX;
  const gapY = moveSprite.centerY - otherSprite.centerY;

  // 算出移动精灵和矩形半宽半高的总和
  const combineWidth = moveSprite.halfWidth + otherSprite.halfWidth;
  const combineHeight = moveSprite.halfHeight + otherSprite.halfHeight;
  // 检查x轴上是否有碰撞
  // 判断两个精灵中心点间的距离,是否小于精灵半宽和
  if (Math.abs(gapX) < combineWidth + 40) {
    // 检查y轴是否有碰撞
    hit = Math.abs(gapY) < combineHeight + 40;
  } else {
    hit = false;
  }
  return hit;
}

// 初始化画布
export function initState(backgroundUrl, sprites) {
  loader = PIXI.Loader.shared;
  loader.reset();
  if (backgroundUrl != "") {
    const loader_new = new PIXI.Loader();
    loader_new.add([{ name: "sceen", url: backgroundUrl }]);
    loader_new.load((loader) => {
      const cacheResource = loader.resources["sceen"];
      let texture = cacheResource?.texture;

      //Create a Pixi Application   创建一个pixi应用
      app = new PIXI.Application({
        width: texture.width, // default: 800 宽度
        height: texture.height, // default: 600 高度
        antialias: true, // default: false 反锯齿
        transparent: true, // default: false 透明度
        resolution: 1, // default: 1 分辨率
        backgroundAlpha: 1, // 设置背景颜色透明度   0是透明
      });
      document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
      container = new PIXI.Container();
      app.stage.addChild(container);
      const backgroundSprite = PIXI.Sprite.from(texture);
      backgroundSprite.x = 0;
      backgroundSprite.y = 0;
      // if(texture.width > $(".lf-graph").width() && texture.height > $(".lf-graph").height()) {
      //     app.renderer.resize(texture.width, texture.height)
      //     container.addChild(backgroundSprite)
      //     gameContainer = {
      //         x: 0,
      //         y: 0,
      //         width: texture.width,
      //         height: texture.height,
      //     };
      // }
      // else {
      //     let rate = texture.height / texture.width
      //     backgroundSprite.width = $(".lf-graph").width()
      //     backgroundSprite.height = $(".lf-graph").width() * rate
      //     app.renderer.resize($(".lf-graph").width(), $(".lf-graph").width() * rate)
      //     container.addChild(backgroundSprite)
      //     gameContainer = {
      //         x: 0,
      //         y: 0,
      //         width: $(".lf-graph").width(),
      //         height: $(".lf-graph").width() * rate,
      //     };
      // }

      let max_node_x = find_max_x(sprites);
      let min_node_x = find_min_x(sprites);
      let max_node_y = find_max_y(sprites);
      let min_node_y = find_min_y(sprites);
      if (max_node_x.x != min_node_x.x && max_node_y.y != min_node_y.y) {
        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, texture.width, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x == min_node_x.x && max_node_y.y != min_node_y.y) {
        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        // app.renderer.resize(Math.max(min_node_x.x - 125, texture.width, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x != min_node_x.x && max_node_y.y == min_node_y.y) {
        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, texture.width, $(".lf-graph").width()), Math.max(min_node_y.y - 125, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else {
        // app.renderer.resize(min_node_x.x + 500, min_node_x.y + 500)
        // gameContainer = {
        //     x: min_node_x.x - 250,
        //     y: min_node_x.y - 250,
        //     width: min_node_x.x + 500,
        //     height: min_node_x.y + 500,
        // };

        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        // app.renderer.resize(Math.max(min_node_x.x - 125, texture.width, $(".lf-graph").width()), Math.max(min_node_y.y - 125, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(min_node_x.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      }
    });
  } else {
    app = new PIXI.Application({
      width: $(".lf-graph").width(), // default: 800 宽度
      height: $(".lf-graph").height(), // default: 600 高度
      antialias: true, // default: false 反锯齿
      transparent: true, // default: false 透明度
      resolution: 1, // default: 1 分辨率
      backgroundAlpha: 1, // 设置背景颜色透明度   0是透明
    });
    document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
    container = new PIXI.Container();
    app.stage.addChild(container);
    // gameContainer = {
    //     x: 0,
    //     y: 0,
    //     width: window.innerWidth,
    //     height: window.innerHeight,
    // };

    let max_node_x = find_max_x(sprites);
    let min_node_x = find_min_x(sprites);
    let max_node_y = find_max_y(sprites);
    let min_node_y = find_min_y(sprites);
    if (max_node_x.x != min_node_x.x && max_node_y.y != min_node_y.y) {
      // gameContainer = {
      //     x: min_node_x.x - 250,
      //     y: min_node_x.y - 250,
      //     width: max_node_x.x - min_node_x.x + 500,
      //     height: max_node_y.y - min_node_y.y + 500,
      // };

      // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, $(".lf-graph").height()))
      gameContainer = {
        x: min_node_x.x - 125,
        y: min_node_y.y - 125,
        width: Math.max(
          max_node_x.x - min_node_x.x + 250,
          $(".lf-graph").width()
        ),
        height: Math.max(
          max_node_y.y - min_node_y.y + 250,
          $(".lf-graph").height()
        ),
      };
      width = gameContainer.width;
      height = gameContainer.height;
    } else if (max_node_x.x == min_node_x.x && max_node_y.y != min_node_y.y) {
      // app.renderer.resize(Math.max(min_node_x.x - 125, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, $(".lf-graph").height()))
      gameContainer = {
        x: min_node_x.x - 125,
        y: min_node_y.y - 125,
        // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
        // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
        width: Math.max(min_node_x.x - 125, $(".lf-graph").width()),
        height: Math.max(
          max_node_y.y - min_node_y.y + 250,
          $(".lf-graph").height()
        ),
      };
      width = gameContainer.width;
      height = gameContainer.height;
    } else if (max_node_x.x != min_node_x.x && max_node_y.y == min_node_y.y) {
      // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, $(".lf-graph").width()), Math.max(min_node_y.y - 125, $(".lf-graph").height()))
      gameContainer = {
        x: min_node_x.x - 125,
        y: min_node_y.y - 125,
        // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
        // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
        width: Math.max(
          max_node_x.x - min_node_x.x + 250,
          $(".lf-graph").width()
        ),
        height: Math.max(min_node_y.y - 125, $(".lf-graph").height()),
      };
      width = gameContainer.width;
      height = gameContainer.height;
    } else {
      // gameContainer = {
      //     x: min_node_x.x - 250,
      //     y: min_node_x.y - 250,
      //     width: min_node_x.x + 500,
      //     height: min_node_x.y + 500,
      // };

      // app.renderer.resize(Math.max(min_node_x.x - 125, $(".lf-graph").width()), Math.max(min_node_y.y - 125, $(".lf-graph").height()))
      gameContainer = {
        x: min_node_x.x - 125,
        y: min_node_y.y - 125,
        width: Math.max(min_node_x.x - 125, $(".lf-graph").width()),
        height: Math.max(min_node_y.y - 125, $(".lf-graph").height()),
      };
      width = gameContainer.width;
      height = gameContainer.height;
    }
  }
}

export function initStateNew() {
  loader = PIXI.Loader.shared;
  loader.reset();
  //Create a Pixi Application   创建一个pixi应用
  // let dimensions = {}
  // if(backgroundUrl != '') {
  //     await getImageWidth(backgroundUrl)
  //     .then(Dimensions => {
  //         dimensions = Dimensions
  //         console.log(dimensions)
  //     })
  //     .catch(error => {
  //         console.error('Error loading image:', error);
  //     });
  //     app = new PIXI.Application({
  //         width: dimensions.width,         // default: 800 宽度
  //         height: dimensions.height,        // default: 600 高度
  //         antialias: true,    // default: false 反锯齿
  //         transparent: true, // default: false 透明度
  //         resolution: 1,      // default: 1 分辨率
  //         backgroundAlpha: 1,   // 设置背景颜色透明度   0是透明
  //     });
  //     document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
  //     container = new PIXI.Container();
  //     if(isAdd) {
  //         addSprite(sprites, backgroundUrl)
  //     }
  // }
  // else {
  //     app = new PIXI.Application({
  //         width: $(".lf-graph").width(),         // default: 800 宽度
  //         height: $(".lf-graph").height(),        // default: 600 高度
  //         antialias: true,    // default: false 反锯齿
  //         transparent: true, // default: false 透明度
  //         resolution: 1,      // default: 1 分辨率
  //         backgroundAlpha: 1,   // 设置背景颜色透明度   0是透明
  //     });
  //     document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
  //     container = new PIXI.Container();
  //     if(isAdd) {
  //         addSprite(sprites, backgroundUrl)
  //     }
  // }
}

// 添加精灵 Sprite
export function addSprite(sprites, backgroundUrl) {
  let blobs = [];

  /*******************  加载纹理贴图，创造精灵，并将精灵添加到stage舞台上 *******************/
  if (backgroundUrl != "") {
    let temp = [
      { name: "sceen", url: backgroundUrl },
      { name: "jujin_1", url: jujinPng_1 },
      { name: "jujin_2", url: jujinPng_2 },
      { name: "people_1", url: people_1 },
      { name: "people_2", url: people_2 },
      { name: "people_3", url: people_3 },
      { name: "people_4", url: people_4 },
    ];
    // for (let j = 0; j < sprites.length; j++) {
    //     if(sprites[j].type == "icon-ai-npc") {
    //         temp.push({ name: sprites[j].id, url: sprites[j].properties.url })
    //     }
    // }
    loader.add(temp).load(() => {
      // app.stage.addChild(container)
      const cacheResource = loader.resources["sceen"];
      let texture = cacheResource?.texture;

      //Create a Pixi Application   创建一个pixi应用
      app = new PIXI.Application({
        width: texture.width, // default: 800 宽度
        height: texture.height, // default: 600 高度
        antialias: true, // default: false 反锯齿
        transparent: true, // default: false 透明度
        resolution: 1, // default: 1 分辨率
        backgroundAlpha: 1, // 设置背景颜色透明度   0是透明
      });
      document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
      container = new PIXI.Container();

      const backgroundSprite = PIXI.Sprite.from(texture);
      backgroundSprite.x = 0;
      backgroundSprite.y = 0;
      let max_node_x = find_max_x(sprites);
      let min_node_x = find_min_x(sprites);
      let max_node_y = find_max_y(sprites);
      let min_node_y = find_min_y(sprites);

      if (max_node_x.x != min_node_x.x && max_node_y.y != min_node_y.y) {
        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        app.ticker.stop();
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, texture.width, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x == min_node_x.x && max_node_y.y != min_node_y.y) {
        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        app.ticker.stop();
        // app.renderer.resize(Math.max(min_node_x.x - 125, texture.width, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x != min_node_x.x && max_node_y.y == min_node_y.y) {
        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        app.ticker.stop();
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, texture.width, $(".lf-graph").width()), Math.max(min_node_y.y - 125, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else {
        // app.renderer.resize(min_node_x.x + 500, min_node_x.y + 500)
        // gameContainer = {
        //     x: min_node_x.x - 250,
        //     y: min_node_x.y - 250,
        //     width: min_node_x.x + 500,
        //     height: min_node_x.y + 500,
        // };

        // let rate = texture.height / texture.width
        // backgroundSprite.width = $(".lf-graph").width()
        // backgroundSprite.height = $(".lf-graph").width() * rate
        // if(min_node_x.x >= 0) {
        //     backgroundSprite.x = min_node_x.x;
        // }
        // else {
        //     backgroundSprite.x = Math.abs(min_node_x.x);
        // }
        // if(min_node_y.y >= 0) {
        //     backgroundSprite.y = min_node_y.y;
        // }
        // else {
        //     backgroundSprite.y = Math.abs(min_node_y.y);
        // }
        app.ticker.stop();
        // app.renderer.resize(Math.max(min_node_x.x - 125, texture.width, $(".lf-graph").width()), Math.max(min_node_y.y - 125, texture.height, $(".lf-graph").height()))
        container.addChild(backgroundSprite);
        gameContainer = {
          x: backgroundSprite.x,
          y: backgroundSprite.y,
          // width: Math.max(min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(min_node_x.y + 500, texture.height, $(".lf-graph").height()),
          width: backgroundSprite.width,
          height: backgroundSprite.height,
        };
        width = gameContainer.width;
        height = gameContainer.height;
      }
      // if(texture.width > $(".lf-graph").width() && texture.height > $(".lf-graph").height()) {
      //     app.renderer.resize(texture.width, texture.height)
      //     container.addChild(backgroundSprite)
      // }
      // else {
      //     let rate = texture.height / texture.width
      //     backgroundSprite.width = $(".lf-graph").width()
      //     backgroundSprite.height = $(".lf-graph").width() * rate
      //     app.renderer.resize($(".lf-graph").width(), $(".lf-graph").width() * rate)
      //     container.addChild(backgroundSprite)
      // }
      if (!window.getDevice()) {
        app.ticker.start();
        createSprite(
          sprites,
          blobs,
          nodes,
          backgroundSprite.x,
          backgroundSprite.y
        );
      }
      app.stage.addChild(container);
      app.renderer.render(app.stage);
    });
  } else {
    let temp = [
      { name: "jujin_1", url: jujinPng_1 },
      { name: "jujin_2", url: jujinPng_2 },
      { name: "people_1", url: people_1 },
      { name: "people_2", url: people_2 },
      { name: "people_3", url: people_3 },
      { name: "people_4", url: people_4 },
    ];
    for (let j = 0; j < sprites.length; j++) {
      if (sprites[j].type == "icon-ai-npc") {
        temp.push({ name: sprites[j].id, url: sprites[j].properties.url });
      }
    }
    loader.add(temp).load(() => {
      // app.stage.addChild(container)
      // const cacheResource = loader.resources["sceen"];
      // let texture = cacheResource?.texture;
      // const backgroundSprite = PIXI.Sprite.from(texture)
      // backgroundSprite.x = 0;
      // backgroundSprite.y = 0;
      // app.renderer.resize(texture.width, texture.height)
      // container.addChild(backgroundSprite)

      //Create a Pixi Application   创建一个pixi应用
      app = new PIXI.Application({
        width: $(".lf-graph").width(), // default: 800 宽度
        height: $(".lf-graph").height(), // default: 600 高度
        antialias: true, // default: false 反锯齿
        transparent: true, // default: false 透明度
        resolution: 1, // default: 1 分辨率
        backgroundAlpha: 1, // 设置背景颜色透明度   0是透明
      });
      document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
      container = new PIXI.Container();

      let max_node_x = find_max_x(sprites);
      let min_node_x = find_min_x(sprites);
      let max_node_y = find_max_y(sprites);
      let min_node_y = find_min_y(sprites);
      if (max_node_x.x != min_node_x.x && max_node_y.y != min_node_y.y) {
        // gameContainer = {
        //     x: min_node_x.x - 250,
        //     y: min_node_x.y - 250,
        //     width: max_node_x.x - min_node_x.x + 500,
        //     height: max_node_y.y - min_node_y.y + 500,
        // };
        app.ticker.stop();
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          width: Math.max(
            max_node_x.x - min_node_x.x + 250,
            $(".lf-graph").width()
          ),
          height: Math.max(
            max_node_y.y - min_node_y.y + 250,
            $(".lf-graph").height()
          ),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x == min_node_x.x && max_node_y.y != min_node_y.y) {
        app.ticker.stop();
        // app.renderer.resize(Math.max(min_node_x.x - 125, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: Math.max(min_node_x.x - 125, $(".lf-graph").width()),
          height: Math.max(
            max_node_y.y - min_node_y.y + 250,
            $(".lf-graph").height()
          ),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x != min_node_x.x && max_node_y.y == min_node_y.y) {
        app.ticker.stop();
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, $(".lf-graph").width()), Math.max(min_node_y.y - 125, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: Math.max(
            max_node_x.x - min_node_x.x + 250,
            $(".lf-graph").width()
          ),
          height: Math.max(min_node_y.y - 125, $(".lf-graph").height()),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else {
        // gameContainer = {
        //     x: min_node_x.x - 250,
        //     y: min_node_x.y - 250,
        //     width: min_node_x.x + 500,
        //     height: min_node_x.y + 500,
        // };
        app.ticker.stop();
        // app.renderer.resize(Math.max(min_node_x.x - 125, $(".lf-graph").width()), Math.max(min_node_y.y - 125, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          width: Math.max(min_node_x.x - 125, $(".lf-graph").width()),
          height: Math.max(min_node_y.y - 125, $(".lf-graph").height()),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      }
      if (!window.getDevice()) {
        app.ticker.start();
        createSprite(
          sprites,
          blobs,
          nodes,
          min_node_x.x - 250,
          min_node_x.y - 250
        );
      }
      app.stage.addChild(container);
      app.renderer.render(app.stage);
    });
  }
}

export function addSpriteNew(sprites,sectionList) {
  let blobs = [];

  let backgroundUrlList = []

  for (let j = 0; j < sectionList.length; j++) {
    if(sectionList[j].properties.imageList && sectionList[j].properties.imageList.length != 0) {
      backgroundUrlList.push({ name: sectionList[j].id, url: sectionList[j].properties.imageList[0].url })
    }
  }
  
  /*******************  加载纹理贴图，创造精灵，并将精灵添加到stage舞台上 *******************/
  if (backgroundUrlList.length != 0) {
    let temp = [
      // { name: "sceen", url: backgroundUrl },
      { name: "jujin_1", url: jujinPng_1 },
      { name: "jujin_2", url: jujinPng_2 },
      { name: "people_1", url: people_1 },
      { name: "people_2", url: people_2 },
      { name: "people_3", url: people_3 },
      { name: "people_4", url: people_4 },
    ];
    for (let j = 0; j < backgroundUrlList.length; j++) {
      temp.push({ name: backgroundUrlList[j].name, url: backgroundUrlList[j].url })
    }
    loader.add(temp).load(() => {
      let max_node_section_x = find_max_x(sectionList);
      let min_node_section_x = find_min_x(sectionList);
      let max_node_section_y = find_max_y(sectionList);
      let min_node_section_y = find_min_y(sectionList);

      // console.log(max_node_section_x)
      // console.log(min_node_section_x)
      // console.log(max_node_section_y)
      // console.log(min_node_section_y)

      width = max_node_section_x.x - min_node_section_x.x != 0 ? max_node_section_x.x - min_node_section_x.x : (sectionList[0].properties.width ? sectionList[0].properties.width : 1000);
      height = max_node_section_y.y - min_node_section_y.y != 0 ? max_node_section_y.y - min_node_section_y.y : (sectionList[0].properties.height ? sectionList[0].properties.height : 600);

      // console.log(width)
      // console.log(height)

      //Create a Pixi Application   创建一个pixi应用
      app = new PIXI.Application({
        width: width, // default: 800 宽度
        height: height, // default: 600 高度
        antialias: true, // default: false 反锯齿
        transparent: true, // default: false 透明度
        resolution: 1, // default: 1 分辨率
        backgroundAlpha: 1, // 设置背景颜色透明度   0是透明
      });
      document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
      container = new PIXI.Container();

      app.ticker.stop();
      for (let j = 0; j < sectionList.length; j++) {
        let cacheResource = loader.resources[sectionList[j].id];
        let texture = cacheResource?.texture;
        let backgroundSprite = PIXI.Sprite.from(texture);
        backgroundSprite.width = sectionList[j].properties.width ? sectionList[j].properties.width : 1000
        backgroundSprite.height = sectionList[j].properties.height ? sectionList[j].properties.height : 600
        backgroundSprite.x = sectionList[j].x - backgroundSprite.width / 2;
        backgroundSprite.y = sectionList[j].y - backgroundSprite.height / 2;
        // console.log(backgroundSprite.x)
        // console.log(backgroundSprite.y)
        container.addChild(backgroundSprite);
      }

      gameContainer = {
        x: min_node_section_x.x - min_node_section_x.properties.width / 2 > 0 ? min_node_section_x.x - min_node_section_x.properties.width / 2 : 0,
        y: min_node_section_y.y - min_node_section_y.properties.height / 2 > 0 ? min_node_section_y.y - min_node_section_y.properties.height / 2 : 0,
        width: min_node_section_x.x - min_node_section_x.properties.width / 2 > 0 ? width : width + (min_node_section_x.x - min_node_section_x.properties.width / 2),
        height: min_node_section_y.y - min_node_section_y.properties.height / 2 > 0 ? height : height + (min_node_section_y.y - min_node_section_y.properties.height / 2),
      };

      if (!window.getDevice()) {
        app.ticker.start();
        createSprite(
          sprites,
          blobs,
          nodes,
          min_node_section_x.x - min_node_section_x.properties.width / 2 > 0 ? min_node_section_x.x - min_node_section_x.properties.width / 2 : 0,
          min_node_section_y.y - min_node_section_y.properties.height / 2 > 0 ? min_node_section_y.y - min_node_section_y.properties.height / 2 : 0
        );
      }
      app.stage.addChild(container);
      app.renderer.render(app.stage);
    });
  } else {
    let temp = [
      { name: "jujin_1", url: jujinPng_1 },
      { name: "jujin_2", url: jujinPng_2 },
      { name: "people_1", url: people_1 },
      { name: "people_2", url: people_2 },
      { name: "people_3", url: people_3 },
      { name: "people_4", url: people_4 },
    ];
    loader.add(temp).load(() => {
      //Create a Pixi Application   创建一个pixi应用
      app = new PIXI.Application({
        width: $(".lf-graph").width(), // default: 800 宽度
        height: $(".lf-graph").height(), // default: 600 高度
        antialias: false, // default: false 反锯齿
        transparent: true, // default: false 透明度
        resolution: 1, // default: 1 分辨率
        backgroundAlpha: 1, // 设置背景颜色透明度   0是透明
      });
      document.getElementsByClassName("lf-graph")[0].appendChild(app.view);
      container = new PIXI.Container();

      let max_node_x = find_max_x(sprites);
      let min_node_x = find_min_x(sprites);
      let max_node_y = find_max_y(sprites);
      let min_node_y = find_min_y(sprites);
      if (max_node_x.x != min_node_x.x && max_node_y.y != min_node_y.y) {
        // gameContainer = {
        //     x: min_node_x.x - 250,
        //     y: min_node_x.y - 250,
        //     width: max_node_x.x - min_node_x.x + 500,
        //     height: max_node_y.y - min_node_y.y + 500,
        // };
        app.ticker.stop();
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          width: Math.max(
            max_node_x.x - min_node_x.x + 250,
            $(".lf-graph").width()
          ),
          height: Math.max(
            max_node_y.y - min_node_y.y + 250,
            $(".lf-graph").height()
          ),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x == min_node_x.x && max_node_y.y != min_node_y.y) {
        app.ticker.stop();
        // app.renderer.resize(Math.max(min_node_x.x - 125, $(".lf-graph").width()), Math.max(max_node_y.y - min_node_y.y + 250, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: Math.max(min_node_x.x - 125, $(".lf-graph").width()),
          height: Math.max(
            max_node_y.y - min_node_y.y + 250,
            $(".lf-graph").height()
          ),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else if (max_node_x.x != min_node_x.x && max_node_y.y == min_node_y.y) {
        app.ticker.stop();
        // app.renderer.resize(Math.max(max_node_x.x - min_node_x.x + 250, $(".lf-graph").width()), Math.max(min_node_y.y - 125, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          // width: Math.max(max_node_x.x - min_node_x.x + 500, texture.width, $(".lf-graph").width()),
          // height: Math.max(max_node_y.y - min_node_y.y + 500, texture.height, $(".lf-graph").height()),
          width: Math.max(
            max_node_x.x - min_node_x.x + 250,
            $(".lf-graph").width()
          ),
          height: Math.max(min_node_y.y - 125, $(".lf-graph").height()),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      } else {
        // gameContainer = {
        //     x: min_node_x.x - 250,
        //     y: min_node_x.y - 250,
        //     width: min_node_x.x + 500,
        //     height: min_node_x.y + 500,
        // };
        app.ticker.stop();
        // app.renderer.resize(Math.max(min_node_x.x - 125, $(".lf-graph").width()), Math.max(min_node_y.y - 125, $(".lf-graph").height()))
        gameContainer = {
          x: min_node_x.x - 125,
          y: min_node_y.y - 125,
          width: Math.max(min_node_x.x - 125, $(".lf-graph").width()),
          height: Math.max(min_node_y.y - 125, $(".lf-graph").height()),
        };
        width = gameContainer.width;
        height = gameContainer.height;
      }
      if (!window.getDevice()) {
        app.ticker.start();
        createSprite(
          sprites,
          blobs,
          nodes,
          min_node_x.x - 250,
          min_node_x.y - 250
        );
      }
      app.stage.addChild(container);
      app.renderer.render(app.stage);
    });
  }
}

// 创建人物精灵以及隐藏精灵 Sprite
function createSprite(sprites, blobs, nodes, x, y) {
  // 使用SpriteUtilities
  let animate = new SpriteUtilities(PIXI, app.renderer);
  for (let j = 0; j < sprites.length; j++) {
    if (
      sprites[j].type.indexOf("icon-pm") == -1 &&
      sprites[j].type.indexOf("icon-st-") == -1 &&
      sprites[j].x > x &&
      sprites[j].y > y
    ) {
      nodes.push(sprites[j]);
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(jujinPng_2, 72, 72);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (var i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      if (
        sprites[j].properties.nodeSize &&
        sprites[j].properties.nodeSize.width &&
        sprites[j].properties.nodeSize.height
      ) {
        jujin.x = sprites[j].x - sprites[j].properties.nodeSize.width / 2;
        jujin.y = sprites[j].y - sprites[j].properties.nodeSize.height / 2;
        jujin.width = sprites[j].properties.nodeSize.width;
        jujin.height = sprites[j].properties.nodeSize.height;
        if (sprites[j].type == "icon-ai-npc") {
          jujin.visible = false;
        } else {
          jujin.visible = false;
        }
      } else {
        if (
          sprites[j].type == "icon-theme" ||
          sprites[j].type == "icon-stage" ||
          sprites[j].type == "icon-task"
        ) {
          jujin.x = sprites[j].x - 125;
          jujin.y = sprites[j].y - 37.5;
          jujin.width = 250;
          jujin.height = 75;
          jujin.visible = false;
        } else if (
          sprites[j].type == "icon-whiteboard" ||
          sprites[j].type == "icon-camera" ||
          sprites[j].type == "icon-map"
        ) {
          jujin.x = sprites[j].x - 132.5;
          jujin.y = sprites[j].y - 125;
          jujin.width = 265;
          jujin.height = 250;
          jujin.visible = false;
        } else if (sprites[j].type == "icon-select") {
          jujin.x = sprites[j].x - 125;
          jujin.y = sprites[j].y - 77;
          jujin.width = 250;
          jujin.height = 154;
          jujin.visible = false;
        } else if (sprites[j].type == "icon-ai-npc") {
          jujin.x = sprites[j].x - 50;
          jujin.y = sprites[j].y - 50;
          jujin.width = 100;
          jujin.height = 100;
          jujin.visible = false;
        } else {
          jujin.x = sprites[j].x - 125;
          jujin.y = sprites[j].y - 125;
          jujin.width = 250;
          jujin.height = 250;
          jujin.visible = false;
        }
      }

      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      blobs.push(jujin);
      // keyMove(jujin, sprites[j].properties)
      app.ticker.add(() => gameLoop(jujin, []));
    }
  }
  for (let j = 0; j < sprites.length; j++) {
    if (sprites[j].type == "icon-pm-1") {
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(jujinPng_1, 93, 78);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (let i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      jujin.x =
        sprites[j].x -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100) /
          2;
      jujin.y =
        sprites[j].y -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100) /
          2;
      jujin.width =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100;
      jujin.height =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100;
      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      keyMove(jujin, sprites[j].properties);
      app.ticker.add(() => gameLoop(jujin, blobs));
    } else if (sprites[j].type == "icon-pm-2") {
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(jujinPng_2, 72, 72);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (let i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      jujin.x =
        sprites[j].x -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100) /
          2;
      jujin.y =
        sprites[j].y -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100) /
          2;
      jujin.width =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100;
      jujin.height =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100;
      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      keyMove(jujin, sprites[j].properties);
      app.ticker.add(() => gameLoop(jujin, blobs));
    } else if (sprites[j].type == "icon-st-1") {
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(people_1, 31.5, 47.5);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (let i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      jujin.x =
        sprites[j].x -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100) /
          2;
      jujin.y =
        sprites[j].y -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100) /
          2;
      jujin.width =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100;
      jujin.height =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100;
      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      keyMove(jujin, sprites[j].properties);
      app.ticker.add(() => gameLoop(jujin, blobs));
    } else if (sprites[j].type == "icon-st-2") {
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(people_2, 32, 48);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (let i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      jujin.x =
        sprites[j].x -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100) /
          2;
      jujin.y =
        sprites[j].y -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100) /
          2;
      jujin.width =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100;
      jujin.height =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100;
      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      keyMove(jujin, sprites[j].properties);
      app.ticker.add(() => gameLoop(jujin, blobs));
    } else if (sprites[j].type == "icon-st-3") {
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(people_3, 32, 48);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (let i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      jujin.x =
        sprites[j].x -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100) /
          2;
      jujin.y =
        sprites[j].y -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100) /
          2;
      jujin.width =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100;
      jujin.height =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100;
      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      keyMove(jujin, sprites[j].properties);
      app.ticker.add(() => gameLoop(jujin, blobs));
    } else if (sprites[j].type == "icon-st-4") {
      //创建纹理数组  将雪碧图变成纹理数组  66,48
      let frames = animate.filmstrip(people_4, 32, 48);
      // 创建纹理数组  只使用雪碧图中的一部分
      // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
      for (let i = 0; i < frames.length; i++) {
        frames[i].texture = frames[i].baseTexture;
      }
      let jujin = animate.sprite(frames); //使用SpriteUtilities创建精灵
      // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
      container.addChild(jujin);
      jujin.animationSpeed = 0.08;
      jujin.vx = 0;
      jujin.vy = 0;
      jujin.x =
        sprites[j].x -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100) /
          2;
      jujin.y =
        sprites[j].y -
        (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100) /
          2;
      jujin.width =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width
          ? sprites[j].properties.nodeSize.width
          : 100;
      jujin.height =
        sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height
          ? sprites[j].properties.nodeSize.height
          : 100;
      // jujin.play();

      // 设置一个states对象字面量属性
      jujin.states = {
        down: 0,
        left: 4,
        right: 8,
        up: 12,
        walkDown: [0, 3],
        walkLeft: [4, 7],
        walkRight: [8, 11],
        walkUp: [12, 15],
      };
      keyMove(jujin, sprites[j].properties);
      app.ticker.add(() => gameLoop(jujin, blobs));
    }
    // else if(sprites[j].type == "icon-ai-npc") {
    //     // const cacheResource = loader.resources[sprites[j].id];
    //     // let texture = cacheResource?.texture;
    //     // const backgroundSprite = PIXI.Sprite.from(texture)
    //     // if (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width && sprites[j].properties.nodeSize.height) {
    //     //     backgroundSprite.x = sprites[j].x - sprites[j].properties.nodeSize.width / 2;
    //     //     backgroundSprite.y = sprites[j].y - sprites[j].properties.nodeSize.height / 2;
    //     //     backgroundSprite.width = sprites[j].properties.nodeSize.width
    //     //     backgroundSprite.height = sprites[j].properties.nodeSize.height
    //     // }
    //     // else {
    //     //     backgroundSprite.x = sprites[j].x - 50;
    //     //     backgroundSprite.y = sprites[j].y - 50;
    //     //     backgroundSprite.width = 100
    //     //     backgroundSprite.height = 100
    //     // }
    //     // backgroundSprite.visible = true
    //     // container.addChild(backgroundSprite);
    //     // blobs.push(backgroundSprite);
    //     // app.ticker.add(() => gameLoop(backgroundSprite, gameContainer, blobs));
    //     //创建纹理数组  将雪碧图变成纹理数组  66,48
    //     let frames = animate.filmstrip(jujinPng_2, 72, 72);
    //     // 创建纹理数组  只使用雪碧图中的一部分
    //     // let frames = animate.frames(jujinPng,[[0,0],[48,0],[96,0],[144,0]], 48, 48);
    //     for (let i = 0; i < frames.length; i++) {
    //         frames[i].texture = frames[i].baseTexture;
    //     }
    //     let jujin = animate.sprite(frames);  //使用SpriteUtilities创建精灵
    //     // let jujin = this.setTexture(frames);  //使用之前的方法创建精灵
    //     container.addChild(jujin);
    //     jujin.animationSpeed = 0.08;
    //     jujin.vx = 0;
    //     jujin.vy = 0;
    //     jujin.x = sprites[j].x - (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width ? sprites[j].properties.nodeSize.width : 100) / 2;
    //     jujin.y = sprites[j].y - (sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height ? sprites[j].properties.nodeSize.height : 100) / 2;
    //     jujin.width = sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.width ? sprites[j].properties.nodeSize.width : 100;
    //     jujin.height = sprites[j].properties.nodeSize && sprites[j].properties.nodeSize.height ? sprites[j].properties.nodeSize.height : 100;

    //     jujin.states = {
    //         down: 0,
    //         left: 4,
    //         right: 8,
    //         up: 12,
    //         walkDown: [0, 3],
    //         walkLeft: [4, 7],
    //         walkRight: [8, 11],
    //         walkUp: [12, 15]
    //     };
    //     blobs.push(jujin)
    //     // keyMove(jujin, sprites[j].properties)
    //     app.ticker.add(() => gameLoop(jujin, gameContainer, blobs));
    // }
  }
}

// 销毁画布
export function destoryApp() {
  app.destroy();
  app = null;
  loader = null;
}

// 改变画布宽度和高度
export function changeCanvas(rate) {
  gameContainer = {
    x: gameContainer.x,
    y: gameContainer.y,
    width: width * rate,
    height: height * rate,
  };
  app.renderer.resize(width * rate, height * rate);
}
