<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="sidebar"
    class="diagram-sidebar"
    :style="!isHiddenRightSide ? 'width: 77.5px' : 'width: 77.5px'"
  >
    <div class="leftSide" style="z-index: 51">
      <div
        class="icon_nor"
        style="cursor: pointer; display: none"
        @click="showSearchRightSide"
      >
        <img src="../../assets/icon_search_nor.png" />
        <span class="toolText_nor">{{ languageData.search }}</span>
      </div>

      <div
        :class="!isHiddenRightSide && tab == 3 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showToolsRightSide"
      >
        <svg
          v-if="!isHiddenRightSide && tab == 3"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.75 4.375C3.75 4.02982 4.02982 3.75 4.375 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375V13.125C18.75 13.4702 18.4702 13.75 18.125 13.75H16.25V15.625C16.25 15.9702 15.9702 16.25 15.625 16.25H1.875C1.52982 16.25 1.25 15.9702 1.25 15.625V6.875C1.25 6.52982 1.52982 6.25 1.875 6.25H3.75V4.375ZM5 7.5H3.75H2.5V9.375H15V7.5H5ZM5 6.25V5H17.5V12.5H16.25V6.875C16.25 6.52982 15.9702 6.25 15.625 6.25H5ZM2.5 13.75H6.25C6.59518 13.75 6.875 13.4702 6.875 13.125C6.875 12.7798 6.59518 12.5 6.25 12.5H2.5V10.625H15V15H2.5V13.75Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.75 4.375C3.75 4.02982 4.02982 3.75 4.375 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375V13.125C18.75 13.4702 18.4702 13.75 18.125 13.75H16.25V15.625C16.25 15.9702 15.9702 16.25 15.625 16.25H1.875C1.52982 16.25 1.25 15.9702 1.25 15.625V6.875C1.25 6.52982 1.52982 6.25 1.875 6.25H3.75V4.375ZM5 7.5H3.75H2.5V9.375H15V7.5H5ZM5 6.25V5H17.5V12.5H16.25V6.875C16.25 6.52982 15.9702 6.25 15.625 6.25H5ZM2.5 13.75H6.25C6.59518 13.75 6.875 13.4702 6.875 13.125C6.875 12.7798 6.59518 12.5 6.25 12.5H2.5V10.625H15V15H2.5V13.75Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span :class="!isHiddenRightSide && tab == 3 ? 'toolText_pre' : 'toolText_nor'">{{
          languageData.toolLibrary
        }}</span>
      </div>

      <div
        :class="!isHiddenRightSide && tab == 4 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer; display: none"
        @click="showLibraryRightSide"
      >
        <img
          v-if="!isHiddenRightSide && tab == 4"
          src="../../assets/icon_material library_pre.png"
        />
        <img v-else src="../../assets/icon_material library_nor.png" />
        <span
          :class="!isHiddenRightSide && tab == 4 ? 'toolText_pre' : 'toolText_nor'"
          style="text-align: center"
          >{{ languageData.materialLibrary }}</span
        >
      </div>

      <div
        :class="!isHiddenRightSide && tab == 9 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showScaffoldRightSide"
      >
        <!-- <img
          src="../../assets/icon_scaffold_pre.png"
          v-if="!isHiddenRightSide && tab == 9"
          style="width: 20px; height: 20px"
        />
        <img
          src="../../assets/icon_scaffold_nor.png"
          v-else
          style="width: 20px; height: 20px"
        /> -->

        <svg
          v-if="!isHiddenRightSide && tab == 9"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.74137 2.55602C9.9057 2.48133 10.0943 2.48133 10.2586 2.55602L17.1336 5.68102C17.3567 5.78244 17.5 6.00491 17.5 6.25V13.75C17.5 13.9951 17.3567 14.2176 17.1336 14.319L10.2586 17.444C10.0943 17.5187 9.9057 17.5187 9.74137 17.444L2.86637 14.319C2.64325 14.2176 2.5 13.9951 2.5 13.75V6.25C2.5 6.00491 2.64325 5.78244 2.86637 5.68102L9.74137 2.55602ZM8.01378 4.71437L13.4249 7.41994L15.4765 6.30087L10 3.81154L8.01378 4.71437ZM16.25 7.30284L14.0625 8.49602V10L13.4249 9.6789L12.8125 10.625V9.17784L10.625 10.371V15.9044L16.25 13.3476V7.30284ZM9.375 15.9044V10.371L3.75 7.30284V13.3476L9.375 15.9044ZM4.52347 6.30087L10 9.28807L12.0881 8.14909L6.54968 5.37986L4.52347 6.30087Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.74137 2.55602C9.9057 2.48133 10.0943 2.48133 10.2586 2.55602L17.1336 5.68102C17.3567 5.78244 17.5 6.00491 17.5 6.25V13.75C17.5 13.9951 17.3567 14.2176 17.1336 14.319L10.2586 17.444C10.0943 17.5187 9.9057 17.5187 9.74137 17.444L2.86637 14.319C2.64325 14.2176 2.5 13.9951 2.5 13.75V6.25C2.5 6.00491 2.64325 5.78244 2.86637 5.68102L9.74137 2.55602ZM8.01378 4.71437L13.4249 7.41994L15.4765 6.30087L10 3.81154L8.01378 4.71437ZM16.25 7.30284L14.0625 8.49602V10L13.4249 9.6789L12.8125 10.625V9.17784L10.625 10.371V15.9044L16.25 13.3476V7.30284ZM9.375 15.9044V10.371L3.75 7.30284V13.3476L9.375 15.9044ZM4.52347 6.30087L10 9.28807L12.0881 8.14909L6.54968 5.37986L4.52347 6.30087Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span
          :class="!isHiddenRightSide && tab == 9 ? 'toolText_pre' : 'toolText_nor'"
          style="text-align: center"
          >{{ languageData.scaffold }}</span
        >
      </div>

      <div
        :class="!isHiddenRightSide && tab == 2 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer;display: none;"
        @click="showCollectionRightSide"
      >
        <svg
          v-if="!isHiddenRightSide && tab == 2"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 1.875C10.2367 1.875 10.4532 2.0141 10.559 2.23431L12.9094 7.12316L17.5775 7.73001C17.8144 7.76081 18.0137 7.92923 18.0912 8.16405C18.1687 8.39887 18.1108 8.65907 17.942 8.83461L14.4422 12.4744L15.6064 17.3173C15.6627 17.5517 15.5894 17.7992 15.4161 17.96C15.2428 18.1208 14.998 18.1684 14.7806 18.0836L10 16.2192L5.21947 18.0836C5.00199 18.1684 4.75723 18.1208 4.58392 17.96C4.41061 17.7992 4.33735 17.5517 4.39368 17.3173L5.55785 12.4744L2.05808 8.83461C1.88928 8.65907 1.83138 8.39887 1.90885 8.16405C1.98632 7.92923 2.18563 7.76081 2.4225 7.73001L7.09059 7.12316L9.441 2.23431C9.54687 2.0141 9.76328 1.875 10 1.875ZM10 3.97844L8.05903 8.01568C7.96558 8.21007 7.7849 8.34302 7.57754 8.36998L3.84557 8.85513L6.69196 11.8154C6.8472 11.9768 6.9096 12.2111 6.85636 12.4326L5.89504 16.4317L9.78056 14.9164C9.92206 14.8612 10.078 14.8612 10.2195 14.9164L14.105 16.4317L13.1437 12.4326C13.0904 12.2111 13.1528 11.9768 13.3081 11.8154L16.1545 8.85513L12.4225 8.36998C12.2151 8.34302 12.0345 8.21007 11.941 8.01568L10 3.97844Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 1.875C10.2367 1.875 10.4532 2.0141 10.559 2.23431L12.9094 7.12316L17.5775 7.73001C17.8144 7.76081 18.0137 7.92923 18.0912 8.16405C18.1687 8.39887 18.1108 8.65907 17.942 8.83461L14.4422 12.4744L15.6064 17.3173C15.6627 17.5517 15.5894 17.7992 15.4161 17.96C15.2428 18.1208 14.998 18.1684 14.7806 18.0836L10 16.2192L5.21947 18.0836C5.00199 18.1684 4.75723 18.1208 4.58392 17.96C4.41061 17.7992 4.33735 17.5517 4.39368 17.3173L5.55785 12.4744L2.05808 8.83461C1.88928 8.65907 1.83138 8.39887 1.90885 8.16405C1.98632 7.92923 2.18563 7.76081 2.4225 7.73001L7.09059 7.12316L9.441 2.23431C9.54687 2.0141 9.76328 1.875 10 1.875ZM10 3.97844L8.05903 8.01568C7.96558 8.21007 7.7849 8.34302 7.57754 8.36998L3.84557 8.85513L6.69196 11.8154C6.8472 11.9768 6.9096 12.2111 6.85636 12.4326L5.89504 16.4317L9.78056 14.9164C9.92206 14.8612 10.078 14.8612 10.2195 14.9164L14.105 16.4317L13.1437 12.4326C13.0904 12.2111 13.1528 11.9768 13.3081 11.8154L16.1545 8.85513L12.4225 8.36998C12.2151 8.34302 12.0345 8.21007 11.941 8.01568L10 3.97844Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span
          :class="!isHiddenRightSide && tab == 2 ? 'toolText_pre' : 'toolText_nor'"
          style="text-align: center"
          >{{ languageData.collect }}</span
        >
      </div>

      <div
        :class="!isHiddenRightSide && tab == 12 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showNPCRightSide"
      >
        <!-- <img
          src="../../assets/icon_collection_pre.png"
          v-if="!isHiddenRightSide && tab == 2"
          style="width: 20px; height: 20px"
        />
        <img
          src="../../assets/icon_collection_nor.png"
          v-else
          style="width: 20px; height: 20px"
        /> -->

        <svg
          v-if="!isHiddenRightSide && tab == 12"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.5 5.5H5C5.16667 5.83333 5.6 6.5 6 6.5H7.5C7.33333 6.16667 6.9 5.5 6.5 5.5Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            d="M9.5 5.5H11C10.8333 5.83333 10.4 6.5 10 6.5H8.5C8.66667 6.16667 9.1 5.5 9.5 5.5Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 9.5H7V8.5H9V9.5Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.23713 2.07468C3.38454 1.98357 3.56861 1.97529 3.72361 2.05279L5.52278 2.95237L7.8143 2.03576C7.93351 1.98808 8.06649 1.98808 8.1857 2.03576L10.4772 2.95237L12.2764 2.05279C12.4314 1.97529 12.6155 1.98357 12.7629 2.07468C12.9103 2.16578 13 2.32671 13 2.5V6H14V7H13V11.5C13 11.7761 12.7761 12 12.5 12H3.5C3.22386 12 3 11.7761 3 11.5V7H2V6H3V2.5C3 2.32671 3.08973 2.16578 3.23713 2.07468ZM8 3.03852L5.6857 3.96424C5.55309 4.01728 5.40413 4.01108 5.27639 3.94721L4 3.30902V11H12V3.30902L10.7236 3.94721C10.5959 4.01108 10.4469 4.01728 10.3143 3.96424L8 3.03852Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 13H6.5V14H3V13ZM9.5 13H13V14H9.5V13Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.5 5.5H5C5.16667 5.83333 5.6 6.5 6 6.5H7.5C7.33333 6.16667 6.9 5.5 6.5 5.5Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            d="M9.5 5.5H11C10.8333 5.83333 10.4 6.5 10 6.5H8.5C8.66667 6.16667 9.1 5.5 9.5 5.5Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 9.5H7V8.5H9V9.5Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.23713 2.07468C3.38454 1.98357 3.56861 1.97529 3.72361 2.05279L5.52278 2.95237L7.8143 2.03576C7.93351 1.98808 8.06649 1.98808 8.1857 2.03576L10.4772 2.95237L12.2764 2.05279C12.4314 1.97529 12.6155 1.98357 12.7629 2.07468C12.9103 2.16578 13 2.32671 13 2.5V6H14V7H13V11.5C13 11.7761 12.7761 12 12.5 12H3.5C3.22386 12 3 11.7761 3 11.5V7H2V6H3V2.5C3 2.32671 3.08973 2.16578 3.23713 2.07468ZM8 3.03852L5.6857 3.96424C5.55309 4.01728 5.40413 4.01108 5.27639 3.94721L4 3.30902V11H12V3.30902L10.7236 3.94721C10.5959 4.01108 10.4469 4.01728 10.3143 3.96424L8 3.03852Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 13H6.5V14H3V13ZM9.5 13H13V14H9.5V13Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span
          :class="!isHiddenRightSide && tab == 12 ? 'toolText_pre' : 'toolText_nor'"
          style="text-align: center"
          >NPC</span
        >
      </div>

      <div
        :class="!isHiddenRightSide && tab == 13 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showAINPCRightSide"
      >
        <!-- <img
          src="../../assets/icon_collection_pre.png"
          v-if="!isHiddenRightSide && tab == 2"
          style="width: 20px; height: 20px"
        />
        <img
          src="../../assets/icon_collection_nor.png"
          v-else
          style="width: 20px; height: 20px"
        /> -->

        <svg
          v-if="!isHiddenRightSide && tab == 13"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.5 5.5H5C5.16667 5.83333 5.6 6.5 6 6.5H7.5C7.33333 6.16667 6.9 5.5 6.5 5.5Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            d="M9.5 5.5H11C10.8333 5.83333 10.4 6.5 10 6.5H8.5C8.66667 6.16667 9.1 5.5 9.5 5.5Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 9.5H7V8.5H9V9.5Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.23713 2.07468C3.38454 1.98357 3.56861 1.97529 3.72361 2.05279L5.52278 2.95237L7.8143 2.03576C7.93351 1.98808 8.06649 1.98808 8.1857 2.03576L10.4772 2.95237L12.2764 2.05279C12.4314 1.97529 12.6155 1.98357 12.7629 2.07468C12.9103 2.16578 13 2.32671 13 2.5V6H14V7H13V11.5C13 11.7761 12.7761 12 12.5 12H3.5C3.22386 12 3 11.7761 3 11.5V7H2V6H3V2.5C3 2.32671 3.08973 2.16578 3.23713 2.07468ZM8 3.03852L5.6857 3.96424C5.55309 4.01728 5.40413 4.01108 5.27639 3.94721L4 3.30902V11H12V3.30902L10.7236 3.94721C10.5959 4.01108 10.4469 4.01728 10.3143 3.96424L8 3.03852Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 13H6.5V14H3V13ZM9.5 13H13V14H9.5V13Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M6.5 5.5H5C5.16667 5.83333 5.6 6.5 6 6.5H7.5C7.33333 6.16667 6.9 5.5 6.5 5.5Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            d="M9.5 5.5H11C10.8333 5.83333 10.4 6.5 10 6.5H8.5C8.66667 6.16667 9.1 5.5 9.5 5.5Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 9.5H7V8.5H9V9.5Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.23713 2.07468C3.38454 1.98357 3.56861 1.97529 3.72361 2.05279L5.52278 2.95237L7.8143 2.03576C7.93351 1.98808 8.06649 1.98808 8.1857 2.03576L10.4772 2.95237L12.2764 2.05279C12.4314 1.97529 12.6155 1.98357 12.7629 2.07468C12.9103 2.16578 13 2.32671 13 2.5V6H14V7H13V11.5C13 11.7761 12.7761 12 12.5 12H3.5C3.22386 12 3 11.7761 3 11.5V7H2V6H3V2.5C3 2.32671 3.08973 2.16578 3.23713 2.07468ZM8 3.03852L5.6857 3.96424C5.55309 4.01728 5.40413 4.01108 5.27639 3.94721L4 3.30902V11H12V3.30902L10.7236 3.94721C10.5959 4.01108 10.4469 4.01728 10.3143 3.96424L8 3.03852Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 13H6.5V14H3V13ZM9.5 13H13V14H9.5V13Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span
          :class="!isHiddenRightSide && tab == 13 ? 'toolText_pre' : 'toolText_nor'"
          style="text-align: center"
          >AI NPC</span
        >
      </div>

      <div
        :class="tab == 6 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @mousedown="dragInNode('my-group')"
      >
        <!-- <img v-if="tab == 6" src="../../assets/icon_frame_pre.png" draggable="false" />
        <img v-else src="../../assets/icon_frame_nor.png" draggable="false" /> -->

        <svg v-if="tab == 6" t="1722584834916" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1484" width="20" height="20">
          <path d="M788.22 112.35H235.77C159.25 112.35 97 174.6 97 251.12v378.15c0 76.51 62.24 138.77 138.76 138.77h552.46c76.52 0 138.77-62.25 138.77-138.77V251.12c0.01-76.52-62.25-138.77-138.77-138.77z m68.32 516.92c0 37.67-30.65 68.31-68.32 68.31H235.77c-37.67 0-68.31-30.65-68.31-68.31V251.12c0-37.67 30.64-68.31 68.31-68.31h552.46c37.67 0 68.32 30.65 68.32 68.31v378.15zM840.17 841.19H183.83c-19.43 0-35.23 15.8-35.23 35.23 0 19.43 15.8 35.23 35.23 35.23h656.34c19.43 0 35.23-15.8 35.23-35.23-0.01-19.42-15.81-35.23-35.23-35.23z" p-id="1485" fill="white"
          fill-opacity="0.9"></path>
          <path d="M692.85 422.58h-48.94c-41.57 0-75.38 33.82-75.38 75.38v19.59h-81.97c-42.98 0-80.13-25.05-97.9-61.25 37.53-4.28 66.8-36.19 66.8-74.85V332.5c0-41.56-33.82-75.38-75.37-75.38h-48.95c-41.56 0-75.37 33.82-75.37 75.38v48.95c0 39.78 31 72.38 70.1 75.11 20.74 69.15 84.89 119.71 160.7 119.71h87.92c11.47 27.02 38.27 46.02 69.43 46.02h48.94c41.57 0 75.38-33.82 75.38-75.38v-48.94c0-41.57-33.82-75.39-75.39-75.39z m-378.37-90.09c0-9.19 7.48-16.67 16.66-16.67h48.95c9.19 0 16.66 7.48 16.66 16.67v48.95c0 9.19-7.48 16.67-16.66 16.67h-48.95c-9.19 0-16.66-7.48-16.66-16.67v-48.95zM709.52 546.9c0 9.19-7.48 16.67-16.67 16.67h-48.94c-9.2 0-16.67-7.48-16.67-16.67v-48.94c0-9.19 7.48-16.67 16.67-16.67h48.94c9.2 0 16.67 7.48 16.67 16.67v48.94z" p-id="1486" fill="white"
          fill-opacity="0.9"></path>
        </svg>

        <svg v-else t="1722584834916" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1484" width="20" height="20">
          <path d="M788.22 112.35H235.77C159.25 112.35 97 174.6 97 251.12v378.15c0 76.51 62.24 138.77 138.76 138.77h552.46c76.52 0 138.77-62.25 138.77-138.77V251.12c0.01-76.52-62.25-138.77-138.77-138.77z m68.32 516.92c0 37.67-30.65 68.31-68.32 68.31H235.77c-37.67 0-68.31-30.65-68.31-68.31V251.12c0-37.67 30.64-68.31 68.31-68.31h552.46c37.67 0 68.32 30.65 68.32 68.31v378.15zM840.17 841.19H183.83c-19.43 0-35.23 15.8-35.23 35.23 0 19.43 15.8 35.23 35.23 35.23h656.34c19.43 0 35.23-15.8 35.23-35.23-0.01-19.42-15.81-35.23-35.23-35.23z" p-id="1485" fill="black"
          fill-opacity="0.4"></path>
          <path d="M692.85 422.58h-48.94c-41.57 0-75.38 33.82-75.38 75.38v19.59h-81.97c-42.98 0-80.13-25.05-97.9-61.25 37.53-4.28 66.8-36.19 66.8-74.85V332.5c0-41.56-33.82-75.38-75.37-75.38h-48.95c-41.56 0-75.37 33.82-75.37 75.38v48.95c0 39.78 31 72.38 70.1 75.11 20.74 69.15 84.89 119.71 160.7 119.71h87.92c11.47 27.02 38.27 46.02 69.43 46.02h48.94c41.57 0 75.38-33.82 75.38-75.38v-48.94c0-41.57-33.82-75.39-75.39-75.39z m-378.37-90.09c0-9.19 7.48-16.67 16.66-16.67h48.95c9.19 0 16.66 7.48 16.66 16.67v48.95c0 9.19-7.48 16.67-16.66 16.67h-48.95c-9.19 0-16.66-7.48-16.66-16.67v-48.95zM709.52 546.9c0 9.19-7.48 16.67-16.67 16.67h-48.94c-9.2 0-16.67-7.48-16.67-16.67v-48.94c0-9.19 7.48-16.67 16.67-16.67h48.94c9.2 0 16.67 7.48 16.67 16.67v48.94z" p-id="1486" fill="black"
          fill-opacity="0.4"></path>
        </svg>

        <span :class="tab == 6 ? 'toolText_pre' : 'toolText_nor'">{{
          languageData.section
        }}</span>
      </div>

      <div
        :class="tab == 5 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showBackground"
      >
        <!-- <img
          src="../../assets/icon_background_pre.png"
          v-if="tab == 5"
          style="width: 21px; height: 18px"
        />
        <img
          src="../../assets/icon_background_nor.png"
          v-else
          style="width: 21px; height: 18px"
        /> -->

        <svg
          v-if="tab == 5"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.25 4.375C1.25 4.02982 1.52982 3.75 1.875 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375V15.625C18.75 15.9702 18.4702 16.25 18.125 16.25H1.875C1.52982 16.25 1.25 15.9702 1.25 15.625V4.375ZM2.5 5V11.1411L5.84326 8.27546C6.04751 8.10039 6.34088 8.07566 6.57156 8.21407L12.4612 11.7479L14.625 10.125C14.7673 10.0183 14.9482 9.97726 15.1226 10.0121L17.5 10.4876V5H2.5ZM17.5 11.7624L15.1514 11.2927L13.646 12.4217L17.5 14.5628V11.7624ZM15.713 15L12.1965 13.0463L12.1784 13.0359L6.31472 9.5177L2.5 12.7875V15H15.713Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.25 4.375C1.25 4.02982 1.52982 3.75 1.875 3.75H18.125C18.4702 3.75 18.75 4.02982 18.75 4.375V15.625C18.75 15.9702 18.4702 16.25 18.125 16.25H1.875C1.52982 16.25 1.25 15.9702 1.25 15.625V4.375ZM2.5 5V11.1411L5.84326 8.27546C6.04751 8.10039 6.34088 8.07566 6.57156 8.21407L12.4612 11.7479L14.625 10.125C14.7673 10.0183 14.9482 9.97726 15.1226 10.0121L17.5 10.4876V5H2.5ZM17.5 11.7624L15.1514 11.2927L13.646 12.4217L17.5 14.5628V11.7624ZM15.713 15L12.1965 13.0463L12.1784 13.0359L6.31472 9.5177L2.5 12.7875V15H15.713Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span :class="tab == 5 ? 'toolText_pre' : 'toolText_nor'">{{
          languageData.background
        }}</span>
      </div>

      <div
        :class="tab == 7 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer; display: none"
        @click="showAnalysisSide"
      >
        <img
          v-if="tab == 7"
          src="../../assets/icon_analysis_pre.png"
          style="width: 21px; height: 18px"
        />
        <img
          v-else
          src="../../assets/icon_analysis_nor.png"
          style="width: 21px; height: 18px"
        />
        <span :class="tab == 7 ? 'toolText_pre' : 'toolText_nor'">{{
          languageData.analysis
        }}</span>
      </div>

      <div
        :class="tab == 8 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer; display: flex"
        @click="showNewAnalysisSide"
      >
        <!-- <img
          src="../../assets/icon_analysis_pre.png"
          v-if="tab == 8"
          style="width: 21px; height: 18px"
        />
        <img
          src="../../assets/icon_analysis_nor.png"
          v-else
          style="width: 21px; height: 18px"
        /> -->

        <svg
          v-if="tab == 8"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 3.125C2.5 2.77982 2.77982 2.5 3.125 2.5H16.875C17.2202 2.5 17.5 2.77982 17.5 3.125V14.375C17.5 14.7202 17.2202 15 16.875 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V3.125ZM3.75 3.75V13.75H16.25V3.75H3.75Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 16.875C5.625 16.5298 5.90482 16.25 6.25 16.25H13.75C14.0952 16.25 14.375 16.5298 14.375 16.875C14.375 17.2202 14.0952 17.5 13.75 17.5H6.25C5.90482 17.5 5.625 17.2202 5.625 16.875Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4044 7.02344C15.6676 7.24677 15.6999 7.64117 15.4766 7.90437L12.2948 11.6544C12.1807 11.7888 12.0151 11.8688 11.8389 11.8747C11.6627 11.8805 11.4922 11.8117 11.3695 11.6851L8.17722 8.39307L5.44418 11.154C5.20134 11.3993 4.80562 11.4013 4.56031 11.1585C4.31499 10.9156 4.31299 10.5199 4.55582 10.2746L7.73764 7.06032C7.85581 6.94094 8.01706 6.87416 8.18504 6.87502C8.35301 6.87589 8.51357 6.94433 8.63051 7.06492L11.7873 10.3204L14.5234 7.09565C14.7468 6.83245 15.1412 6.80012 15.4044 7.02344Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 3.125C2.5 2.77982 2.77982 2.5 3.125 2.5H16.875C17.2202 2.5 17.5 2.77982 17.5 3.125V14.375C17.5 14.7202 17.2202 15 16.875 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V3.125ZM3.75 3.75V13.75H16.25V3.75H3.75Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.625 16.875C5.625 16.5298 5.90482 16.25 6.25 16.25H13.75C14.0952 16.25 14.375 16.5298 14.375 16.875C14.375 17.2202 14.0952 17.5 13.75 17.5H6.25C5.90482 17.5 5.625 17.2202 5.625 16.875Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.4044 7.02344C15.6676 7.24677 15.6999 7.64117 15.4766 7.90437L12.2948 11.6544C12.1807 11.7888 12.0151 11.8688 11.8389 11.8747C11.6627 11.8805 11.4922 11.8117 11.3695 11.6851L8.17722 8.39307L5.44418 11.154C5.20134 11.3993 4.80562 11.4013 4.56031 11.1585C4.31499 10.9156 4.31299 10.5199 4.55582 10.2746L7.73764 7.06032C7.85581 6.94094 8.01706 6.87416 8.18504 6.87502C8.35301 6.87589 8.51357 6.94433 8.63051 7.06492L11.7873 10.3204L14.5234 7.09565C14.7468 6.83245 15.1412 6.80012 15.4044 7.02344Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span :class="tab == 8 ? 'toolText_pre' : 'toolText_nor'">{{
          languageData.analysis
        }}</span>
      </div>
      <div
        :class="!isHiddenRightSide && tab == 10 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showOperationRightSide"
      >
        <svg
          v-if="!isHiddenRightSide && tab == 10"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.89486 2.7249C9.11272 2.46347 9.4997 2.42439 9.76543 2.63698L12.8904 5.13698C13.0216 5.24188 13.105 5.39514 13.1218 5.56221C13.1387 5.72929 13.0876 5.89613 12.9801 6.02513L6.73014 13.5251C6.63875 13.6348 6.51256 13.7099 6.37257 13.7379L3.24757 14.3629C3.06396 14.3996 2.87356 14.3521 2.72876 14.2334C2.58395 14.1146 2.5 13.9373 2.5 13.75V10.625C2.5 10.4788 2.55126 10.3372 2.64486 10.2249L8.89486 2.7249ZM9.46295 3.99576L3.75 10.8513V12.9876L5.9111 12.5554L11.6117 5.71474L9.46295 3.99576Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 16.875C2.5 16.5298 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 16.5298 17.5 16.875C17.5 17.2202 17.2202 17.5 16.875 17.5H3.125C2.77982 17.5 2.5 17.2202 2.5 16.875Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.75 9.375C12.3693 9.375 11.25 10.4943 11.25 11.875C11.25 13.2557 12.3693 14.375 13.75 14.375C15.1307 14.375 16.25 13.2557 16.25 11.875C16.25 10.4943 15.1307 9.375 13.75 9.375ZM10 11.875C10 9.80393 11.6789 8.125 13.75 8.125C15.8211 8.125 17.5 9.80393 17.5 11.875C17.5 13.9461 15.8211 15.625 13.75 15.625C11.6789 15.625 10 13.9461 10 11.875Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.75 10C14.0952 10 14.375 10.2798 14.375 10.625V11.6161L14.8169 12.0581C15.061 12.3021 15.061 12.6979 14.8169 12.9419C14.5729 13.186 14.1771 13.186 13.9331 12.9419L13.3081 12.3169C13.1908 12.1997 13.125 12.0408 13.125 11.875V10.625C13.125 10.2798 13.4048 10 13.75 10Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.89486 2.7249C9.11272 2.46347 9.4997 2.42439 9.76543 2.63698L12.8904 5.13698C13.0216 5.24188 13.105 5.39514 13.1218 5.56221C13.1387 5.72929 13.0876 5.89613 12.9801 6.02513L6.73014 13.5251C6.63875 13.6348 6.51256 13.7099 6.37257 13.7379L3.24757 14.3629C3.06396 14.3996 2.87356 14.3521 2.72876 14.2334C2.58395 14.1146 2.5 13.9373 2.5 13.75V10.625C2.5 10.4788 2.55126 10.3372 2.64486 10.2249L8.89486 2.7249ZM9.46295 3.99576L3.75 10.8513V12.9876L5.9111 12.5554L11.6117 5.71474L9.46295 3.99576Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 16.875C2.5 16.5298 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 16.5298 17.5 16.875C17.5 17.2202 17.2202 17.5 16.875 17.5H3.125C2.77982 17.5 2.5 17.2202 2.5 16.875Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.75 9.375C12.3693 9.375 11.25 10.4943 11.25 11.875C11.25 13.2557 12.3693 14.375 13.75 14.375C15.1307 14.375 16.25 13.2557 16.25 11.875C16.25 10.4943 15.1307 9.375 13.75 9.375ZM10 11.875C10 9.80393 11.6789 8.125 13.75 8.125C15.8211 8.125 17.5 9.80393 17.5 11.875C17.5 13.9461 15.8211 15.625 13.75 15.625C11.6789 15.625 10 13.9461 10 11.875Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.75 10C14.0952 10 14.375 10.2798 14.375 10.625V11.6161L14.8169 12.0581C15.061 12.3021 15.061 12.6979 14.8169 12.9419C14.5729 13.186 14.1771 13.186 13.9331 12.9419L13.3081 12.3169C13.1908 12.1997 13.125 12.0408 13.125 11.875V10.625C13.125 10.2798 13.4048 10 13.75 10Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>
        <!-- <img
          src="../../assets/icon_scaffold_pre.png"
          v-if="!isHiddenRightSide && tab == 10"
          style="width: 20px; height: 20px"
        />
        <img
          src="../../assets/icon_scaffold_nor.png"
          v-else
          style="width: 20px; height: 20px"
        /> -->
        <span
          :class="!isHiddenRightSide && tab == 10 ? 'toolText_pre' : 'toolText_nor'"
          style="text-align: center"
          >{{ languageData.operation_record }}</span
        >
      </div>

      <div
        :class="!isHiddenRightSide && tab == 11 ? 'icon_pre' : 'icon_nor'"
        style="cursor: pointer"
        @click="showJobReceptionRightSide"
      >
        <svg
          v-if="!isHiddenRightSide && tab == 11"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.93306 8.93306C9.17714 8.68898 9.57286 8.68898 9.81694 8.93306L12.3169 11.4331C12.561 11.6771 12.561 12.0729 12.3169 12.3169L9.81694 14.8169C9.57286 15.061 9.17714 15.061 8.93306 14.8169C8.68898 14.5729 8.68898 14.1771 8.93306 13.9331L10.3661 12.5H3.125C2.77982 12.5 2.5 12.2202 2.5 11.875C2.5 11.5298 2.77982 11.25 3.125 11.25H10.3661L8.93306 9.81694C8.68898 9.57286 8.68898 9.17714 8.93306 8.93306Z"
            fill="white"
            fill-opacity="0.9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.25 1.25C5.90482 1.25 5.625 1.52982 5.625 1.875V2.5H3.125C2.77982 2.5 2.5 2.77982 2.5 3.125V8.75H3.75V3.75H5.625V5C5.625 5.34518 5.90482 5.625 6.25 5.625H13.75C14.0952 5.625 14.375 5.34518 14.375 5V3.75H16.25V17.5H3.75V15H2.5V18.125C2.5 18.4702 2.77982 18.75 3.125 18.75H16.875C17.2202 18.75 17.5 18.4702 17.5 18.125V3.125C17.5 2.77982 17.2202 2.5 16.875 2.5H14.375V1.875C14.375 1.52982 14.0952 1.25 13.75 1.25H6.25ZM6.875 2.5H13.125V4.375H6.875V2.5Z"
            fill="white"
            fill-opacity="0.9"
          />
        </svg>

        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.93306 8.93306C9.17714 8.68898 9.57286 8.68898 9.81694 8.93306L12.3169 11.4331C12.561 11.6771 12.561 12.0729 12.3169 12.3169L9.81694 14.8169C9.57286 15.061 9.17714 15.061 8.93306 14.8169C8.68898 14.5729 8.68898 14.1771 8.93306 13.9331L10.3661 12.5H3.125C2.77982 12.5 2.5 12.2202 2.5 11.875C2.5 11.5298 2.77982 11.25 3.125 11.25H10.3661L8.93306 9.81694C8.68898 9.57286 8.68898 9.17714 8.93306 8.93306Z"
            fill="black"
            fill-opacity="0.4"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.25 1.25C5.90482 1.25 5.625 1.52982 5.625 1.875V2.5H3.125C2.77982 2.5 2.5 2.77982 2.5 3.125V8.75H3.75V3.75H5.625V5C5.625 5.34518 5.90482 5.625 6.25 5.625H13.75C14.0952 5.625 14.375 5.34518 14.375 5V3.75H16.25V17.5H3.75V15H2.5V18.125C2.5 18.4702 2.77982 18.75 3.125 18.75H16.875C17.2202 18.75 17.5 18.4702 17.5 18.125V3.125C17.5 2.77982 17.2202 2.5 16.875 2.5H14.375V1.875C14.375 1.52982 14.0952 1.25 13.75 1.25H6.25ZM6.875 2.5H13.125V4.375H6.875V2.5Z"
            fill="black"
            fill-opacity="0.4"
          />
        </svg>

        <span
          :class="!isHiddenRightSide && tab == 11 ? 'toolText_pre' : 'toolText_nor'"
          >{{ languageData.job_reception_area }}</span
        >
      </div>

      <div class="icon_nor" style="cursor: pointer; display: none" @click="showBoxSide">
        <img src="../../assets/icon_box_nor.png" />
        <span class="toolText_nor">{{ languageData.box }}</span>
      </div>
    </div>
    <div v-if="!isHiddenRightSide" id="rightSide" class="rightSide">
      <template v-if="tab == 2">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 4px 0px 6px;
          "
        >
          <span class="Text">{{ languageData.collect }}</span>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div style="padding: 10px">
          <!-- <div>
            <h1
              :class="collapseController.isMyType ? 'node-category-title_pre' : 'node-category-title_nor'"
              style="cursor: pointer"
              @click="collapseController.isMyType = !collapseController.isMyType"
            >
              <img
                :src="collapseController.isMyType? require( '../../assets/lx_pre.png' ) : require('../../assets/lx_nor.png')"
                style="width: 13px; height: 13px"
              />
              {{ languageData.my }}
            </h1>
            <div v-if="collapseController.isMyType" class="iconContent">
              <div
                class="iconOption"
                :title="languageData.CPOTE"
                @mousedown="create_template('/json/Collect/C-POTE模式案例.json', 'pbl-1')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.CPOTE
                }}</span>
              </div>

              <div
                class="iconOption"
                :title="languageData.CPOTE2"
                @mousedown="
                  create_template('/json/Collect/C-POTE模式课程设计流程.json', 'pbl-1')
                "
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.CPOTE2
                }}</span>
              </div>
              <div
                class="iconOption"
                :title="languageData.subject_icon"
                @mousedown="
                  create_template('/json/Collect/各科义务教育课标.json', 'pbl-1')
                "
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.subject_icon
                }}</span>
              </div>
              <div class="iconOption" style="visibility: hidden">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    @mousedown="dragInNode('icon-task')"
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.task
                }}</span>
              </div>
            </div>
            <div class="iconContent">
              <div
                class="iconOption"
                :title="languageData.educational_base"
                @mousedown="
                  create_template('/json/Collect/未来教育基地第二期.json', 'pbl-1')
                "
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.educational_base
                }}</span>
              </div>
              <div
                class="iconOption"
                :title="languageData.Project_learning_curriculum_design_framework"
                @mousedown="
                  create_template('/json/Collect/项目式学习课程设计框架.json', 'pbl-1')
                "
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.Project_learning_curriculum_design_framework
                }}</span>
              </div>
            </div>
          </div> -->

          <div>
            <h1
              :class="
                collapseController.isShareType
                  ? 'node-category-title_pre'
                  : 'node-category-title_nor'
              "
              style="cursor: pointer"
              @click="collapseController.isShareType = !collapseController.isShareType"
            >
              <img
                :src="
                  collapseController.isShareType
                    ? require('../../assets/lx_pre.png')
                    : require('../../assets/lx_nor.png')
                "
                style="width: 13px; height: 13px"
              />
              {{ languageData.share }}
            </h1>
            <div v-if="collapseController.isShareType" class="iconContent">
              <div class="iconOption">
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.share1
                }}</span>
              </div>

              <div class="iconOption">
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.share2
                }}</span>
              </div>

              <div class="iconOption" style="visibility: hidden">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    @mousedown="dragInNode('icon-AI')"
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.aiCard
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="tab == 3">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 4px 0px 6px;
          "
        >
          <span class="Text">{{ languageData.toolLibrary }}</span>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div style="padding: 10px">
          <div>
            <h1
              :class="
                collapseController.isSelectToolsType
                  ? 'node-category-title_pre'
                  : 'node-category-title_nor'
              "
              style="cursor: pointer"
              @click="
                collapseController.isSelectToolsType = !collapseController.isSelectToolsType
              "
            >
              <img
                :src="
                  collapseController.isSelectToolsType
                    ? require('../../assets/lx_pre.png')
                    : require('../../assets/lx_nor.png')
                "
                style="width: 13px; height: 13px"
              />
              {{ languageData.toolClass }}
            </h1>
            <div v-if="collapseController.isSelectToolsType" class="iconContent">
              <div class="iconOption" @mousedown="dragInNode('icon-theme')">
                <div class="icon-option">
                  <div class="icon-node icon-start"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.start
                }}</span>
              </div>

              <div class="iconOption" @mousedown="dragInNode('icon-stage')">
                <div class="icon-option">
                  <div class="icon-node icon-stage"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.stage
                }}</span>
              </div>

              <div class="iconOption" @mousedown="dragInNode('icon-task')">
                <div class="icon-option">
                  <div class="icon-node icon-task"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.task
                }}</span>
              </div>
            </div>
            <div
              v-if="collapseController.isSelectToolsType"
              class="iconContent"
              style="display: none"
            >
              <div class="iconOption" @mousedown="dragInNode('icon-select')">
                <div class="icon-option">
                  <div class="icon-node icon-select"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.select
                }}</span>
              </div>

              <div
                class="iconOption"
                style="visibility: hidden"
                @mousedown="dragInNode('icon-homework')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-homework"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.homeworkCard
                }}</span>
              </div>

              <div
                class="iconOption"
                style="visibility: hidden"
                @mousedown="dragInNode('icon-task')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-task"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.task
                }}</span>
              </div>
            </div>
          </div>

          <div>
            <h1
              :class="
                collapseController.isSelectSourceType
                  ? 'node-category-title_pre'
                  : 'node-category-title_nor'
              "
              style="cursor: pointer"
              @click="
                collapseController.isSelectSourceType = !collapseController.isSelectSourceType
              "
            >
              <img
                :src="
                  collapseController.isSelectSourceType
                    ? require('../../assets/lx_pre.png')
                    : require('../../assets/lx_nor.png')
                "
                style="width: 13px; height: 13px"
              />
              {{ languageData.resources }}
            </h1>
            <div v-if="collapseController.isSelectSourceType" class="iconContent">
              <div class="iconOption" @mousedown="dragInNode('icon-card')">
                <div class="icon-option">
                  <div class="icon-node icon-card"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.card
                }}</span>
              </div>
              <div class="iconOption" @mousedown="dragInNode('icon-AI')">
                <div class="icon-option">
                  <div class="icon-node icon-AI"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.aiCard
                }}</span>
              </div>

              <div class="iconOption" @mousedown="dragInNode('icon-thinking')">
                <div class="icon-option">
                  <div class="icon-node icon-thinking"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.thinkingCard
                }}</span>
              </div>
            </div>

            <div v-if="collapseController.isSelectSourceType" class="iconContent">
              <div class="iconOption" @mousedown="dragInNode('icon-whiteboard')">
                <div class="icon-option">
                  <div class="icon-node icon-whiteboard"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.whiteboard_card
                }}</span>
              </div>
              <!-- <div
                class="iconOption"
                @mousedown="dragInNode('icon-map')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-map"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.map_card
                }}</span>
              </div> -->
              <div class="iconOption" @mousedown="dragInNode('icon-camera')">
                <div class="icon-option">
                  <div class="icon-node icon-camera"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.camera_card
                }}</span>
              </div>

              <div
                class="iconOption"
                style="visibility: hidden"
                @mousedown="dragInNode('icon-new-AI')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-AI"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.aiCard
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="tab == 4">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 4px 0px 6px;
          "
        >
          <span class="Text">{{ languageData.materialLibrary }}</span>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>

        <div style="padding: 10px">
          <!-- <div>
            <h1 :class="collapseController.isSelectSourceType ? 'node-category-title_pre' : 'node-category-title_nor'">
              <img src="../../assets/lx_pre.png" v-if="collapseController.isSelectSourceType" style="width:13px;height:13px;" />
              <img src="../../assets/lx_nor.png" v-else style="width:13px;height:13px;" />
              图片
            </h1>
            <div class="iconContent">
              <div class="iconOption">
                <div class="icon-option">
                  <div class="icon-node icon-card" @mousedown="dragInNode('icon-card')">
                  </div>
                </div>
                <span style="font-size: 16px;font-family: SimSun;font-weight: 400;color: #666666;">卡片</span>
              </div>

              <div class="iconOption">
                <div class="icon-option">
                  <div class="icon-node icon-select" @mousedown="dragInNode('icon-select')">
                  </div>
                </div>
                <span style="font-size: 16px;font-family: SimSun;font-weight: 400;color: #666666;">选择题</span>
              </div>
              <el-upload action="#" list-type="picture-card" :auto-upload="false">
                <i slot="default" class="el-icon-circle-plus" style="color:#2995FF;"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                  <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                      <el-icon size="16"><ZoomIn /></el-icon>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                      <el-icon size="16"><Download /></el-icon>
                    </span>
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <el-icon size="16"><Delete /></el-icon>
                    </span>
                  </span>
                </div>
              </el-upload>

            </div>
          </div> -->
        </div>
      </template>

      <template v-if="tab == 9">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 4px 0px 6px;
          "
        >
          <span class="Text">{{ languageData.scaffold }}</span>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div class="scaffold" style="overflow-y: auto; height: 94%">
          <div
            id="scaffoldTip"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding-left: 24px;
              padding-right: 24px;
              margin-bottom: 15px;
            "
          >
            <div
              style="
                padding: 6px 8px;
                border-radius: 6px;
                background: var(--brand-light, #e0eafb);
              "
            >
              <span
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.tip35 }}</span
              >
              <span
                style="
                  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.tip36 }}</span
              >
              <span
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
                >{{ languageData.tip37 }}</span
              >
              <span
                style="
                  position: relative;
                  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ languageData.tip38 }}
                <button
                  type="button"
                  style="
                    position: absolute;
                    bottom: -5px;
                    right: -40px;
                    padding: 0;
                    background: 0 0;
                    border: none;
                    outline: 0;
                    cursor: pointer;
                    font-size: 16px;
                  "
                >
                  <img
                    v-if="hoverClose"
                    src="../../assets/close_pre.png"
                    width="16"
                    height="16"
                    @mouseout="blurClose"
                    @click="handleClose"
                  />
                  <img
                    v-else
                    src="../../assets/close_nor.png"
                    width="16"
                    height="16"
                    @mouseover="focusClose"
                  />
                </button>
              </span>
            </div>
          </div>
          <el-tabs v-model="activeTabName" @tab-click="handleClick">
            <el-tab-pane :name="languageData.pbl" :label="languageData.pbl">
              <div style="padding: 10px">
                <!-- {{ languageData.contentTip }} -->
                <div class="iconContent">
                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl1 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="create_template('/json/pbl/pbl-观察笔记.json', 'pbl-1')"
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl1 }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl2 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="create_template('/json/pbl/PBL介绍.json', 'pbl-2')"
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl2 }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl3 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="
                        create_template('/json/pbl/PBL跨学科模板.json', 'pbl-3')
                      "
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl3 }}</span
                      >
                    </div>
                  </el-tooltip>
                </div>

                <div class="iconContent">
                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl4 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="
                        create_template('/json/pbl/团队成员介绍 活动模板.json', 'pbl-4')
                      "
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl4 }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl5 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="create_template('/json/pbl/POV表格.json', 'pbl-5')"
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl5 }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl6 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="create_template('/json/pbl/康奈尔笔记法.json', 'pbl-6')"
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl6 }}</span
                      >
                    </div>
                  </el-tooltip>
                </div>

                <div class="iconContent" :style="isHk ? 'display: none' : ''">
                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl7 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="
                        create_template('/json/pbl/苏格拉底式追问.json', 'pbl-7')
                      "
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl7 }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl5 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      style="visibility: hidden"
                      @mousedown="create_template('/json/pbl/POV表格.json', 'pbl-5')"
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl5 }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.pbl6 }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      style="visibility: hidden"
                      @mousedown="create_template('/json/pbl/康奈尔笔记法.json', 'pbl-6')"
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.pbl6 }}</span
                      >
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :name="languageData.thinking" :label="languageData.thinking">
              <div style="padding: 10px">
                <div>
                  <h1
                    :class="
                      collapseController.isSiType
                        ? 'node-category-title_pre'
                        : 'node-category-title_nor'
                    "
                    style="cursor: pointer"
                    @click="collapseController.isSiType = !collapseController.isSiType"
                  >
                    <img
                      v-if="collapseController.isSiType"
                      src="../../assets/lx_pre.png"
                      style="width: 13px; height: 13px"
                    />
                    <img
                      v-else
                      src="../../assets/lx_nor.png"
                      style="width: 13px; height: 13px"
                    />
                    {{ languageData.scientific_inquiry }}
                  </h1>
                  <div v-if="collapseController.isSiType" class="iconContent">
                    <el-tooltip popper-class="tooltip-item" placement="bottom">
                      <template #content>
                        <div>
                          {{ languageData.Turmin }}：<br />{{ languageData.tip39
                          }}<br />{{ languageData.tip40 }}<br />{{ languageData.tip41 }}
                        </div>
                      </template>
                      <div
                        class="iconOption"
                        :style="{ display: isHk ? 'none' : null }"
                        @mousedown="
                          create_template('/json/思维类/图尔敏法.json', 'thinking-1')
                        "
                      >
                        <div class="icon-option">
                          <div class="icon-node icon-scaffold"></div>
                        </div>
                        <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                          languageData.Turmin
                        }}</span>
                      </div>
                    </el-tooltip>

                    <el-tooltip popper-class="tooltip-item" placement="bottom">
                      <template #content>
                        <div>
                          {{ languageData.SE }}：<br />{{ languageData.tip39 }}<br />{{
                            languageData.tip40
                          }}<br />{{ languageData.tip41 }}
                        </div>
                      </template>
                      <div
                        class="iconOption"
                        :style="{ display: isHk ? 'none' : null }"
                        @mousedown="
                          create_template('/json/思维类/5E教学设计.json', 'thinking-2')
                        "
                      >
                        <div class="icon-option">
                          <div class="icon-node icon-scaffold"></div>
                        </div>
                        <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                          languageData.SE
                        }}</span>
                      </div>
                    </el-tooltip>

                    <el-tooltip popper-class="tooltip-item" placement="bottom">
                      <template #content>
                        <div>
                          {{ languageData.six_thinking }}：<br />{{ languageData.tip39
                          }}<br />{{ languageData.tip40 }}<br />{{ languageData.tip41 }}
                        </div>
                      </template>
                      <div
                        class="iconOption"
                        @mousedown="
                          create_template('/json/思维类/六顶思考帽.json', 'thinking-3')
                        "
                      >
                        <div class="icon-option">
                          <div class="icon-node icon-scaffold"></div>
                        </div>
                        <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                          languageData.six_thinking
                        }}</span>
                      </div>
                    </el-tooltip>
                  </div>
                </div>

                <div :style="{ display: isHk ? 'none' : null }">
                  <h1
                    :class="
                      collapseController.isStemType
                        ? 'node-category-title_pre'
                        : 'node-category-title_nor'
                    "
                    style="cursor: pointer"
                    @click="
                      collapseController.isStemType = !collapseController.isStemType
                    "
                  >
                    <img
                      v-if="collapseController.isStemType"
                      src="../../assets/lx_pre.png"
                      style="width: 13px; height: 13px"
                    />
                    <img
                      v-else
                      src="../../assets/lx_nor.png"
                      style="width: 13px; height: 13px"
                    />
                    {{ languageData.STEM }}
                  </h1>
                  <div v-if="collapseController.isStemType" class="iconContent">
                    <el-tooltip popper-class="tooltip-item" placement="bottom">
                      <template #content>
                        <div>
                          {{ languageData.SSE }}：<br />{{ languageData.tip39 }}<br />{{
                            languageData.tip40
                          }}<br />{{ languageData.tip41 }}
                        </div>
                      </template>
                      <div
                        class="iconOption"
                        @mousedown="
                          create_template(
                            '/json/思维类/3SE课程设计模型.json',
                            'thinking-4'
                          )
                        "
                      >
                        <div class="icon-option">
                          <div class="icon-node icon-scaffold"></div>
                        </div>
                        <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                          languageData.SSE
                        }}</span>
                      </div>
                    </el-tooltip>

                    <el-tooltip popper-class="tooltip-item" placement="bottom">
                      <template #content>
                        <div>
                          {{ languageData.SEX }}：<br />{{ languageData.tip39 }}<br />{{
                            languageData.tip40
                          }}<br />{{ languageData.tip41 }}
                        </div>
                      </template>
                      <div class="iconOption" style="visibility: hidden">
                        <div class="icon-option">
                          <div class="icon-node icon-scaffold"></div>
                        </div>
                        <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                          languageData.SEX
                        }}</span>
                      </div>
                    </el-tooltip>

                    <el-tooltip popper-class="tooltip-item" placement="bottom">
                      <template #content>
                        <div>
                          {{ languageData.SEX }}：<br />{{ languageData.tip39 }}<br />{{
                            languageData.tip40
                          }}<br />{{ languageData.tip41 }}
                        </div>
                      </template>
                      <div class="iconOption" style="visibility: hidden">
                        <div class="icon-option">
                          <div class="icon-node icon-scaffold"></div>
                        </div>
                        <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                          languageData.SEX
                        }}</span>
                      </div>
                    </el-tooltip>
                  </div>
                </div>
                <div>
                  <h1
                    :class="
                      collapseController.isMindMapType
                        ? 'node-category-title_pre'
                        : 'node-category-title_nor'
                    "
                    style="cursor: pointer"
                    @click="
                      collapseController.isMindMapType = !collapseController.isMindMapType
                    "
                  >
                    <img
                      v-if="collapseController.isMindMapType"
                      src="../../assets/lx_pre.png"
                      style="width: 13px; height: 13px"
                    />
                    <img
                      v-else
                      src="../../assets/lx_nor.png"
                      style="width: 13px; height: 13px"
                    />
                    {{ languageData.map_mind }}
                  </h1>
                  <div v-if="collapseController.isMindMapType" class="iconContent2">
                    <template v-for="(item, index) of map_mind_date" :key="index">
                      <el-tooltip
                        v-if="item.json != ''"
                        popper-class="tooltip-item"
                        placement="bottom"
                      >
                        <template #content>
                          <div>
                            {{ item.name }}：<br />{{ languageData.tip39 }}<br />{{
                              languageData.tip40
                            }}<br />{{ languageData.tip41 }}
                          </div>
                        </template>
                        <div
                          class="iconOption"
                          @mousedown="
                            create_template(item.json, 'thinking-' + (5 + index))
                          "
                        >
                          <div class="icon-option">
                            <div class="icon-node">
                              <img style="width: 34px; height: 34px" :src="item.src" />
                            </div>
                          </div>
                          <span
                            style="font-size: 14px; font-weight: 400; color: #000000"
                            >{{ item.name }}</span
                          >
                        </div>
                      </el-tooltip>
                    </template>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :name="languageData.subject" :label="languageData.subject">
              <div style="padding: 10px">
                <div class="iconContent">
                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.ArProse }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      :style="{ display: isHk ? 'none' : null }"
                      @mousedown="
                        create_template('/json/学科类/论辩文写作.json', 'project-1')
                      "
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.ArProse }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.English_Creative_Writing }}：<br />{{
                          languageData.tip39
                        }}<br />{{ languageData.tip40 }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      @mousedown="
                        create_template('/json/学科类/英语创意写作.json', 'project-2')
                      "
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.English_Creative_Writing }}</span
                      >
                    </div>
                  </el-tooltip>

                  <el-tooltip popper-class="tooltip-item" placement="bottom">
                    <template #content>
                      <div>
                        {{ languageData.ArProse }}：<br />{{ languageData.tip39 }}<br />{{
                          languageData.tip40
                        }}<br />{{ languageData.tip41 }}
                      </div>
                    </template>
                    <div
                      class="iconOption"
                      style="visibility: hidden"
                      @mousedown="
                        create_template('/json/学科类/论辩文写作.json', 'project-1')
                      "
                    >
                      <div class="icon-option">
                        <div class="icon-node icon-scaffold"></div>
                      </div>
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 400;
                          color: #000000;
                          white-space: nowrap;
                        "
                        >{{ languageData.ArProse }}</span
                      >
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane :name="languageData.AI" :label="languageData.AI">
              <div
                style="
                  padding: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <el-input
                  v-model="content"
                  type="textarea"
                  :placeholder="languageData.prompts"
                  :autosize="{ minRows: 4, maxRows: 15 }"
                />
                <el-button
                  v-if="!isSending"
                  size="small"
                  style="margin-top: 10px"
                  type="primary"
                  @click="gain"
                >
                  {{ languageData.generate }}
                </el-button>
                <el-button
                  v-else
                  size="small"
                  style="margin-top: 10px"
                  type="primary"
                  @click="stopAsk"
                >
                  {{ languageData.tip88 }}
                </el-button>
              </div>
              <div
                v-if="ai_result_div"
                style="
                  padding: 10px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <el-input
                  v-model="ai_result"
                  v-loading="loading"
                  :element-loading-text="languageData.tip25"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 15 }"
                ></el-input>
                <div class="flex_x_sb" style="margin-top: 10px">
                  <el-button size="small" type="primary" @click="generate">
                    {{ languageData.gain }}
                  </el-button>
                  <!-- <el-button
                    @click="handle_resource_card"
                    size="small"
                    type="primary"
                    >{{ languageData.generate3 }}</el-button> -->
                </div>
              </div>
              <div style="padding: 10px">
                <el-collapse v-model="activeName" accordion>
                  <template
                    v-for="(item, index) in languageData.promptsList"
                    :key="index"
                  >
                    <el-collapse-item
                      v-if="index == 1 || index == 6"
                      :title="item.label"
                      :name="index + 1"
                    >
                      <template v-for="(items, i) in item.prompt" :key="i">
                        <div
                          class="prompt"
                          style="cursor: pointer"
                          @click="selectProblem(items.label)"
                        >
                          {{ i + 1 }}.{{ items.label }}
                        </div>
                      </template>
                    </el-collapse-item>
                  </template>
                </el-collapse>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <template v-if="tab == 10">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="flex_x_cen">
            <span class="Text" style="margin-right: 10px">{{
              languageData.operation_record
            }}</span>
            <svg
              id="Layer_1"
              class="icon"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              enable-background="new 0 0 20 20"
              xml:space="preserve"
              style="cursor: pointer"
              @click="export_operation_record()"
            >
              <image
                id="image0"
                width="20"
                height="20"
                x="0"
                y="0"
                href="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9u
              ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IiYjMjI5OyYjMTc1
              OyYjMTg4OyYjMjI5OyYjMTM1OyYjMTg2OyI+CjxwYXRoIGlkPSJWZWN0b3IgNDMgKFN0cm9rZSki
              IGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMiA4QzIgNy43MjM4
              NiAyLjIyMzg2IDcuNSAyLjUgNy41SDRDNC4yNzYxNCA3LjUgNC41IDcuNzIzODYgNC41IDhDNC41
              IDguMjc2MTQgNC4yNzYxNCA4LjUgNCA4LjVIM1YxM0gxM1Y4LjVIMTJDMTEuNzIzOSA4LjUgMTEu
              NSA4LjI3NjE0IDExLjUgOEMxMS41IDcuNzIzODYgMTEuNzIzOSA3LjUgMTIgNy41SDEzLjVDMTMu
              Nzc2MSA3LjUgMTQgNy43MjM4NiAxNCA4VjEzLjVDMTQgMTMuNzc2MSAxMy43NzYxIDE0IDEzLjUg
              MTRIMi41QzIuMjIzODYgMTQgMiAxMy43NzYxIDIgMTMuNVY4WiIgZmlsbD0iYmxhY2siIGZpbGwt
              b3BhY2l0eT0iMC45Ii8+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0xMC44NzUgNS41TDggMkw1LjEy
              NSA1LjVINy41VjExLjVDNy41IDExLjc3NjEgNy43MjM4NiAxMiA4IDEyQzguMjc2MTQgMTIgOC41
              IDExLjc3NjEgOC41IDExLjVWNS41SDEwLjg3NVoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9
              IjAuOSIvPgo8L2c+Cjwvc3ZnPgo="
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="black"
              style="cursor: pointer; margin-left: 10px"
              @click="getDataAgain()"
            >
              <title>{{ languageData.refresh }}</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.0733 11.2394L3.4898 8.91992L3.87424 9.79981C4.5691 11.3902 6.15551 12.5 8.00002 12.5C10.4853 12.5 12.5 10.4853 12.5 8L13.5 8C13.5 11.0376 11.0376 13.5 8.00002 13.5C6.0692 13.5 4.37156 12.5051 3.39045 11.0013L2.92674 11.7606L2.0733 11.2394ZM8.00002 3.5C5.51474 3.5 3.50002 5.51472 3.50002 8L2.50002 8C2.50002 4.96243 4.96246 2.5 8.00002 2.5C9.93085 2.5 11.6285 3.49489 12.6096 4.99871L13.0733 4.2394L13.9267 4.7606L12.5102 7.08008L12.1258 6.20019C11.4309 4.60983 9.84453 3.5 8.00002 3.5Z"
                fill="black"
                fill-opacity="0.9"
              />
            </svg>
          </div>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div
          v-loading="loading"
          style="overflow-y: auto; height: calc(100% - 46px)"
          :element-loading-text="languageData.loading"
        >
          <el-collapse v-model="activeGroupOperation" accordion @change="change_collapse">
            <el-collapse-item
              v-for="item of group"
              :key="item.id"
              :title="item.name"
              :name="item.id"
            >
              <div
                v-for="item_group_member of item.group_member"
                :key="item_group_member.userid"
                class="flex_x_sb group_name"
                @click="look_operation_record(item_group_member.operation)"
              >
                <div class="flex_x">
                  {{ item_group_member.username }}
                </div>
                <div class="flex_x">
                  <div
                    class="flex_x"
                    style="
                      justify-content: center;
                      align-items: center;
                      margin-right: 10px;
                    "
                    :title="languageData.tip29"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.00003 4C2.00003 3.72386 2.22389 3.5 2.50003 3.5H4C4.27614 3.5 4.5 3.72386 4.5 4C4.5 4.27614 4.27614 4.5 4 4.5H3.00003L3 14H11V11C11 10.7239 11.2239 10.5 11.5 10.5C11.7762 10.5 12 10.7239 12 11V14.5C12 14.7761 11.7762 15 11.5 15H2.5C2.36739 15 2.24021 14.9473 2.14645 14.8536C2.05268 14.7598 2 14.6326 2 14.5L2.00003 4Z"
                        fill="black"
                        fill-opacity="0.9"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.00006 5.5C5.00006 5.22386 5.22392 5 5.50006 5H13.5001C13.7762 5 14.0001 5.22386 14.0001 5.5C14.0001 5.77614 13.7762 6 13.5001 6H5.50006C5.22392 6 5.00006 5.77614 5.00006 5.5Z"
                        fill="black"
                        fill-opacity="0.9"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.50006 1C9.7762 1 10.0001 1.22386 10.0001 1.5V9.5C10.0001 9.77614 9.7762 10 9.50006 10C9.22392 10 9.00006 9.77614 9.00006 9.5V1.5C9.00006 1.22386 9.22392 1 9.50006 1Z"
                        fill="black"
                        fill-opacity="0.9"
                      />
                    </svg>
                    <div>({{ item_group_member.addNode.length }})</div>
                  </div>
                  <div
                    class="flex_x"
                    style="
                      justify-content: center;
                      align-items: center;
                      margin-right: 10px;
                    "
                    :title="languageData.edit"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 2.5C2 2.22386 2.22386 2 2.5 2H8.5C8.77614 2 9 2.22386 9 2.5C9 2.77614 8.77614 3 8.5 3H3V13H13V8.5C13 8.22386 13.2239 8 13.5 8C13.7761 8 14 8.22386 14 8.5V13.5C14 13.7761 13.7761 14 13.5 14H2.5C2.22386 14 2 13.7761 2 13.5V2.5Z"
                        fill="black"
                        fill-opacity="0.9"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645Z"
                        fill="black"
                        fill-opacity="0.9"
                      />
                    </svg>
                    <div>({{ item_group_member.updateNode.length }})</div>
                  </div>
                  <div
                    class="flex_x"
                    style="justify-content: center; align-items: center"
                    :title="languageData.tip31"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 2.5C5 2.22386 5.22386 2 5.5 2H10.5C10.7761 2 11 2.22386 11 2.5C11 2.77614 10.7761 3 10.5 3H5.5C5.22386 3 5 2.77614 5 2.5Z"
                        fill="#EE3E3E"
                        fill-opacity="0.9"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 4.5C2 4.21185 2.22386 4 2.5 4H13.5C13.7761 4 14 4.21185 14 4.5C14 4.83161 13.7761 5 13.5 5L12.5 5.04348V14H3.5V5H2.5C2.22386 5 2 4.83161 2 4.5ZM4.5 5V12.9565L11.5 13V5.04348L4.5 5Z"
                        fill="#EE3E3E"
                        fill-opacity="0.9"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.5 6.45455C6.77614 6.45455 7 6.65805 7 6.90909V11C7 11.251 6.77614 11.4545 6.5 11.4545C6.22386 11.4545 6 11.251 6 11V6.90909C6 6.65805 6.22386 6.45455 6.5 6.45455Z"
                        fill="#EE3E3E"
                        fill-opacity="0.9"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.5 6.45455C9.77614 6.45455 10 6.65805 10 6.90909V11C10 11.251 9.77614 11.4545 9.5 11.4545C9.22386 11.4545 9 11.251 9 11V6.90909C9 6.65805 9.22386 6.45455 9.5 6.45455Z"
                        fill="#EE3E3E"
                        fill-opacity="0.9"
                      />
                    </svg>
                    <div>({{ item_group_member.deleteNode.length }})</div>
                  </div>
                </div>
                <!-- <div>
                  （{{item_group_member.operation.length}}）
                </div> -->
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </template>

      <template v-if="tab == 11">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div
            class="flex_x_cen"
            style="display: flex; justify-content: center; align-items: center"
          >
            <span class="Text" style="margin-right: 5px">{{
              languageData.job_reception_area
            }}</span>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              style="cursor: pointer"
              @click="getDataAgain()"
            >
              <title>{{ languageData.refresh }}</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.2606 2L6.58008 3.43401L5.70019 3.82321C4.10983 4.52666 3 6.13268 3 8C3 10.516 5.01472 12.5556 7.5 12.5556V13.568C4.46243 13.568 2 11.0751 2 8C2 6.0453 2.99489 4.32667 4.49871 3.33343L3.7394 2.86399L4.2606 2ZM12 8C12 5.48399 9.98528 3.44436 7.5 3.44436V2.432C10.5376 2.432 13 4.92488 13 8C13 9.9547 12.0051 11.6733 10.5013 12.6666L11.2606 13.136L10.7394 14L8.41992 12.566L9.29981 12.1768C10.8902 11.4733 12 9.86732 12 8Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
          </div>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div
          v-loading="loading"
          style="overflow-y: auto; height: calc(100% - 46px)"
          :element-loading-text="languageData.loading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <!-- <div
            v-if="loading"
            style="min-height: 100px"
            v-loading="loading"
            :element-loading-text="languageData.loading"
            element-loading-background="rgba(0, 0, 0, 0)"
          ></div> -->
          <el-collapse v-if="!loading" v-model="activeGroupJobs">
            <template v-for="(item, index) in group" :key="item.id">
              <el-collapse-item :title="item.name" :name="item.id">
                <div
                  v-for="(item_job_reception, i) in item.job_reception"
                  :key="i"
                  class="group_name"
                  :style="
                    selectItem[index] == i
                      ? 'border: 1px solid var(--brand-normal, #3681FC);padding:6px 8px;height: 36px;border-radius: 4px;background: var(--font-icon-wh-1, rgba(255, 255, 255, 0.90));'
                      : 'padding:6px 8px;height: 36px;border-radius: 4px;background: var(--font-icon-wh-1, rgba(255, 255, 255, 0.90));'
                  "
                  @click="
                    look_job_reception_record(
                      JSON.parse(item_job_reception.text).data.nodes,
                      index,
                      i,
                      JSON.parse(item_job_reception.text).data.createDate
                    )
                  "
                >
                  <div class="flex_x_sb">
                    <template
                      v-for="(newItem, _index) in JSON.parse(item_job_reception.text).data
                        .nodes"
                      :key="_index"
                    >
                      <div
                        v-if="newItem.type == 'icon-theme' && _index == 0"
                        style="
                          color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                          text-align: center;
                          font-family: SimSun;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 20px;
                        "
                      >
                        {{ newItem.properties.topic }}
                      </div>
                      <div
                        v-else-if="newItem.type == 'icon-task' && _index == 0"
                        style="
                          color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                          text-align: center;
                          font-family: SimSun;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 20px;
                        "
                      >
                        {{ newItem.properties.taskTitle }}
                      </div>
                      <div
                        v-else-if="newItem.type != 'icon-task' && newItem.type != 'icon-theme' && _index == 0"
                        style="
                          color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                          text-align: center;
                          font-family: SimSun;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 20px;
                        "
                      >
                        </div>
                    </template>
                    <div
                      v-if="item_job_reception.isRead"
                      style="
                        color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                        text-align: center;
                        font-family: SimSun;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                      "
                    >
                      {{ JSON.parse(item_job_reception.text).data.createUser }}
                    </div>
                    <div
                      v-else
                      style="display: flex; justify-content: center; align-items: center"
                    >
                      <span
                        style="
                          margin-right: 10px;
                          color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                          text-align: center;
                          font-family: SimSun;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 20px;
                        "
                        >{{ JSON.parse(item_job_reception.text).data.createUser }}</span
                      >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6"
                        height="6"
                        viewBox="0 0 6 6"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1906_4887)">
                          <circle cx="3" cy="3" r="3" fill="#EE3E3E" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1906_4887">
                            <rect width="6" height="6" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <div class="flex_x_sb">
                    <div
                      style="
                        color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                        font-family: SimSun;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                      "
                    >
                      {{
                        formatDate(JSON.parse(item_job_reception.text).data.createDate)
                      }}
                    </div>
                    <div
                      style="
                        color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                        font-family: SimSun;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                      "
                    >
                      {{
                        formatTime(JSON.parse(item_job_reception.text).data.createDate)
                      }}
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div>
      </template>

      <template v-if="tab == 12">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 4px 0px 6px;
          "
        >
          <span class="Text">NPC</span>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div style="padding: 10px">
          <div>
            <h1
              :class="
                collapseController.isPmType
                  ? 'node-category-title_pre'
                  : 'node-category-title_nor'
              "
              style="cursor: pointer"
              @click="collapseController.isPmType = !collapseController.isPmType"
            >
              <img
                v-if="collapseController.isPmType"
                src="../../assets/lx_pre.png"
                style="width: 13px; height: 13px"
              />
              <img
                v-else
                src="../../assets/lx_nor.png"
                style="width: 13px; height: 13px"
              />
              {{ languageData.pocket_monster }}
            </h1>
            <div v-if="collapseController.isPmType" class="iconContent">
              <div class="iconOption" @mousedown="dragInNode('icon-pm-1')">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    style="
                      background: url('https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%A6%96%E6%80%AA1.png');
                      background-size: cover;
                    "
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.pocket_monster_1
                }}</span>
              </div>

              <div class="iconOption" @mousedown="dragInNode('icon-pm-2')">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    style="
                      background: url('https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%A6%96%E6%80%AA2.png');
                      background-size: cover;
                    "
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.pocket_monster_2
                }}</span>
              </div>

              <div
                class="iconOption"
                style="visibility: hidden"
                @mousedown="dragInNode('icon-AI')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000">{{
                  languageData.aiCard
                }}</span>
              </div>
            </div>
          </div>
          <div>
            <h1
              :class="
                collapseController.isStType
                  ? 'node-category-title_pre'
                  : 'node-category-title_nor'
              "
              style="cursor: pointer"
              @click="collapseController.isStType = !collapseController.isStType"
            >
              <img
                v-if="collapseController.isStType"
                src="../../assets/lx_pre.png"
                style="width: 13px; height: 13px"
              />
              <img
                v-else
                src="../../assets/lx_nor.png"
                style="width: 13px; height: 13px"
              />
              {{ languageData.people }}
            </h1>
            <div v-if="collapseController.isStType" class="iconContent">
              <div class="iconOption" @mousedown="dragInNode('icon-st-1')">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    style="
                      background: url('https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240229211823.png');
                      background-size: cover;
                    "
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000"
                  >{{ languageData.people }}1</span
                >
              </div>

              <div class="iconOption" @mousedown="dragInNode('icon-st-2')">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    style="
                      background: url('https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240229213333.png');
                      background-size: cover;
                    "
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000"
                  >{{ languageData.people }}2</span
                >
              </div>

              <div class="iconOption" @mousedown="dragInNode('icon-st-3')">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    style="
                      background: url('https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240229213557.png');
                      background-size: cover;
                    "
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000"
                  >{{ languageData.people }}3</span
                >
              </div>
            </div>
            <div v-if="collapseController.isStType" class="iconContent">
              <div class="iconOption" @mousedown="dragInNode('icon-st-4')">
                <div class="icon-option">
                  <div
                    class="icon-node icon-collect"
                    style="
                      background: url('https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240229165621.png');
                      background-size: cover;
                    "
                  ></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000"
                  >{{ languageData.people }}4</span
                >
              </div>

              <div
                class="iconOption"
                style="visibility: hidden"
                @mousedown="dragInNode('icon-st-4')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000"
                  >{{ languageData.people }}4</span
                >
              </div>

              <div
                class="iconOption"
                style="visibility: hidden"
                @mousedown="dragInNode('icon-st-4')"
              >
                <div class="icon-option">
                  <div class="icon-node icon-collect"></div>
                </div>
                <span style="font-size: 14px; font-weight: 400; color: #000000"
                  >{{ languageData.people }}4</span
                >
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- AI NPC -->
      <template v-if="tab == 13">
        <div
          style="
            height: 46px;
            background: #ffffff;
            border-radius: 0px 0px 6px 6px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px 4px 0px 6px;
          "
        >
          <span class="Text">AI NPC</span>
          <img
            src="../../assets/hidden.png"
            style="margin-right: 26px; cursor: pointer"
            @click="hiddenRightSide"
          />
        </div>
        <div style="padding: 10px; overflow-y: auto; height: calc(100% - 56px)">
          <template
            v-for="{
              list,
              isLoading,
              groupName,
              loadingText,
              groupType,
              isCollapse,
              setCollapse,
            } in agentListGroups"
            :key="groupName"
          >
            <div>
              <h1
                :class="isCollapse ? 'node-category-title_nor' : 'node-category-title_pre'"
                style="cursor: pointer"
                @click="setCollapse(!isCollapse)"
              >
                <img
                  :src="
                    isCollapse
                      ? require('../../assets/lx_nor.png')
                      : require('../../assets/lx_pre.png')
                  "
                  style="width: 13px; height: 13px"
                />
                {{ groupName }}
              </h1>
              <div
                v-if="!isCollapse"
                v-loading="isLoading"
                :element-loading-text="loadingText"
                class="iconContent"
                :style="{ minHeight: '100px' }"
              >
                <template v-for="(item, i) of list" :key="i">
                  <div class="iconOption" @mousedown="dragInNode('icon-ai-npc', item)">
                    <div class="icon-option">
                      <div
                        class="icon-node icon-collect"
                        :style="
                          'background: url(' +
                          item.headUrl +
                          ');background-size: cover;border-radius: 4px;'
                        "
                      ></div>
                      <!-- <el-avatar shape="square" :size="34" :src="item.headUrl"></el-avatar> -->
                    </div>
                    <div
                      :style="{ width: '100%', display: 'flex', alignItems: 'center' }"
                    >
                      <span style="font-size: 14px; font-weight: 400; color: #000000">
                        {{ item.assistantName }}
                      </span>
                      <el-icon
                        v-if="groupType === 'my'"
                        color="#007bff"
                        @mousedown.stop
                        @click.stop="$emit('addAIAgent', item.id)"
                      >
                        <!-- TODO -->
                        <Edit />
                      </el-icon>
                    </div>
                  </div>
                </template>
                <div
                  v-if="groupType === 'my'"
                  class="iconOption"
                  @click="$emit('addAIAgent', '')"
                >
                  <div class="icon-option" style="width: 50px; height: 50px">
                    <div class="icon-node icon-add"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>

    <div v-if="!isHiddenRightSide && record_details_flag" class="record_details">
      <el-collapse v-model="active_record_details">
        <el-collapse-item
          v-for="item of record_details"
          :key="item.date"
          :title="`${item.date}（${item.arr.length}）`"
          :name="item.date"
        >
          <div
            v-for="(item_arr, index) of item.arr"
            :key="index"
            style="margin: 10px; color: rgba(0, 0, 0, 0.4); font-size: 14px"
          >
            <div class="create_at">
              {{ item_arr.create_at }}
            </div>
            <div
              style="cursor: pointer"
              :title="item_arr.removeHTMLTags ? item_arr.removeHTMLTags : item_arr.name"
              @click="skip_card(item_arr.text.data.nodes[0].id)"
            >
              <span style="color: #3681fc">“{{ item_arr.name }}”</span>
              <span v-html="item_arr.action"></span>
              {{ item_arr.type }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div
      :style="
        !isHiddenRightSide && job_reception_record_details_flag
          ? 'display:block;'
          : 'display:none;'
      "
      class="job_reception_record_details"
    >
      <div style="height: 100%">
        <template v-for="(item, index) in job_reception_record_details" :key="index">
          <div v-if="item.type == 'icon-theme'" style="margin-bottom: 8px">
            <div
              style="
                width: 100%;
                color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                font-family: SimSun;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              "
            >
              {{ languageData.topic }}:
            </div>
            <div
              style="
                width: 100%;
                color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                font-family: SimSun;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              "
            >
              {{ item.properties.topic }}
            </div>
          </div>
          <div
            v-if="item.type == 'icon-task'"
            style="
              margin-bottom: 8px;
              padding-bottom: 8px;
              border-bottom: 1px solid var(--bg3, #e7e7e7);
            "
          >
            <div
              style="
                color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                font-family: SimSun;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              "
            >
              {{ languageData.taskName }}: {{ item.properties.taskTitle }}
            </div>
            <div
              style="
                color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                font-family: SimSun;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin-top: 8px;
              "
            >
              {{ languageData.requirements }}:
              <div class="content2" v-html="item.properties.taskContent"></div>
            </div>
          </div>
          <div v-if="item.type == 'icon-whiteboard'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ languageData.whiteboard_card }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div v-if="item.properties.createDate" class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <img
              v-if="
                item.properties.whiteboardContent &&
                item.properties.whiteboardContent != ''
              "
              style="
                width: auto;
                height: auto;
                max-width: calc(100%);
                max-height: calc(100%);
              "
              :src="item.properties.whiteboardContent"
            />
            <p
              v-else
              style="
                color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
                font-family: SimSun;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
              "
            >
              {{ languageData.tip76 }}
            </p>
          </div>
          <div v-if="item.type == 'icon-card'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ item.properties.title }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div
              v-if="item.properties.createDate"
              class="flex_x_sb"
              style="margin-bottom: 8px"
            >
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <div
              v-if="item.properties.radio == 'content'"
              class="content2"
              v-html="item.properties.content"
            ></div>
            <iframe
              v-else-if="item.properties.radio == 'iframeUrl'"
              style="
                width: auto;
                height: auto;
                max-width: calc(100%);
                max-height: calc(100%);
              "
              :src="
                item.properties.iframeUrl.indexOf('https://') != -1 ||
                item.properties.iframeUrl.indexOf('http://') != -1
                  ? item.properties.iframeUrl
                  : '//' + item.properties.iframeUrl
              "
              frameborder="no"
              border="0"
              sandbox="allow-forms allow-scripts allow-same-origin"
            ></iframe>
            <div
              v-else-if="item.properties.radio == 'embedCode'"
              class="embedCode"
              style="
                width: 100%;
                justify-content: center;
                display: flex;
                align-items: center;
              "
              v-html="item.properties.embedCode"
            ></div>
            <img
              v-else-if="item.properties.radio == 'imageList'"
              style="width: auto; height: auto; max-width: 100%; max-height: 100%"
              :src="item.properties.imageList[0].url"
            />
            <video
              v-else-if="item.properties.radio == 'videoList'"
              controls
              :src="item.properties.videoList[0].url"
              style="width: auto; height: auto; max-width: 100%; max-height: 100%"
            ></video>
            <audio
              v-else-if="item.properties.radio == 'audioList'"
              controls
              :src="item.properties.audioList[0].url"
              style="width: 100%; margin-top: 15px"
            ></audio>
            <div
              v-else-if="
                item.properties.radio == 'fileList' &&
                item.properties.fileList &&
                item.properties.fileList.length != 0 &&
                (item.properties.fileList[0].url.indexOf('.pdf') != -1 ||
                  item.properties.fileList[0].url.indexOf('.PDF') != -1)
              "
              style="
                width: 100%;
                height: 200px;
                justify-content: center;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <iframe
                style="
                  position: absolute;
                  transform: scale(0.5, 0.5) translate(-47%, -50%);
                  width: 189%;
                  height: 206%;
                  top: 0;
                  left: 0;
                  right: 0;
                "
                :src="
                  'https://cloud.cocorobo.cn/pdf.js/web/viewer.html?file=' +
                  item.properties.fileList[0].url
                "
                frameborder="no"
                border="0"
              ></iframe>
            </div>
            <div
              v-else-if="
                item.properties.radio == 'fileList' &&
                item.properties.fileList &&
                item.properties.fileList.length != 0 &&
                item.properties.fileList[0].url.indexOf('.pdf') == -1 &&
                item.properties.fileList[0].url.indexOf('.PDF') == -1
              "
              style="
                width: 100%;
                height: 200px;
                justify-content: center;
                display: flex;
                align-items: center;
                position: relative;
              "
            >
              <iframe
                style="
                  position: absolute;
                  transform: scale(0.5, 0.5) translate(-47%, -50%);
                  width: 187%;
                  height: 206%;
                  top: 0;
                  left: 0;
                  right: 0;
                "
                :src="
                  'https://view.officeapps.live.com/op/view.aspx?src=' +
                  item.properties.fileList[0].url
                "
                frameborder="no"
                border="0"
              ></iframe>
            </div>
          </div>
          <div v-if="item.type == 'icon-AI'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ item.properties.topic }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div
              v-if="item.properties.createDate"
              class="flex_x_sb"
              style="margin-bottom: 8px"
            >
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <div v-if="item.properties.radio == 'description'" class="content2">
              {{ item.properties.aiContent }}
            </div>
            <img
              v-else-if="
                item.properties.radio == 'image' && item.properties.imgResult != ''
              "
              style="width: auto; height: auto; max-width: 100%; max-height: 100%"
              :src="item.properties.imgResult"
            />
          </div>
          <div v-if="item.type == 'icon-new-AI'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ item.properties.topic }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div
              v-if="item.properties.createDate"
              class="flex_x_sb"
              style="margin-bottom: 8px"
            >
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <div v-if="item.properties.radio == 'description'" class="content2">
              {{ item.properties.aiContent }}
            </div>
            <img
              v-else-if="
                item.properties.radio == 'image' && item.properties.imgResult != ''
              "
              style="width: auto; height: auto; max-width: 100%; max-height: 100%"
              :src="item.properties.imgResult"
            />
            <audio
              v-else-if="
                item.properties.radio == 'audio' && item.properties.audioList.length != 0
              "
              style="max-width: 100%; max-height: 100%"
              :src="item.properties.audioList[0].url"
              controls
            ></audio>
          </div>
          <div v-if="item.type == 'icon-thinking'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ languageData.thinkingCard }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div
              v-if="item.properties.createDate"
              class="flex_x_sb"
              style="margin-bottom: 8px"
            >
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <img
              style="
                width: auto;
                height: auto;
                max-width: calc(100%);
                max-height: calc(100%);
              "
              :src="
                item.properties.thinkingContent
                  ? item.properties.thinkingContent
                  : 'https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/e8215fd4-4b4c-4373-ac36-5ea301aefa73.png'
              "
            />
          </div>
          <div v-if="item.type == 'icon-map'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ languageData.map_card }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div
              v-if="item.properties.createDate"
              class="flex_x_sb"
              style="margin-bottom: 8px"
            >
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <img
              v-if="item.properties.lat && item.properties.lng && item.properties.map_url"
              style="
                width: auto;
                height: auto;
                max-width: calc(100%);
                max-height: calc(100%);
              "
              :src="item.properties.map_url"
            />
          </div>
          <div v-if="item.type == 'icon-camera'" style="padding: 8px 0px">
            <div class="flex_x_sb">
              <div
                style="
                  color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.9));
                  font-family: SimSun;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                "
              >
                {{ languageData.camera_card }}
              </div>
              <div
                style="
                  color: var(--brand-normal, #3681fc);
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                "
              >
                {{ item.properties.createUser }}
              </div>
            </div>
            <div
              v-if="item.properties.createDate"
              class="flex_x_sb"
              style="margin-bottom: 8px"
            >
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatDate(item.properties.createDate) }}
              </div>
              <div
                style="
                  color: var(--font-icon-gy-3-placeholder, rgba(0, 0, 0, 0.4));
                  font-family: SimSun;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                "
              >
                {{ formatTime(item.properties.createDate) }}
              </div>
            </div>
            <img
              v-if="item.properties.src"
              style="
                width: auto;
                height: auto;
                max-width: calc(100%);
                max-height: calc(100%);
              "
              :src="item.properties.src"
            />
          </div>
        </template>
      </div>
    </div>
    <div id="sendCard">
      <el-dialog
        v-model="export_operation_record_flag"
        width="30%"
        :show-close="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
      >
        <template #header>
          <span style="width: 100%; position: relative">
            <span class="el-dialog__title">{{ languageData.tip104 }}</span>
            <button type="button" class="close" style="top: 4px; right: 0">
              <img
                v-if="hoverClose"
                src="../../assets/close_pre.png"
                width="16"
                height="16"
                @mouseout="blurClose"
                @click="handleExportClose"
              />
              <img
                v-else
                src="../../assets/close_nor.png"
                width="16"
                height="16"
                @mouseover="focusClose"
              />
            </button>
          </span>
        </template>
        <span
          style="
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          "
        >
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >{{ languageData.tip75 }}</el-checkbox
          >
          <div style="margin: 5px 0"></div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <el-checkbox-group v-model="checkedGroup">
              <template v-for="item in allGroup" :key="item.id">
                <el-checkbox
                  :label="item.id"
                  style="margin-right: 8px; margin-bottom: 10px"
                >
                  {{ item.name }}</el-checkbox
                >
              </template>
            </el-checkbox-group>
          </div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 10px;
            "
          >
            {{ languageData.tip106 }}
          </div>
          <div
            style="
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
            "
          >
            <el-radio
              v-model="radio"
              label="1"
              value="1"
              style="margin-bottom: 0; margin-right: 10px"
              @change="setSelect"
              >{{ languageData.tip105 }}</el-radio
            >
            <el-radio
              v-model="radio"
              label="2"
              value="2"
              style="margin-bottom: 0"
              @change="setSelect"
              >{{ languageData.jsonFiles }}</el-radio
            >
          </div>
        </span>
        <template #footer>
          <span class="dialog-footer">
            <el-button
              type="primary"
              size="small"
              :title="languageData.cancel"
              @click="handleExportClose"
              >{{ languageData.cancel }}</el-button
            >
            <el-button
              type="primary"
              size="small"
              :title="languageData.confirmSend"
              @click="handleExport()"
              >{{ languageData.export }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
import { sendUpdate, add_Operation } from "../../tools/send.js";
import LogicFlow from "@logicflow/core";
import {
  replaceAllCardId,
  find_max_x,
  find_min_y,
  formatDateTime,
  formatDateTime2,
  removeHTMLTags,
} from "../../tools/utils.js";
export default {
  name: "DiagramSidebar",
  components: {},
  props: {
    isHidden: Boolean,
    newTab: Boolean,
    lf: LogicFlow,
    allGroup: { type: Array, default: undefined },
    refreshAIAgent: Boolean,
  },
  emits: [
    "addAIAgent",
    "clearIncreasingTrenTime",
    "openBackground",
    "openNewAnalyse",
    "openAnalyse",
    "openJobReception",
    "getIsHidden",
    "getBackground",
    "dragInNode",
    "setRefreshAIAgent",
  ],
  data() {
    return {
      shareAgentList: [],
      shareAgentList_loading: false,
      agentList: [],
      isSending: false,
      record_loading: true,
      checkAll: false,
      export_operation_record_flag: false,
      checkedGroup: [],
      isIndeterminate: true,
      active_id: "",
      group: [],
      record_details_flag: false,
      record_details: [],
      selectItem: [],
      job_reception_record_details: [],
      job_reception_record_details_flag: false,
      active_record_details: [],
      activeGroupJobs: [],
      activeGroupOperation: [],
      map_mind_date: [
        {
          name: "KWL图",
          src: require("../../assets/thinking/icon/KWL图.png"),
          json: "/json/思维类/思维导图/KWL图.json",
        },
        {
          name: "W图",
          src: require("../../assets/thinking/icon/W图.png"),
          json: "/json/思维类/思维导图/W图.json",
        },
        {
          name: "X图",
          src: require("../../assets/thinking/icon/X图.png"),
          json: "/json/思维类/思维导图/X图.json",
        },
        {
          name: "Y图",
          src: require("../../assets/thinking/icon/Y图.png"),
          json: "/json/思维类/思维导图/Y图.json",
        },
        // {
        //   name:"钉耙图",
        //   src:require("../../assets/thinking/icon/钉耙图.png"),
        //   json: ""
        // },
        // {
        //   name:"蝴蝶图",
        //   src:require("../../assets/thinking/icon/蝴蝶图.png"),
        //   json: ""
        // },
        // {
        //   name:"金字塔图",
        //   src:require("../../assets/thinking/icon/金字塔图.png"),
        //   json: ""
        // },
        // {
        //   name:"流程分析图",
        //   src:require("../../assets/thinking/icon/流程分析图.png"),
        //   json: ""
        // },
        // {
        //   name:"情节构成图",
        //   src:require("../../assets/thinking/icon/情节构成图（右）.png"),
        //   json: ""
        // },
        // {
        //   name:"情节构成图",
        //   src:require("../../assets/thinking/icon/情节构成图（左）.png"),
        //   json: ""
        // },
        // {
        //   name:"数据图3",
        //   src:require("../../assets/thinking/icon/数据图（3）.png"),
        //   json: ""
        // },
        // {
        //   name:"数据图6",
        //   src:require("../../assets/thinking/icon/数据图（6）.png"),
        //   json: ""
        // },
        // {
        //   name:"水母图",
        //   src:require("../../assets/thinking/icon/水母图.png"),
        //   json: ""
        // },
        // {
        //   name:"糖果图",
        //   src:require("../../assets/thinking/icon/糖果图.png"),
        //   json: ""
        // },
        // {
        //   name:"同心圆图",
        //   src:require("../../assets/thinking/icon/同心圆图.png"),
        //   json: ""
        // },
        {
          name: "韦恩三圆圈图",
          src: require("../../assets/thinking/icon/韦恩三圆圈图.png"),
          json: "/json/思维类/思维导图/韦恩三圆圈图.json",
        },
        {
          name: "韦恩图",
          src: require("../../assets/thinking/icon/韦恩图.png"),
          json: "/json/思维类/思维导图/韦恩图.json",
        },
        {
          name: "鱼骨图",
          src: require("../../assets/thinking/icon/鱼骨图.png"),
          json: "/json/思维类/思维导图/鱼骨图.json",
        },
        // {
        //   name:"中心圆圈图",
        //   src:require("../../assets/thinking/icon/中心圆圈图.png"),
        //   json: ""
        // },
        // {
        //   name:"钻石图",
        //   src:require("../../assets/thinking/icon/钻石图.png"),
        //   json: ""
        // },
        // {
        //   name:"坐标横轴图",
        //   src:require("../../assets/thinking/icon/坐标横轴图.png"),
        //   json: ""
        // },
        // {
        //   name:"坐标轴图",
        //   src:require("../../assets/thinking/icon/坐标轴图.png"),
        //   json: ""
        // },
      ],
      hoverClose: false,
      isHiddenRightSide: true,
      isHiddenRightSide_flag: true,

      collapseController: {
        isSelectSourceType: true,
        isMyAgent: true,
        isShareAgent: true,
        isSiType: true,
        isStemType: true,
        isMindMapType: true,
        isMyType: true,
        isShareType: true,
        isSelectToolsType: true,
        isAIType: true,
        isPmType: true,
        isStType: true,
      },
      tab: 3,
      disabled: false,
      languageData: {},
      activeName: "1",
      activeTabName: "",
      content: "",
      ai_source: null,
      base_url: "https://gpt.cocorobo.cn",
      ai_result: "",
      loading: false,
      ai_result_div: false,
      pre_node_id: [],
      y_coordinate: 0,
      startPointy: 537.5,
      endPointy: 623,
      isHk: false,
      radio: "1",
    };
  },
  computed: {
    agentListGroups() {
      return [
        {
          list: this.agentList,
          isLoading: this.loading,
          loadingText: this.languageData.loading,
          groupName: this.languageData.my,
          groupType: "my",
          isCollapse: !this.collapseController.isMyAgent,
          setCollapse: (v) => {
            this.collapseController.isMyAgent = !v;
          },
        },
        {
          list: this.shareAgentList,
          isLoading: this.shareAgentList_loading,
          loadingText: this.languageData.loading,
          groupName: this.languageData.share4,
          groupType: "share",
          isCollapse: !this.collapseController.isShareAgent,
          setCollapse: (v) => {
            this.collapseController.isShareAgent = !v;
          },
        },
      ];
    },
  },
  watch: {
    isHidden(newVal) {
      this.isHiddenRightSide_flag
        ? (this.isHiddenRightSide = newVal)
        : (this.isHiddenRightSide = false);
    },
    newTab() {
      this.tab = 3;
    },
    "$store.state.select_CSCLDataByID"() {
      this.handle_record_data();
    },
    refreshAIAgent(newVal) {
      if (newVal) {
        this.getAIAgent();
      }
    },
  },
  mounted() {
    this.$emit("getIsHidden", false);
    this.handle_record_data();
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.isHk = false;
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.isHk = true;
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.isHk = false;
      this.languageData = this.enLanguage;
    } else {
      this.isHk = false;
      this.languageData = this.zhHansLanguage;
    }
    this.activeTabName = this.languageData.pbl;
    this.map_mind_date = [
      {
        name: this.languageData.kwl,
        src: require("../../assets/thinking/icon/KWL图.png"),
        json: "/json/思维类/思维导图/KWL图.json",
      },
      {
        name: this.languageData.w,
        src: require("../../assets/thinking/icon/W图.png"),
        json: "/json/思维类/思维导图/W图.json",
      },
      {
        name: this.languageData.x,
        src: require("../../assets/thinking/icon/X图.png"),
        json: "/json/思维类/思维导图/X图.json",
      },
      {
        name: this.languageData.y,
        src: require("../../assets/thinking/icon/Y图.png"),
        json: "/json/思维类/思维导图/Y图.json",
      },
      // {
      //   name:"钉耙图",
      //   src:require("../../assets/thinking/icon/钉耙图.png"),
      //   json: ""
      // },
      // {
      //   name:"蝴蝶图",
      //   src:require("../../assets/thinking/icon/蝴蝶图.png"),
      //   json: ""
      // },
      // {
      //   name:"金字塔图",
      //   src:require("../../assets/thinking/icon/金字塔图.png"),
      //   json: ""
      // },
      // {
      //   name:"流程分析图",
      //   src:require("../../assets/thinking/icon/流程分析图.png"),
      //   json: ""
      // },
      // {
      //   name:"情节构成图",
      //   src:require("../../assets/thinking/icon/情节构成图（右）.png"),
      //   json: ""
      // },
      // {
      //   name:"情节构成图",
      //   src:require("../../assets/thinking/icon/情节构成图（左）.png"),
      //   json: ""
      // },
      // {
      //   name:"数据图3",
      //   src:require("../../assets/thinking/icon/数据图（3）.png"),
      //   json: ""
      // },
      // {
      //   name:"数据图6",
      //   src:require("../../assets/thinking/icon/数据图（6）.png"),
      //   json: ""
      // },
      // {
      //   name:"水母图",
      //   src:require("../../assets/thinking/icon/水母图.png"),
      //   json: ""
      // },
      // {
      //   name:"糖果图",
      //   src:require("../../assets/thinking/icon/糖果图.png"),
      //   json: ""
      // },
      // {
      //   name:"同心圆图",
      //   src:require("../../assets/thinking/icon/同心圆图.png"),
      //   json: ""
      // },
      {
        name: this.languageData.we,
        src: require("../../assets/thinking/icon/韦恩三圆圈图.png"),
        json: "/json/思维类/思维导图/韦恩三圆圈图.json",
      },
      {
        name: this.languageData.venn,
        src: require("../../assets/thinking/icon/韦恩图.png"),
        json: "/json/思维类/思维导图/韦恩图.json",
      },
      {
        name: this.languageData.fishbone_diagram,
        src: require("../../assets/thinking/icon/鱼骨图.png"),
        json: "/json/思维类/思维导图/鱼骨图.json",
      },
      // {
      //   name:"中心圆圈图",
      //   src:require("../../assets/thinking/icon/中心圆圈图.png"),
      //   json: ""
      // },
      // {
      //   name:"钻石图",
      //   src:require("../../assets/thinking/icon/钻石图.png"),
      //   json: ""
      // },
      // {
      //   name:"坐标横轴图",
      //   src:require("../../assets/thinking/icon/坐标横轴图.png"),
      //   json: ""
      // },
      // {
      //   name:"坐标轴图",
      //   src:require("../../assets/thinking/icon/坐标轴图.png"),
      //   json: ""
      // },
    ];
  },
  methods: {
    setSelect(e) {
      this.radio = e;
    },
    handleClick(tab) {
      if (tab.name == this.languageData.AI) {
        this.$emit("openJobReception", false);
      } else {
        this.$emit("openJobReception", false);
      }
    },
    handleExport() {
      if (this.radio == "1") {
        let txt = "";
        this.checkedGroup.forEach((id) => {
          this.allGroup.forEach((allGroup) => {
            if (allGroup.id == id) {
              allGroup.group_member.forEach((group_member) => {
                group_member.operation.forEach((operation) => {
                  let text = {};
                  try {
                    text = JSON.parse(operation.text);
                  } catch {
                    text = operation.text;
                  }
                  let action = "";
                  let type = "";
                  switch (text.type) {
                    case "updateNode":
                      if (text.data.nodes[0]?.properties?.content) {
                        let removeHTMLTags = this.removeHTMLTags(
                          text.data.nodes[0].properties.content
                        );
                        action = `${this.languageData.tip62}${this.languageData.zhang}${this.languageData.tip63}${removeHTMLTags}${this.languageData.tip64}`;
                      } else if (text.data.nodes[0]?.properties?.title) {
                        action =
                          this.languageData.tip67 + text.data.nodes[0].properties.title;
                      } else {
                        action = `${this.languageData.tip62}${this.languageData.zhang}`;
                      }
                      switch (text.data.nodes[0]?.type) {
                        case "icon-card":
                          type = this.languageData.card;
                          break;
                        case "icon-select":
                          type = this.languageData.select;
                          break;
                        case "icon-task":
                          type = this.languageData.task;
                          break;
                        case "icon-AI":
                          type = this.languageData.aiCard;
                          break;
                        case "icon-new-AI":
                          type = this.languageData.aiCard;
                          break;
                        case "icon-theme":
                          type = this.languageData.start;
                          break;
                        case "icon-stage":
                          type = this.languageData.stage;
                          break;
                        case "icon-thinking":
                          type = this.languageData.thinkingCard;
                          break;
                        case "icon-whiteboard":
                          type = this.languageData.whiteboard_card;
                          break;
                        case "icon-map":
                          type = this.languageData.map_card;
                          break;
                        case "icon-camera":
                          type = this.languageData.camera_card;
                          break;
                        default:
                          break;
                      }
                      break;
                    case "addNode":
                      action = `${this.languageData.tip70}${this.languageData.zhang}`;
                      switch (text.data.nodes[0]?.type) {
                        case "icon-card":
                          type = this.languageData.card;
                          break;
                        case "icon-select":
                          type = this.languageData.select;
                          break;
                        case "icon-task":
                          type = this.languageData.task;
                          break;
                        case "icon-AI":
                          type = this.languageData.aiCard;
                          break;
                        case "icon-new-AI":
                          type = this.languageData.aiCard;
                          break;
                        case "icon-theme":
                          type = this.languageData.start;
                          break;
                        case "icon-stage":
                          type = this.languageData.stage;
                          break;
                        case "icon-thinking":
                          type = this.languageData.thinkingCard;
                          break;
                        case "icon-whiteboard":
                          type = this.languageData.whiteboard_card;
                          break;
                        case "icon-map":
                          type = this.languageData.map_card;
                          break;
                        case "icon-camera":
                          type = this.languageData.camera_card;
                          break;
                        default:
                          break;
                      }
                      break;
                    case "deleteNode":
                      action = `${this.languageData.tip71}${this.languageData.zhang}`;
                      switch (text.data.nodes[0]?.type) {
                        case "icon-card":
                          type = this.languageData.card;
                          break;
                        case "icon-select":
                          type = this.languageData.select;
                          break;
                        case "icon-task":
                          type = this.languageData.task;
                          break;
                        case "icon-AI":
                          type = this.languageData.aiCard;
                          break;
                        case "icon-new-AI":
                          type = this.languageData.aiCard;
                          break;
                        case "icon-theme":
                          type = this.languageData.start;
                          break;
                        case "icon-stage":
                          type = this.languageData.stage;
                          break;
                        case "icon-thinking":
                          type = this.languageData.thinkingCard;
                          break;
                        case "icon-whiteboard":
                          type = this.languageData.whiteboard_card;
                          break;
                        case "icon-map":
                          type = this.languageData.map_card;
                          break;
                        case "icon-camera":
                          type = this.languageData.camera_card;
                          break;
                        default:
                          break;
                      }
                      break;
                    case "addEdge":
                      action = `${this.languageData.tip68}`;
                      break;
                    case "deleteEdge":
                      action = `${this.languageData.tip69}`;
                      break;
                    case "updateEdge":
                      action = `${this.languageData.tip72}`;
                      break;
                    default:
                      action = `${this.languageData.tip73}${this.languageData.zhang}`;
                      type = this.languageData.card2;
                      break;
                  }
                  txt =
                    txt +
                    formatDateTime(new Date(operation.create_at).getTime()) +
                    ":" +
                    group_member.name +
                    ":" +
                    action +
                    this.languageData.tip74 +
                    type +
                    "\n";
                });
              });
            }
          });
        });
        const blob = new Blob([txt], {
          type: "text/plain;charset=utf-8",
        }); // 根据 blob生成 url链接
        const objectURL = URL.createObjectURL(blob); // 创建一个 a 标签Tag
        const aTag = document.createElement("a"); // 设置文件的下载地址
        aTag.href = objectURL; // 设置保存后的文件名称
        aTag.download = "操作记录.txt"; // 给 a 标签添加点击事件
        aTag.click(); // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。 // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
        URL.revokeObjectURL(objectURL);
        this.handleExportClose();
      } else {
        let str = [];
        this.checkedGroup.forEach((id) => {
          this.allGroup.forEach((allGroup) => {
            if (allGroup.id == id) {
              allGroup.group_member.forEach((group_member) => {
                let str1 = [];
                group_member.operation.forEach((operation) => {
                  let text = {};
                  try {
                    text = JSON.parse(operation.text);
                  } catch {
                    text = operation.text;
                  }
                  operation.text = text;
                  str1.push(operation);
                });
                str.push({
                  groupName: allGroup.name,
                  name: group_member.username,
                  data: str1,
                });
              });
            }
          });
        });
        this.download("操作记录.json", JSON.stringify(str));
        this.handleExportClose();
      }
    },
    download(filename, text) {
      window.sessionStorage.setItem(filename, text);
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    export_operation_record() {
      this.export_operation_record_flag = true;
    },
    handleExportClose() {
      this.checkedGroup = [];
      this.checkAll = false;
      this.isIndeterminate = true;
      this.export_operation_record_flag = false;
    },
    handleCheckAllChange(value) {
      if (value) {
        this.checkedGroup = this.allGroup.map((item) => {
          return item.id;
        });
      } else {
        this.checkedGroup = [];
      }
      this.isIndeterminate = false;
    },
    change_collapse(id) {
      if (id == "") {
        console.log();
      } else {
        const active_group = this.group.filter((item) => {
          return item.id == id;
        });
        this.record_details_flag = true;
        let operation = [];
        active_group.forEach((item) => {
          item.group_member.forEach((item2) => {
            operation = operation.concat(item2.operation);
          });
        });
        //console.log(operation);
        this.look_operation_record(operation);
      }
    },
    skip_card(id) {
      this.lf.focusOn({
        id: id,
      });
    },
    formatDate(create_at) {
      var date = new Date(create_at);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      return year + "-" + month + "-" + day;
    },
    formatTime(create_at) {
      var date = new Date(create_at);
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      return hour + ":" + minute + ":" + second;
    },
    handle_record_data() {
      let that = this;
      this.group = this.allGroup;
      for (let j = 0; j < this.group.length; j++) {
        this.group[j].job_reception = [];
        for (let i = 0; i < this.group[j].group_member.length; i++) {
          this.group[j].group_member[i].operation = [];
          this.group[j].group_member[i].addNode = [];
          this.group[j].group_member[i].deleteNode = [];
          this.group[j].group_member[i].updateNode = [];
          that.$store.state.select_CSCLDataByID.forEach((cscldata) => {
            // if (cscldata.cid == this.group[j].id) {
            //   let has_group_member = this.group[j].group_member.filter((filter) => {
            //     return filter.userid == cscldata.userid;
            //   });
            //   if (has_group_member.length == 0) {
            //     this.group[j].group_member[this.group[j].group_member.length]={
            //       addNode: [],
            //       create_at: cscldata.create_at,
            //       deleteNode: [],
            //       groupCid: cscldata.cid,
            //       name: cscldata.username,
            //       operation: [],
            //       updateNode: [],
            //       userid: cscldata.userid,
            //       username: cscldata.username,
            //       is_group_member:true
            //     };
            //     this.group[j].group_member[0].operation=cscldata;
            //     console.log(this.group[j].group_member[0]);
            //     let text ={}
            //     try {
            //       text=JSON.parse(cscldata.text)
            //     } catch{
            //       text=cscldata.text
            //     }
            //     switch (text.type) {
            //       case "addNode":
            //       this.group[j].group_member[0].addNode.push(cscldata);
            //         break;
            //       case "deleteNode":
            //       this.group[j].group_member[0].deleteNode.push(cscldata);
            //         break;
            //       default:
            //       this.group[j].group_member[0].updateNode.push(cscldata);
            //         break;
            //     }
            //   }
            // }
            if (
              cscldata.userid == this.group[j].group_member[i].userid &&
              !this.group[j].group_member[i]?.is_group_member
            ) {
              this.group[j].group_member[i].operation.push(cscldata);
              let text = {};
              try {
                text = JSON.parse(cscldata.text);
              } catch {
                text = cscldata.text;
              }
              switch (text.type) {
                case "addNode":
                  this.group[j].group_member[i].addNode.push(cscldata);
                  break;
                case "deleteNode":
                  this.group[j].group_member[i].deleteNode.push(cscldata);
                  break;
                default:
                  this.group[j].group_member[i].updateNode.push(cscldata);
                  break;
              }
            }
          });
        }
        //炜桐
        try {
          this.group[j].job_reception = that.$store.state.select_CSCLDataByID.filter(
            (cscldata) => {
              return (
                cscldata.cid == this.group[j].id &&
                JSON.parse(cscldata.text).type == "sumbitJob"
              );
            }
          );
        } catch {
          this.group[j].job_reception = that.$store.state.select_CSCLDataByID.filter(
            (cscldata) => {
              return (
                cscldata.cid == this.group[j].id && cscldata.text.type == "sumbitJob"
              );
            }
          );
        }
        try {
          this.group[j].job_reception.forEach((item2) => {
            item2.isRead = that.$store.state.select_CSCLDataByID.some((cscldata) => {
              return (
                cscldata.userid == item2.userid &&
                JSON.parse(cscldata.text).type == "read" &&
                JSON.parse(cscldata.text).data.createDate ==
                  JSON.parse(item2.text).data.createDate
              );
            });
          });
        } catch {
          try {
            this.group[j].job_reception.forEach((item2) => {
              item2.isRead = that.$store.state.select_CSCLDataByID.some((cscldata) => {
                return (
                  cscldata.userid == item2.userid &&
                  cscldata.text.type == "read" &&
                  cscldata.text.data.createDate == JSON.parse(item2.text).data.createDate
                );
              });
            });
          } catch {
            try {
              this.group[j].job_reception.forEach((item2) => {
                item2.isRead = that.$store.state.select_CSCLDataByID.some((cscldata) => {
                  return (
                    cscldata.userid == item2.userid &&
                    JSON.parse(cscldata.text).type == "read" &&
                    JSON.parse(cscldata.text).data.createDate ==
                      item2.text.data.createDate
                  );
                });
              });
            } catch {
              this.group[j].job_reception.forEach((item2) => {
                item2.isRead = that.$store.state.select_CSCLDataByID.some((cscldata) => {
                  return (
                    cscldata.userid == item2.userid &&
                    cscldata.text.type == "read" &&
                    cscldata.text.data.createDate == item2.text.data.createDate
                  );
                });
              });
            }
          }
        }
      }
      // this.group.forEach((group, j) => {

      // });
      that.loading = false;
      this.record_loading = false;
    },
    removeHTMLTags,
    look_operation_record(item) {
      console.log(item);
      let NewData = [];
      item.forEach((item) => {
        item.create_at = formatDateTime(new Date(item.create_at).getTime());
        item.date = formatDateTime2(new Date(item.create_at).getTime());
        item.timestamp = new Date(item.create_at).getTime() + 28800000;
        let text = {};
        try {
          text = JSON.parse(item.text);
        } catch {
          text = item.text;
        }
        item.text = text;
        let type = "";
        let action = "";
        switch (text.type) {
          case "clean":
            action = `<span style='color:#FF8000'>${this.languageData.tip34}</span>`;
            break;
          case "updateNode":
            //console.log(text);
            if (text.data.nodes[0]?.properties?.content) {
              let removeHTMLTags = this.removeHTMLTags(
                text.data.nodes[0].properties.content
              );
              item.removeHTMLTags = this.languageData.tip66 + removeHTMLTags;
              action = `<span style='color:#FF8000'>${this.languageData.tip62}${
                this.languageData.zhang
              }${this.languageData.tip63}${removeHTMLTags.substring(0, 5)}......</span>${
                this.languageData.tip64
              }`;
            } else if (text.data.nodes[0]?.properties?.title) {
              action = `<span style='color:#FF8000'>${this.languageData.tip62}${
                this.languageData.zhang
              }${this.languageData.tip65}${text.data.nodes[0].properties.title.substring(
                0,
                5
              )}......</span>${this.languageData.tip64}`;
              item.removeHTMLTags =
                this.languageData.tip67 + text.data.nodes[0].properties.title;
            } else {
              action = `<span style='color:#FF8000'>${this.languageData.tip62}${this.languageData.zhang}</span>`;
            }
            switch (text.data.nodes[0]?.type) {
              case "icon-card":
                type = this.languageData.card;
                break;
              case "icon-select":
                type = this.languageData.select;
                break;
              case "icon-task":
                type = this.languageData.task;
                break;
              case "icon-AI":
                type = this.languageData.aiCard;
                break;
              case "icon-new-AI":
                type = this.languageData.aiCard;
                break;
              case "icon-theme":
                type = this.languageData.start;
                break;
              case "icon-stage":
                type = this.languageData.stage;
                break;
              case "icon-thinking":
                type = this.languageData.thinkingCard;
                break;
              case "icon-whiteboard":
                type = this.languageData.whiteboard_card;
                break;
              case "icon-map":
                type = this.languageData.map_card;
                break;
              case "icon-camera":
                type = this.languageData.camera_card;
                break;
              default:
                break;
            }
            break;
          case "addNode":
            action = `<span style="color:#0000FF">${this.languageData.tip70}${this.languageData.zhang}</span>`;
            switch (text.data.nodes[0]?.type) {
              case "icon-card":
                type = this.languageData.card;
                break;
              case "icon-select":
                type = this.languageData.select;
                break;
              case "icon-task":
                type = this.languageData.task;
                break;
              case "icon-AI":
                type = this.languageData.aiCard;
                break;
              case "icon-new-AI":
                type = this.languageData.aiCard;
                break;
              case "icon-theme":
                type = this.languageData.start;
                break;
              case "icon-stage":
                type = this.languageData.stage;
                break;
              case "icon-thinking":
                type = this.languageData.thinkingCard;
                break;
              case "icon-whiteboard":
                type = this.languageData.whiteboard_card;
                break;
              case "icon-map":
                type = this.languageData.map_card;
                break;
              case "icon-camera":
                type = this.languageData.camera_card;
                break;
              default:
                break;
            }
            break;
          case "deleteNode":
            action = `<span style="color:#FF0000">${this.languageData.tip71}${this.languageData.zhang}</span>`;
            switch (text.data.nodes[0]?.type) {
              case "icon-card":
                type = this.languageData.card;
                break;
              case "icon-select":
                type = this.languageData.select;
                break;
              case "icon-task":
                type = this.languageData.task;
                break;
              case "icon-AI":
                type = this.languageData.aiCard;
                break;
              case "icon-new-AI":
                type = this.languageData.aiCard;
                break;
              case "icon-theme":
                type = this.languageData.start;
                break;
              case "icon-stage":
                type = this.languageData.stage;
                break;
              case "icon-thinking":
                type = this.languageData.thinkingCard;
                break;
              case "icon-whiteboard":
                type = this.languageData.whiteboard_card;
                break;
              case "icon-map":
                type = this.languageData.map_card;
                break;
              case "icon-camera":
                type = this.languageData.camera_card;
                break;
              default:
                break;
            }
            break;
          case "addEdge":
            action = `<span style="color:#0000FF">${this.languageData.tip68}</span>`;
            break;
          case "deleteEdge":
            action = `<span style="color:#FF0000">${this.languageData.tip69}</span>`;
            break;
          case "updateEdge":
            action = `<span style="color:#FF8000">${this.languageData.tip72}</span>`;
            break;
          default:
            action = `${this.languageData.tip73}${this.languageData.zhang}`;
            type = this.languageData.card2;
            break;
        }
        item.type = type;
        item.action = action;
        if (NewData.length == 0) {
          NewData.push({
            date: item.date,
            arr: [item],
          });
        } else {
          NewData.reduce((prev, cur, index) => {
            if (prev == 0) {
              if (cur.date == item.date) {
                cur.arr.push(item);
                return 1;
              } else {
                if (index == NewData.length - 1) {
                  NewData.push({
                    date: item.date,
                    arr: [item],
                  });
                  return 1;
                }
                return 0;
              }
            }
          }, 0);
        }
      });
      this.record_details = NewData;
      this.record_details_flag = true;
    },
    look_job_reception_record(item, index, i, createDate) {
      let that = this;
      that.selectItem[index] = i;
      that.job_reception_record_details = item;
      that.job_reception_record_details_flag = true;
      if (!that.group[index].job_reception[i].isRead) {
        let temp = that.group[index].job_reception;
        that.group[index].job_reception = [];
        temp[i].isRead = true;
        that.group[index].job_reception = temp;
        let data = {
          createDate: createDate,
          createUser:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "潘炜桐"
              : window.parent.US.userInfo.username,
          nodes: item,
        };
        if (
          localStorage.getItem("network") == "true" ||
          localStorage.getItem("network") == true
        ) {
          let newData = {
            type: "read",
            data: data,
          };
          let params =
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "mode=" +
                [
                  "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
                  localStorage.getItem("roomId"),
                  "cscl",
                  encodeURIComponent(encodeURIComponent(JSON.stringify(newData))),
                ].join(",")
              : "mode=" +
                [
                  window.parent.US.userInfo.userid,
                  localStorage.getItem("roomId"),
                  "cscl",
                  encodeURIComponent(encodeURIComponent(JSON.stringify(newData))),
                ].join(",");
          that.$ajax
            .post("https://pbl.cocorobo.cn/api/pbl/add_OperationCSCL", params)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.error(err);
              that.$message({
                message: err,
                type: "error",
              });
            });
        }
      }
    },
    focusClose() {
      this.hoverClose = true;
    },
    blurClose() {
      this.hoverClose = false;
    },
    handleClose() {
      $("#scaffoldTip").css("display", "none");
    },
    create_template(file, className) {
      let that = this;
      if (!this.lf.getEditConfig().isSilentMode) {
        $(".lf-drag-able").addClass(className);
        // let data = that.$parent.lf.getGraphData()
        that.$ajax
          .get(that.isHk ? "/hk" + file : "/cn" + file)
          .then((res) => {
            console.log(res);
            let newDataAll = replaceAllCardId(res.data);
            that.$emit("getClassName", className);
            that.$emit("getNewDataAll", newDataAll);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    handle_map_mind(item) {
      console.log(item.src);
    },
    gain() {
      this.isHiddenRightSide_flag = false;
      let that = this;
      if (this.content == "") {
        this.$message({
          message: "请输入内容",
          type: "warning",
        });
        return;
      }
      let uid = uuidv4();
      that.isSending = true;
      var config = {
        method: "post",
        url: this.base_url + "/ask_question_new_excel",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          question: this.content,
          userId: uid,
          groupId: "123",
          userName: "generate",
          type: "chat",
          isHistory: false,
          file_id: "",
          mind_map_question: this.content,
        },
      };
      that.ai_result_div = true;
      that.loading = true;
      this.$ajax(config)
        .then(() => {})
        .catch((err) => {
          console.log(err);
          that.loading = false;
        });
      that.askOwn(uid);
      this.chat_content = "";
    },
    askOwn(userid) {
      let that = this;
      if (this.ai_source) {
        this.ai_source.close();
      }
      // 创建EventSource
      this.ai_source = new EventSource(this.base_url + "/ask_own/" + userid);
      // 存储获取的流数据（追加）
      let alltext = "";
      // 存储打字效果数据
      let newalltext = "";
      // 初始化md
      //const md = new MarkdownIt();
      // 监听流数据的返回
      this.ai_source.onmessage = function (e) {
        that.loading = false;
        let eData = JSON.parse(e.data);
        if (eData.content.replace("'", "").replace("'", "") == "[DONE]") {
          // that.isHiddenRightSide_flag=true;
          newalltext = newalltext.replace("_", "");
          //需要replace替换两次
          newalltext = newalltext.replace("_", "");
          // 在显示数组中存储当前数据
          that.ai_result = newalltext;
          //重置数据
          that.ai_source.close();
          that.isSending = false;
          alltext = "";
          newalltext = "";
          return;
        } else {
          let content = "";
          if (eData.type == "a") {
            content = eData.content.replace("'", "").replace("'", "");
          }
          if (alltext == "") {
            alltext = content.replace(/^\n+/, ""); //去掉回复消息中偶尔开头就存在的连续换行符
          } else {
            alltext += content;
          }
          // 处理流数据
          newalltext = alltext + "_";
          newalltext = newalltext.replace(/\\n/g, "\n"); // \\n
          newalltext = newalltext.replace(/\\/g, "");
          if (alltext.split("```").length % 2 == 0) newalltext += "\n```\n";
          // 转化返回的回复流数据
          //newalltext = md.render(newalltext);
          that.ai_result = newalltext;
        }
      };
    },
    stopAsk() {
      let that = this;
      if (that.ai_source) {
        that.ai_source.close();
        that.isSending = false;
        that.loading = false;
        that.ai_result_div = false;
        that.ai_result = "";
      }
    },
    generate() {
      this.activeTabName = this.languageData.pbl;
      this.$emit("openJobReception", false);
      this.isHiddenRightSide_flag = true;
      this.isHiddenRightSide = true;
      let that = this;
      let newNodes = [];
      let newEdges = [];
      let data = that.$parent.lf.getGraphData();
      let max_node = find_max_x(data.nodes);
      let replace_x = find_max_x(that.$parent.lf.getNodeModelById(max_node.id).anchors).x;
      let a = replace_x - 400;
      let x_coordinate = 400;
      that.y_coordinate += 500;
      if (that.y_coordinate > 500) {
        that.startPointy += 500;
        that.endPointy += 500;
      }
      var pattern = new RegExp(this.languageData.taskTip2 + ".*：");
      var patternEn = new RegExp(this.languageData.taskTip2 + ".*:");
      var pattern2 = new RegExp(this.languageData.taskContent);
      if (!pattern.test(this.ai_result) && !pattern2.test(this.ai_result)) {
        //非任务卡片
        this.handle_resource_card();
        return;
      }
      //生成主题卡片
      let topic = "";
      if (that.content.includes("“")) {
        let topic1 = that.content.split("“")[1];
        topic = topic1.split("”")[0];
      } else if (that.content.includes("「")) {
        let topic1 = that.content.split("「")[1];
        topic = topic1.split("」")[0];
      } else if (that.content.includes('"')) {
        let topic1 = that.content.split('"')[1];
        topic = topic1.split('"')[0];
      } else {
        topic = that.content;
      }
      let themeId = uuidv4();
      let themeNode = {
        id: themeId,
        type: "icon-theme",
        properties: {
          type: "icon-theme",
          topic: topic,
        },
        x: a + x_coordinate + 232.5,
        y: that.y_coordinate,
        zIndex: 1002,
      };
      that.lf.graphModel.addNode(themeNode);
      newNodes.push(that.$props.lf.graphModel.getNodeModelById(themeId).getData());
      //任务卡片
      let taskContent = "";
      let taskArr = [];
      if (window.location.href.indexOf("cocorobo.cn") != -1) {
        taskContent = this.ai_result.split("任务一：")[1];
        taskArr = taskContent.replace("\n", "").split(pattern);
      } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
        taskContent = this.ai_result.split("任務一：")[1];
        taskArr = taskContent.replace("\n", "").split(pattern);
      } else if (window.location.href.indexOf("cocorobo.com") != -1) {
        taskContent = this.ai_result.split("Task 1:")[1];
        taskArr = taskContent.replace("\n", "").split(patternEn);
      } else {
        taskContent = this.ai_result.split("任务一：")[1];
        taskArr = taskContent.replace("\n", "").split(pattern);
      }
      taskArr.forEach((item, index) => {
        if (index % 2 == 0) {
          //偶数
          //卡片
          let pre_node_id = that.pre_node_id.at(-1);
          let id = uuidv4();
          that.pre_node_id.push(id);
          let node = {
            id: id,
            type: "icon-task",
            properties: {
              type: "icon-task",
              taskTitle: item,
              taskContent: taskArr[index + 1],
              taskTitleColor: "#6E44C9",
            },
            x: a + x_coordinate + 232.5,
            y: that.y_coordinate + 200,
            zIndex: 1002,
          };
          that.lf.graphModel.addNode(node);
          x_coordinate += 300;
          //连线
          if (index > 0) {
            const { transformModel } = this.$props.lf.graphModel;
            transformModel.focusOn(
              node.x,
              node.y,
              window.screen.width,
              window.screen.height
            );
            let x1 =
              that.$props.lf.graphModel.getNodeModelById(pre_node_id).x +
              that.$props.lf.graphModel.getNodeModelById(pre_node_id)._width / 2;
            let x2 =
              that.$props.lf.graphModel.getNodeModelById(id).x -
              that.$props.lf.graphModel.getNodeModelById(id)._width / 2;
            let y1 = that.$props.lf.graphModel.getNodeModelById(pre_node_id).y;
            let y2 = that.$props.lf.graphModel.getNodeModelById(id).y;
            let edgeId = uuidv4();
            let edge = {
              id: edgeId,
              properties: {},
              zIndex: 1,
              type: "pro-line",
              sourceNodeId: pre_node_id,
              targetNodeId: id,
              startPoint: {
                x: x1,
                y: y1,
              },
              endPoint: {
                x: x2,
                y: y2,
              },
            };
            this.$props.lf.graphModel.addEdge(edge);
            newEdges.push(that.$props.lf.graphModel.getEdgeModelById(edgeId).getData());
          } else {
            //主题卡片和任务卡片的连线
            let themeEdgeId = uuidv4();
            let themeEdge = {
              id: themeEdgeId,
              properties: {},
              zIndex: 1,
              type: "pro-line",
              sourceNodeId: themeId,
              targetNodeId: id,
              startPoint: {
                x: a + 400 + 232.5,
                y: that.startPointy,
              },
              endPoint: {
                x: a + 400 + 232.5,
                y: that.endPointy,
              },
            };
            this.$props.lf.graphModel.addEdge(themeEdge);
            newEdges.push(
              that.$props.lf.graphModel.getEdgeModelById(themeEdgeId).getData()
            );
          }
          //协同
          newNodes.push(that.$props.lf.graphModel.getNodeModelById(id).getData());
        }
      });
      sendUpdate(newNodes, newEdges, "update", 0);
      let newData = {
        nodes: newNodes,
        edges: newEdges,
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "addNode");
      this.lf.focusOn({
        id: themeId,
      });
    },
    handle_resource_card() {
      let that = this;
      let newNodes = [];
      let newEdges = [];
      let data = that.$parent.lf.getGraphData();
      let max_node = find_max_x(data.nodes);
      let replace_x = find_max_x(that.$parent.lf.getNodeModelById(max_node.id).anchors).x;
      let a = replace_x - 400;
      let x_coordinate = 400;
      that.y_coordinate += 100;
      if (that.y_coordinate > 100) {
        that.startPointy += 100;
        that.endPointy += 100;
      }
      let themeId = uuidv4();
      let themeNode = {
        id: themeId,
        type: "icon-theme",
        properties: {
          type: "icon-theme",
          topic: this.content,
        },
        x: a + x_coordinate + 232.5,
        y: that.y_coordinate,
        zIndex: 1002,
      };
      that.lf.graphModel.addNode(themeNode);
      newNodes.push(that.$props.lf.graphModel.getNodeModelById(themeId).getData());
      //资源卡片
      let resourceArr = this.ai_result.split("\n\n");
      resourceArr.forEach((item, index) => {
        item = item.replace(/\n/gi, "<br>");
        let pre_node_id = that.pre_node_id.at(-1);
        let id = uuidv4();
        that.pre_node_id.push(id);
        let node = {
          id: id,
          type: "icon-card",
          properties: {
            type: "icon-card",
            content: item,
            title: "文本",
            titleColor: "#DE8344",
            isShowTitle: false,
            radio: "content",
          },
          x: a + x_coordinate + 232.5,
          y: that.y_coordinate + 300,
          zIndex: 1002,
        };
        that.lf.graphModel.addNode(node);
        x_coordinate += 300;
        //连线
        if (index > 0) {
          const { transformModel } = this.$props.lf.graphModel;
          transformModel.focusOn(
            node.x,
            node.y,
            window.screen.width,
            window.screen.height
          );
          let x1 =
            that.$props.lf.graphModel.getNodeModelById(pre_node_id).x +
            that.$props.lf.graphModel.getNodeModelById(pre_node_id)._width / 2;
          let x2 =
            that.$props.lf.graphModel.getNodeModelById(id).x -
            that.$props.lf.graphModel.getNodeModelById(id)._width / 2;
          let y1 = that.$props.lf.graphModel.getNodeModelById(pre_node_id).y;
          let y2 = that.$props.lf.graphModel.getNodeModelById(id).y;
          let edgeId = uuidv4();
          let edge = {
            id: edgeId,
            properties: {},
            zIndex: 1,
            type: "pro-line",
            sourceNodeId: pre_node_id,
            targetNodeId: id,
            startPoint: {
              x: x1,
              y: y1,
            },
            endPoint: {
              x: x2,
              y: y2,
            },
          };
          this.$props.lf.graphModel.addEdge(edge);
          newEdges.push(that.$props.lf.graphModel.getEdgeModelById(edgeId).getData());
        } else {
          //主题卡片和任务卡片的连线
          let themeEdgeId = uuidv4();
          let replace = find_min_y(that.$parent.lf.getNodeModelById(id).anchors);
          let themeEdge = {
            id: themeEdgeId,
            properties: {},
            zIndex: 1,
            type: "pro-line",
            sourceNodeId: themeId,
            targetNodeId: id,
            startPoint: {
              x: a + 400 + 232.5,
              y: that.startPointy,
            },
            endPoint: {
              x: replace.x,
              y: replace.y,
            },
          };
          this.$props.lf.graphModel.addEdge(themeEdge);
          newEdges.push(
            that.$props.lf.graphModel.getEdgeModelById(themeEdgeId).getData()
          );
        }
        //协同
        newNodes.push(that.$props.lf.graphModel.getNodeModelById(id).getData());
      });
      sendUpdate(newNodes, newEdges, "update", 0);
      let newData = {
        nodes: newNodes,
        edges: newEdges,
        backgroundUrl: localStorage.getItem("background"),
      };
      add_Operation(newData, "addNode");
      this.lf.focusOn({
        id: themeId,
      });
    },
    selectProblem(value) {
      this.content = value;
    },
    cut() {
      this.$emit("getBackground", "");
      if (window.location.href.indexOf("cocorobo.cn") != -1) {
        document.domain = "cocorobo.cn";
      } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
        document.domain = "cocorobo.hk";
      } else if (window.location.href.indexOf("cocorobo.com") != -1) {
        document.domain = "cocorobo.com";
      }
      const img = document
        .getElementById("backgroundIframe")
        .contentWindow.h.app.rc.canvas.toDataURL("image/png");
      this.$emit("getBackground", img);
      // console.log(img)
    },
    dragInNode(type, context) {
      if (type == "my-group") {
        this.tab = 6;
        setTimeout(() => {
          this.tab = 3;
        },3000)
        this.$emit("openAnalyse", false);
        this.$emit("openNewAnalyse", false);
        this.$emit("openBackground", false);
      }
      this.$emit("getIsHidden", true);
      this.$emit("dragInNode", type, context);
    },
    getDataAgain() {
      this.loading = true;
      this.job_reception_record_details_flag = false;
      this.$parent.get_select_CSCLDataByID();
      // this.handle_record_data();
    },
    hiddenRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.isHiddenRightSide_flag = true;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", true);
    },
    showSearchRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      this.$message.warning(this.languageData.tip17);
    },
    showProjectRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      this.$message.warning(this.languageData.tip17);
    },
    showCollectionRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", false);
      this.tab = 2;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      // this.$message.warning(this.languageData.tip17)
    },
    showNPCRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", false);
      this.tab = 12;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      // this.$message.warning(this.languageData.tip17)
    },
    showAINPCRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", false);
      this.getAIAgent();
      this.getShareAIAgent();
      this.tab = 13;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
    },
    showScaffoldRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", false);
      this.tab = 9;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
    },
    showOperationRightSide() {
      this.job_reception_record_details_flag = false;
      this.tab = 10;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", false);
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      this.loading = true;
      this.selectItem = [];
      this.record_details_flag = false;
      this.$parent.get_select_CSCLDataByID();
    },
    showJobReceptionRightSide() {
      this.record_details_flag = false;
      this.$emit("openJobReception", true);
      this.$emit("getIsHidden", false);
      this.tab = 11;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      this.loading = true;
      this.activeGroupJobs = [];
      this.selectItem = [];
      this.job_reception_record_details_flag = false;
      this.$parent.get_select_CSCLDataByID();
      // this.handle_record_data();
    },
    showToolsRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", false);
      this.tab = 3;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
    },
    showLibraryRightSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      this.$message.warning(this.languageData.tip17);
    },
    showBackground() {
      if (
        this.tab == 2 ||
        this.tab == 3 ||
        this.tab == 9 ||
        this.tab == 10 ||
        this.tab == 11
      ) {
        let data = this.$parent.lf.getGraphData();
        data.backgroundUrl = localStorage.getItem("background");
        localStorage.setItem("data", JSON.stringify(data));
      }
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", true);
      this.tab = 5;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", true);
      this.$emit("clearIncreasingTrenTime", "");
    },
    showAnalysisSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", true);
      this.tab = 7;
      this.$emit("openAnalyse", true);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
    },
    showNewAnalysisSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("getIsHidden", true);
      this.tab = 8;
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", true);
      this.$emit("openBackground", false);
    },
    showBoxSide() {
      this.job_reception_record_details_flag = false;
      this.record_details_flag = false;
      this.$emit("openJobReception", false);
      this.$emit("openAnalyse", false);
      this.$emit("openNewAnalyse", false);
      this.$emit("openBackground", false);
      this.$emit("clearIncreasingTrenTime", "");
      this.$message.warning(this.languageData.tip17);
    },
    getAIAgent() {
      let that = this;
      var config = {
        method: "POST",
        url: "https://gpt4.cocorobo.cn/get_ai_agent_assistant_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
              : window.parent.US.userInfo.userid,
        },
      };
      that.loading = true;
      this.$ajax(config)
        .then((res) => {
          that.agentList =
            res.data.FunctionResponse.result.length != 0
              ? JSON.parse(res.data.FunctionResponse.result)
              : [];
          for (let i = 0; i < that.agentList.length != 0; i++) {
            if (that.agentList[i].headUrl == "") {
              that.agentList[i].headUrl =
                "https://beta.cloud.cocorobo.cn/aigpt/img/ai_agent_header.88be856f.png";
            }
          }
          that.loading = false;
          that.$emit("setRefreshAIAgent", false);
        })
        .catch((err) => {
          console.log(err);
          that.loading = false;
        });
    },
    getShareAIAgent() {
      let that = this;
      var config = {
        method: "POST",
        url: "https://gpt4.cocorobo.cn/get_ai_agent_assistant_share_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userId:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
              : window.parent.US.userInfo.userid,
          organizeid:
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "45facc0a-1211-11ec-80ad-005056b86db5"
              : window.parent.US.userInfo.organizeid,
        },
      };
      that.shareAgentList_loading = true;
      this.$ajax(config)
        .then((res) => {
          that.shareAgentList =
            res.data.FunctionResponse.result.length != 0
              ? JSON.parse(res.data.FunctionResponse.result)
              : [];
          for (let i = 0; i < that.shareAgentList.length != 0; i++) {
            if (that.shareAgentList[i].headUrl == "") {
              that.shareAgentList[i].headUrl =
                "https://beta.cloud.cocorobo.cn/aigpt/img/ai_agent_header.88be856f.png";
            }
          }
          that.shareAgentList_loading = false;
        })
        .catch((err) => {
          console.log(err);
          that.shareAgentList_loading = false;
        });
    },
  },
};
</script>

<style>
.rightSide .el-collapse-item__wrap {
  background-color: #f0f2f5 !important;
}
.rightSide .el-collapse-item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px !important;
}
.scaffold .el-tabs__nav-scroll {
  margin-left: 15px;
}

.scaffold .el-tab-pane {
  width: auto;
}

.scaffold .prompt {
  background: #e7f3ff;
  border-radius: 5px;
  color: #000 !important;
  padding: 10px;
  margin-bottom: 10px;
}

.tooltip-item {
  border-radius: 6px;
  color: var(--gray-white, #fff);
  /* body/small */
  font-family: SimSun;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.pbl-1 {
  cursor: url("../../assets/缩略图/PBL观察笔记.png"), pointer;
}

.pbl-2 {
  cursor: url("../../assets/缩略图/PBL介绍.png"), pointer;
}

.pbl-3 {
  cursor: url("../../assets/缩略图/PBL跨学科模板.png"), pointer;
}

.pbl-4 {
  cursor: url("../../assets/缩略图/团队成员介绍模板.png"), pointer;
}

.pbl-5 {
  cursor: url("../../assets/缩略图/POV表格.png"), pointer;
}

.pbl-6 {
  cursor: url("../../assets/缩略图/康奈尔笔记法.png"), pointer;
}

.pbl-7 {
  cursor: url("../../assets/缩略图/苏格拉底式追问.png"), pointer;
}

.thinking-1 {
  cursor: url("../../assets/缩略图/图尔敏法.png"), pointer;
}

.thinking-2 {
  cursor: url("../../assets/缩略图/5E教学设计.png"), pointer;
}

.thinking-3 {
  cursor: url("../../assets/缩略图/六顶思考帽.png"), pointer;
}

.thinking-4 {
  cursor: url("../../assets/缩略图/3SE课程设计模型.png"), pointer;
}

.thinking-5 {
  cursor: url("../../assets/缩略图/KWL图.png"), pointer;
}

.thinking-6 {
  cursor: url("../../assets/缩略图/W图.png"), pointer;
}

.thinking-7 {
  cursor: url("../../assets/缩略图/X图.png"), pointer;
}

.thinking-8 {
  cursor: url("../../assets/缩略图/Y图.png"), pointer;
}

.thinking-9 {
  cursor: url("../../assets/缩略图/韦恩三圆圈图.png"), pointer;
}

.thinking-10 {
  cursor: url("../../assets/缩略图/韦恩图.png"), pointer;
}

.thinking-11 {
  cursor: url("../../assets/缩略图/鱼骨图.png"), pointer;
}

.project-1 {
  cursor: url("../../assets/缩略图/论辩文写作.png"), pointer;
}

.project-2 {
  cursor: url("../../assets/缩略图/英语创意写作.png"), pointer;
}

.content2 {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.content2 p {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.content2 p span {
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  font-family: SimSun;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/* #rightSide .el-textarea__inner {
  height: 150px!important;
  min-height: 150px!important;
} */
</style>

<style scoped>
.embedCode :deep() iframe {
  width: 100%;
  height: 90%;
}
.group_name {
  width: 85%;
  height: 30px;
  line-height: 30px;
  padding: 8px;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 10px;
  cursor: pointer;
  flex-wrap: nowrap;
  overflow: hidden;
}
.operation_record_avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  margin: 5px;
  background: #eaa13b;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.diagram-sidebar {
  user-select: none;
  display: flex;
  overflow-y: auto;
}

.leftSide {
  width: 75.5px;
  height: 100%;
  background: #ffffff;
  box-shadow: 5px 0px 15px 1px rgba(0, 47, 130, 0.08);
}

.rightSide {
  width: 300px;
  display: block;
  position: fixed;
  z-index: 50;
  left: 73px;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}
.record_details {
  width: 230px;
  display: block;
  position: fixed;
  z-index: 50;
  left: 377px;
  height: 100%;
  overflow-y: auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}
.job_reception_record_details {
  width: 300px;
  padding: 8px 16px 0px 16px;
  display: block;
  position: fixed;
  z-index: 50;
  left: 373px;
  height: 100%;
  overflow-y: auto;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}
.create_at {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
}
.icon_nor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #ffffff;
}

.icon_pre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #007bff;
}

.toolText_nor {
  font-size: 12px;
  font-family: SimSun;
  font-weight: 400;
  color: #afb3be;
  margin-top: 5px;
  text-align: center;
}

.toolText_pre {
  font-size: 12px;
  font-family: SimSun;
  font-weight: 400;
  color: #ffffff;
  margin-top: 5px;
  text-align: center;
}

.Text {
  font-size: 18px;
  font-family: SimSun;
  font-weight: bold;
  color: #000000;
  line-height: 50px;
  margin-left: 16px;
}

.node-category-title_nor {
  margin: 0 0 0 10px;
  display: block;
  /* border-bottom: 1px solid #e5e5e5; */
  line-height: 30px;
  font-size: 16px;
  font-family: SimSun;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}

.node-category-title_pre {
  margin: 0 0 0 10px;
  display: block;
  /* border-bottom: 1px solid #e5e5e5; */
  line-height: 30px;
  font-size: 16px;
  font-family: SimSun;
  font-weight: 400;
  color: #007bff;
  margin-bottom: 10px;
}

.node-item {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  display: inline-block;
}

.node-category {
  border-bottom: 1px solid #e5e5e5;
}

.svg-node {
  left: 1px;
  top: 1px;
  width: 32px;
  height: 30px;
  display: block;
  position: relative;
  overflow: hidden;
}

.image-node,
.icon-node {
  display: inline-block;
  width: 30px;
  height: 30px;
  /* margin: 10px; */
  cursor: pointer;
}

.image-setting {
  background: url("https://dpubstatic.udache.com/static/dpubimg/UzI4AFUcfO/setting.png");
  background-size: cover;
}

.image-user {
  width: 40px;
  background: url("https://dpubstatic.udache.com/static/dpubimg/-6Fd2uIoJ-/user.png");
  background-size: cover;
}

.image-cloud {
  width: 40px;
  background: url("https://dpubstatic.udache.com/static/dpubimg/0oqFX1nvbD/cloud.png");
  background-size: cover;
}

.icon-message {
  height: 20px;
  background: url("https://dpubstatic.udache.com/static/dpubimg/1TZgBoaq8G/message.png");
  background-size: cover;
}

.icon-count-down {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/暂无-倒计时.png");
  background-size: cover;
}

.icon-thinking-grid {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/14思维网格.png");
  background-size: cover;
}

.icon-el-whiteboard {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/9电子白板.png");
  background-size: cover;
}

.icon-note {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/11便签分类.png");
  background-size: cover;
}

.icon-collaborative-document {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/13协同文档.png");
  background-size: cover;
}

.icon-mindMap {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/12思维导图.png");
  background-size: cover;
}

.icon-choice-question {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/10问卷调查.png");
  background-size: cover;
}

.icon-QA-tools {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/暂无-问答工具.png");
  background-size: cover;
}

.icon-job-submission {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/暂无-作业提交.png");
  background-size: cover;
}

.icon-student-mutual {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/学生互评.png");
  background-size: cover;
}

.icon-multiple-choice {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/选择填空.png");
  background-size: cover;
}

.icon-learn {
  width: 40px;
  height: 40px;
  background: url("../../assets/tools/8素材库.png");
  background-size: cover;
}

.icon-add {
  width: 48px;
  height: 48px;
  background: url("../../assets/加.png");
  background-size: cover;
}

.icon-option {
  /* background: #FFFFFF; */
  width: 34px;
  height: 34px;
  padding: 5px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 6px;
}

.icon-start {
  width: 34px;
  height: 34px;
  background: url("../../assets/start.png");
  background-size: cover;
}

.icon-collect {
  width: 34px;
  height: 34px;
  background: url("../../assets/收藏.png");
  background-size: cover;
}

.icon-scaffold {
  width: 34px;
  height: 34px;
  background: url("../../assets/脚手架.png");
  background-size: cover;
}

.icon-stage {
  width: 34px;
  height: 34px;
  background: url("../../assets/stage.png");
  background-size: cover;
}

.icon-task {
  width: 34px;
  height: 34px;
  background: url("../../assets/task.png");
  background-size: cover;
}

.icon-homework {
  width: 34px;
  height: 34px;
  background: url("../../assets/作业提交.png");
  background-size: cover;
}

.icon-camera {
  width: 34px;
  height: 34px;
  background: url("../../assets/camera_card.png");
  background-size: cover;
}

.icon-map {
  width: 34px;
  height: 34px;
  background: url("../../assets/map_card.png");
  background-size: cover;
}

.icon-card {
  width: 34px;
  height: 34px;
  background: url("../../assets/card.png");
  background-size: cover;
}

.icon-select {
  width: 34px;
  height: 34px;
  background: url("../../assets/select.png");
  background-size: cover;
}

.icon-thinking {
  width: 34px;
  height: 34px;
  background: url("../../assets/thinking.png");
  background-size: cover;
}

.icon-whiteboard {
  width: 34px;
  height: 34px;
  background: url("../../assets/white_board.png");
  background-size: cover;
}

.icon-AI {
  width: 34px;
  height: 34px;
  background: url("../../assets/AI.png");
  background-size: cover;
}

.icon-group {
  width: 34px;
  height: 34px;
  background: url("../../assets/group.png");
  background-size: cover;
}

.iconContent {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0 6px 12px;
}
.iconContent2 {
  /* width: 110%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; */
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  margin: 6px 0 6px 12px;
}
.iconOption {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid rgba(255, 255, 255, 0);
  width: 78px;
  height: 78px;
  cursor: pointer;
}

.iconOption span {
  width: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-align: center;
}

.iconOption:hover {
  border: 1px solid #007bff;
}

.scaffold::-webkit-scrollbar {
  width: 4px;
  /*height: 4px;*/
}

/* 滚动条 */
.scaffold::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

/* 滚动条背景 */
.scaffold::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
